import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';
import * as Blockly from 'blockly';
import '@blockly/toolbox-search';
import {CrossTabCopyPaste} from '@blockly/plugin-cross-tab-copy-paste';
import { SiteService } from 'src/app/shared/services/site.service';
import BlocklyToolBox from 'src/assets/constants/cloud/blockly-toolbox';
import { BLOCKS, dynamicBlockBuilder } from 'src/assets/constants/cloud/predefined';
import { CodeGenerate } from 'src/assets/constants/cloud/predefined-code-generator';
import { javascriptGenerator } from 'blockly/javascript';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastMessageComponent } from 'src/app/shared/components/toast-message/toast-message.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SiteSequencerService } from './services/site-sequencer.service';
import { SequenceStatus, BuilderMode, BlockType, DefaultTabName, CustomCategory,
         Category, DefaultCategoryTabName, scope, componentType, DeviceRunBlockType, 
         toolBoxContentKind, BlockIdentifier, BlockFieldName, XMLEnum} from 'src/app/shared/enums/SequenceKeys.enum';
import { HostRoute } from 'src/app/shared/enums/Host.enum';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { EditAndDeleteConfirmationModalComponent } from 'src/app/shared/components/edit-and-delete-confirmation-modal/edit-and-delete-confirmation-modal.component';
import { DomSanitizer  } from '@angular/platform-browser';
import { ObjectUtil } from 'src/app/shared/utils/object-util';
import { SEQUENCE_TEMPLATES } from 'src/assets/constants/cloud/sequence-templates';
import { CUSTOM_FUNCTIONS } from 'src/assets/constants/cloud/custom-functions';
import { UNIT_CONVERSION_BLOCKS } from 'src/assets/constants/cloud/unit-conversions';
import { BlocklyValidators } from 'src/assets/constants/cloud/validators';
import { SequenceValidationPopupComponent } from 'src/app/shared/components/sequence-validation-popup/sequence-validation-popup.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { SequenceScopeSelectionModalComponent } from 'src/app/shared/components/sequence-scope-selection-modal/sequence-scope-selection-modal.component';
import { BlockTheme, categoryTheme } from 'src/assets/constants/blockly-theme';
import DEVICE_TOOLBOX from 'src/assets/constants/device/blockly-toolbox-device';
import { DEVICE_BLOCKS, dynamicDeviceBlockBuilder } from 'src/assets/constants/device/predefined-device';
import { DeviceCodeGenerate, runContinuousPythonFun, runStartupPythonFun, sequenceRunRateMs } from 'src/assets/constants/device/predefined-code-generator-device';
import { pythonGenerator } from 'blockly/python';
import { CanvasTabs } from 'src/app/shared/interfaces/interfaces';
import { DEFAULT_TEMPLATE } from 'src/assets/constants/device/default-template';
import { Device } from './services/webusbdevice';
import { DFU } from './services/dfu-helper';
import { FeatureToggleService } from 'src/app/services/feature-toggle.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { CcuLogsModalComponent } from 'src/app/shared/components/ccu-logs-modal/ccu-logs-modal.component';
import { CommonService } from 'src/app/services/common.service';
dayjs.extend(utc);
declare var navigator: any;
@Component({
  selector: 'app-site-sequencer',
  templateUrl: './site-sequencer.component.html',
  styleUrls: ['./site-sequencer.component.scss']
})
/**
 * Represents the Site Sequencer component.
 */
export class SiteSequencerComponent {
  sites!: any[];
  languages!: any[];
  blocklyWorkSpace!: any;
  placeholderMessage: string = '';
  showToolBox: boolean = false;
  showToolBoxToggleButton: boolean = false;
  readOnlyMode = true;
  isCodeBlockVisible: boolean = false;
  codeBlockTooltipText = "Expand Code Snippet"
  code: string = '';
  categories = {
    CREATED_BY_ME : 'Created by Me' ,
    SHARED_BY_OTHERS : 'Shared by Others'
  }
  subscriptions!: Subscription;
  initSiteName = 'Select a site';
  initLanguageName = 'Select a language';
  selectedSite: any = null;
  selectedSiteId: any = '';
  selectedLanguageId: any = '';
  siteId: any = '';
  isLoading = false;
  sequences: any[] = [];
  sequencerBlock: any = null;
  tooltip: boolean = false;
  sequencerFunctionCollection: any[] = [];
  isLoadingSequence: boolean = false;
  editMode: boolean = true;
  showSequenceActionButtons: boolean = false;
  isUpdatingExistingMarker: boolean = false;
  isLoadingAllSequences: boolean = false;
  workspaceXML: any = null;
  errorBlocks: any = [];
  breadcrumbs : any[] = [];
  sequenceTabs: any[] = [];
  isSequenceTabSelected: boolean = false;
  selectedSequenceTab: any = null;
  sequenceTohighlight: any = null;
  activeTabIndex: number = 0;
  sequenceLogs: any[] = [];
  sequenceLogstableData: any[] = [];
  sequenceLogsHeaderColumns: any[] = [];
  sequenceLogsSubscription: any;
  sequenceLogsRefreshTime: number = 60000;
  defaultTabLabel: any = DefaultTabName.SITE_SEQUENCES;
  previousRunStatus = SequenceStatus.DISABLE;
  
  builderMode: any = BuilderMode.SEQUENCE_BUILDER;
  blocklyContainerId: any = 'blockly-container';
  hideBlockCategory = ['API Integration'];
  hideBlockTypes = ['point_write_null'];

  //Declaring the dropdown list for functions
  
  dropdownFunctionsList: any = {
    createdByMeFunctions: [],
    createdByOtherFunctions: []
  }

  dropdownAccordion: any = {
    createdByMeFunctions: false,
    createdByOtherFunctions: false
  };

  duplicateFunctionBlock: any = {
    createdByMeFunctions: [],
    createdByOtherFunctions: [false]
  }

  isLikeUnlikeRefresh: boolean = false;

  hoveredFunctionBlock: any;
  enableTableEdit: boolean = false;
  isDraggingFunctionBlock: boolean = false;
  modifiedFunctionBlocks: any = [];

    
  @ViewChild('showHideButton', { read: ElementRef }) showHideButton!: ElementRef;
  @ViewChild('sequenceTabGroup') sequenceTabGroup!: MatTabGroup;
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @ViewChild('renameInput') renameInput!: ElementRef;
  toolBox: any;
  blocksToInit: any;
  skipDeleteFunctionEvent: boolean = false;
  isFunctionBlockClicked: boolean = false;
  selectedFunctionToView: any = null;
  isBuildingNewSequenceFunction: boolean = false;
  isDuplicatingSequenceFunction: boolean = false;
  isDeletingSequenceFunction: boolean = false;
  previousMode: any = null;
  activeSequencerBlockRef: any = null;
  userSelectedSiteInternal: string = '';
  isSearchActive: boolean = false;
  searchText: string = '';
  tempCanvasTabs: any = [];
  tempSequenceTab: any = [];
  isCreateCanvasLoading: boolean = false;
  clickTimer: any;
  canvasTabToSwitch: any = null;
  searchedSequenceLogsValue: any = null;
  isCreatingNewDeviceCanvas: boolean = false;

  categoryMenu: any = {
    tabs: [
      {'label': 'cloud', 'id': 'CLOUD'},
      {'label': 'system', 'id': 'CCU'},
      {'label': 'device', 'id': 'DEVICE'}
    ],
    activeTabIndex: 0,
    selectedCategory: {'label': 'cloud', 'id': 'CLOUD'}
  }

  canvasTabs: CanvasTabs = {
    tabs: [],
    activeTabIndex: 0,
    selectedCanvasTab: null
  }
  deviceRunBlocksOnWorkspace: any[] = [];
  originalToolBoxContents: any;
  contextMenuPostion : {
    x: number,
    y: number
  } = {x: 0, y: 0};
  showClearBlock: boolean = false;
  showSystemCategory: boolean = false;
  previousCCULogsRef: any = {};
  isPasteEventTriggered: boolean = false;
  noChangesInWorkspace: boolean = true;
  deprecatedBlocks: any = ['getHistorizedValue','getHistorizedValueFromDateRange','gethistorizedNVariant', 'gethistorizedDateTimeVariant', 'getAggregatedValues', 'getAggregatedValuesFromDateRange', 'getAggregatedValuesFromDateTimeVariant', 'getHistorizedValueExcludeMins'];

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const customBlocksDropdown: HTMLElement | null = document.getElementById('custom-blocks-dropdown');

    // Checking if the clicked element is not a descendant of the element with id "custom-blocks-dropdown"
    if (!customBlocksDropdown?.contains(clickedElement)) {
      if (this.menuTrigger) {
        this.menuTrigger.closeMenu();
      }
    }
  }

  constructor(public locaStorageService: LocalStorageService, private siteSequencerService: SiteSequencerService, 
    private dom: DomSanitizer,private siteService: SiteService, private toastMessage: MatSnackBar, 
    private dialog: MatDialog, public featureToggleService: FeatureToggleService,
    private commonService: CommonService) { 
      this.dropdownAccordion = {
        createdByMeFunctions: false,
        createdByOtherFunctions: false
      };
    }

  ngOnInit(): void {
    try {
      this.sequencerBlock = null;
      this.parseUrl();
      this.placeholderMessage = 'Select a site';
      this.getLanguages();
      this.modifiedFunctionBlocks = [];
      this.getAllSites(); 
      this.featureToggleService.flagChange.subscribe((flags) => {
        this.setFlags(flags);
      });
      this.useFeatureToggles();
    } catch (error) {
      this.isLoading = false;
    }
   
  }

  useFeatureToggles() {   
    let sub = this.featureToggleService.featureFlagsSubject.subscribe((flags)=>{
        this.setFlags(flags);
        sub.unsubscribe();
        
    });
    this.featureToggleService.getFlags();
}

  setFlags(flags:any) {
    this.showClearBlock = flags.hasOwnProperty('clear-block') ? flags['clear-block']?.value : false;
    this.showSystemCategory = flags.hasOwnProperty('sequencer-on-ccu') ? flags['sequencer-on-ccu']?.value : false;

    // Hiding the system category tab if the showSystemCategory is disabled
    if(!this.showSystemCategory) {
      this.categoryMenu.tabs = this.categoryMenu.tabs.filter((tab: any) => tab.id !== 'CCU');
    }
  }

  parseUrl() {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    this.handleQueryParams(queryParams);
  }

  // Toast Message 
  showToast(type: string, message: string) {
    this.toastMessage.openFromComponent(ToastMessageComponent, {
      duration: 5000,
      data: {
        type: type,
        message: message
      },
    });
  }

  /**
   * Reinitializing the workspace for different tabs on toggling level tabs. 
   * @param event {any} - the event with the selected tab index and label.
   * @param initWorkspace {boolean} - the flag to initialize the workspace.
   */
    toggleCategoryLevelTab(event: any, initWorkspace?: boolean) {  
      this.isLoading = true;

      this.sequenceTabs = []; // Clearing the existing sequence tabs
      this.deviceRunBlocksOnWorkspace = []; // Clearing the existing device run blocks on workspace
      // Clearing the existing canvas tabs and selected canvas tab
      if(initWorkspace) {
        setTimeout(() => {
          this.canvasTabs.selectedCanvasTab = null;
          this.canvasTabs.tabs = [];
        }, 500);
      }

      const allBlocks = this.blocklyWorkSpace ? this.blocklyWorkSpace.getAllBlocks() : [];
      const switchTab = () => {
        this.categoryMenu.selectedCategory = this.categoryMenu.tabs[event.index];
        initWorkspace ? '' : this.toggleWorkspace('delete'); // Deleting the existing workspace
        setTimeout(() => {
          this.builderMode = BuilderMode.SEQUENCE_BUILDER; // Setting the builder mode
          this.previousMode = null;

          // Get the list of workspaces for the selected category level and add them as tabs.
          this.getWorkspacesForSelectedCategoryType();
          this.categoryMenu.activeTabIndex = event.index;
        }, 500);
      };
    
      if (allBlocks.length === 0 || this.noChangesInWorkspace) {
        // If the Blockly workspace is empty, switch the tab immediately
        switchTab();
      } else {
        // If the Blockly workspace is not empty, check if blocks are valid
        const isSequencesValid = this.checkAndReturnErrorStatus();
        if (isSequencesValid) {
          // If blocks are valid, switch the tab after saving and deleting the ccurrent workspace
          this.isLoading = true;
          this.saveWorkspaceXML();
          this.saveAllSequences(true);
          switchTab();
        } else {
          // If blocks are not valid, fall back to the previous tab
          const previousTabIndex = this.categoryMenu.tabs.findIndex((tab: any) => tab.label === this.categoryMenu.selectedCategory.label);
          this.categoryMenu.activeTabIndex = previousTabIndex;
          this.categoryMenu.selectedCategory = this.categoryMenu.tabs[previousTabIndex];
          this.isLoading = false;
        }
      }
    }

  /**
   * Fetching the workspaces for selected category level type and adding them as tabs.
   * @param selectedCategoryLevel {string} - the selected category level type. // Possible values: CLOUD, CCU, DEVICE
  */
  getWorkspacesForSelectedCategoryType() {
    const selectedCategoryLevel = this.categoryMenu.selectedCategory.id;
    // Get the list of workspaces for the selected category level and add them as tabs.
    this.siteSequencerService.getWorkspacesListForCategory(this.selectedSiteId, selectedCategoryLevel)
    .subscribe((res: any) => {
      if(res && res.length > 0) {
        // Adding the workspaces as canvas tabs

        res.forEach((canvasObj: any) => {
          canvasObj['tempName'] = canvasObj.name;
          canvasObj['isCanvasNameEdit'] = false;
          canvasObj['showMenu'] = false
        });

        this.canvasTabs.tabs = res;
        this.tempCanvasTabs = JSON.parse(JSON.stringify(res));
        if(this.canvasTabToSwitch) {
          this.canvasTabs.selectedCanvasTab = this.canvasTabToSwitch;
          this.activeTabIndex = this.canvasTabs.tabs.findIndex((tab: any) => tab.id === this.canvasTabToSwitch.id);
        } else {
          this.canvasTabToSwitch = null;
          this.canvasTabs.selectedCanvasTab = this.canvasTabs.tabs[0];
          this.activeTabIndex = 0;
        }
        
        this.switchToSelectedTab(this.canvasTabs.selectedCanvasTab);
      } else {
        const initWorkspace = true;
        this.createNewCanvasTab(initWorkspace);
      }
    }, (err: any)=> {
      this.isLoading = false;
      this.showToast('error', `${err.message}`);
    });
  }
  
  // Method to handle the tooltip display and update its position in align with dropdown menu
  hoveredFunction(event: Event) {
    this.tooltip = true;
    const toolTipPostionX = document.getElementsByClassName('cdk-overlay-pane')[0].getBoundingClientRect().x;
    let selectedRow = event?.target as HTMLHtmlElement;
    let toolTipPostionY = selectedRow.parentElement?.getBoundingClientRect().top || 0;
    const element = document.getElementById('custom-block-tooltip');
    setTimeout(() => {
      if (element) {
        const tooltipWidth = element?.getBoundingClientRect().width;
        element.style.left = toolTipPostionX - tooltipWidth + 'px';
        const tooltipHeight = element?.getBoundingClientRect().height;
        const totalHeight = toolTipPostionY + tooltipHeight;
        if(totalHeight > window.innerHeight) {
          toolTipPostionY = window.innerHeight - tooltipHeight - 20;
        }
        element.style.top = toolTipPostionY + 'px';
      }
    }, 1);
  } 
  
  // Method to handle the tooltip hide
  unhoveredFunction(){
    this.tooltip = false;
  }

  async handleQueryParams(params: any) {
    if (params.has('token')) {
      const token = params.get('token');
      this.locaStorageService.persistToken(token);
      try {
        let userDetails = await lastValueFrom(this.siteService.getUser());
        this.commonService.userDetails = userDetails;
        this.locaStorageService.persistUserId(userDetails.userId);
        this.locaStorageService.persistUserEmail(userDetails.emailId);
      } catch (error:any) {
        this.showToast('error', `${error.message}`);
      }
      this.featureToggleService.devCycleInit();
    }
    if (params.has('userId')) {
      const userId = params.get('userId')
      this.locaStorageService.persistUserId(userId);
    }
    if (params.has('portal')) {
      const portal = params.get('portal');
      sessionStorage.setItem("portal", portal);
    }
    if (params.has('siteName')) {
      const siteNameFromFacPortal = params.get('siteName');
      sessionStorage.setItem("siteNameFromFacPortal",JSON.stringify(siteNameFromFacPortal));
    }
    if(params.has('selectedSiteId')) {
       this.userSelectedSiteInternal = params.get('selectedSiteId')
    }
  }

  /** method to initialize blockly workspace and show default blocks */
  initializeBlockly() {
    if (this.categoryMenu.selectedCategory.id === Category.DEVICE) {
        this.toolBox = DEVICE_TOOLBOX;
        this.blocksToInit = DEVICE_BLOCKS;
    } else {
        this.toolBox = ObjectUtil.deepClone(BlocklyToolBox);
        this.blocksToInit = BLOCKS;
        this.blocksToInit = this.blocksToInit.concat(UNIT_CONVERSION_BLOCKS);
        this.blocksToInit = this.blocksToInit.concat(SEQUENCE_TEMPLATES);
        this.blocksToInit = this.blocksToInit.concat(CUSTOM_FUNCTIONS);
    }
    

    // Backup the original toolBox contents
      this.originalToolBoxContents = [...this.toolBox.contents];

    if (this.builderMode === BuilderMode.SEQUENCE_BUILDER) {
        if (this.categoryMenu.selectedCategory.id === Category.DEVICE || this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
             this.toolBox.contents = this.toolBox.contents.filter((category: any) => !this.hideBlockCategory.includes(category.name));
             if(!this.showClearBlock && this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
              this.toolBox.contents = [...this.originalToolBoxContents];
              this.toolBox.contents = this.toolBox.contents.filter((category: any) => !this.hideBlockCategory.includes(category.name));
              this.toolBox.contents = this.toolBox.contents.map((category: any) => {
                category.contents = category.contents.filter((block: any) => !this.hideBlockTypes.includes(block.type));
                return category;
              });
            }
        } else {
          this.toolBox.contents = [...this.originalToolBoxContents];
        }
        this.getAndCreateSequenceFunctions();
    } else {
      this.toolBox.contents = this.toolBox.contents.filter((category: any) => !this.hideBlockCategory.includes(category.name));
      if (this.categoryMenu.selectedCategory.id === Category.SYSTEM && !this.showClearBlock) {
        this.toolBox.contents = this.toolBox.contents.map((category: any) => {
          category.contents = category.contents.filter((block: any) => !this.hideBlockTypes.includes(block.type));
          return category;
        });
      }
      this.initializeBlocklyWorkspace();
    }
}


  initializeBlocklyWorkspace() {
    this.toggleBlocksByBuilderMode();
    this.toggleCategoriesByBuilderMode();
    
    Blockly.defineBlocksWithJsonArray(this.blocksToInit);
    if(this.categoryMenu.selectedCategory.id === Category.DEVICE) {
      DeviceCodeGenerate.addCodeGenerators();
    } else {
      if(this.categoryMenu.selectedCategory.id === Category.CLOUD) {
        dynamicBlockBuilder.httpHeaderBlocksInit();
        dynamicBlockBuilder.httpRequestBlocksInit();
        dynamicBlockBuilder.httpRequestBodyBlocksInit();
      }
      CodeGenerate.addCodeGenerators();
      CodeGenerate.sequenceTemplates();
      CodeGenerate.customFunctions();
      CodeGenerate.unitConversionTemplates();
      CodeGenerate.externalAPIItegrattionTemplates();
      BlocklyValidators.addValidators();
    }


    if (!this.blocklyWorkSpace) {
      this.blocklyWorkSpace = Blockly.inject(this.blocklyContainerId, {
        toolbox: this.toolBox,
        grid: {
          spacing: 10,
          length: 2,
          snap: true,
          colour: '#ccc'
        },
        zoom:
        {
          controls: true,
          startScale: 0.8,
          maxScale: 3,
          minScale: 0.3,
          scaleSpeed: 1.2,
          pinch: true,
          wheel: true
        },
        move:{
          drag: true,
          wheel: true
        },
        trashcan: true,
      });
      
      let blockStyle = BlockTheme.blockStyles;
      let categorySyle = categoryTheme.categoryStyle;
      var theme = Blockly.Theme.defineTheme('customTheme', {
        blockStyles: blockStyle,
        categoryStyles: categorySyle,
        name: ''
      });

      this.blocklyWorkSpace.setTheme(theme);
      
      this.initCrossTabCopyPaste();
      this.blocklyWorkSpace.getToolbox()?.setVisible(false);
      this.formatCategoryNames();
      this.initializeToolboxSearch();
      
      setTimeout(() => {
        this.subscribeToBlocklyEvents();
      }, 500);
    }
  }

  // Getting sequence functions from API and creating the minimal function blocks for the functions in sequence builder dropdown
  getAndCreateSequenceFunctions() {
    this.isLoading = true;
    if(this.builderMode === BuilderMode.SEQUENCE_BUILDER) {
      this.sequencerFunctionCollection = [];
      this.siteSequencerService.getSequenceFunctionsList(this.SequenceFunctionsComponent).subscribe((res: any) => {
        if(res) {
          res.createdByMeList.forEach((functionObj: any) => functionObj['category'] = 'Created by Me');
          res.createdByOthers.forEach((functionObj: any) => functionObj['category'] = 'Shared by Others');
          this.sequencerFunctionCollection = [...res.createdByMeList, ...res.createdByOthers];
          
          // Setting the type for each block 
          this.sequencerFunctionCollection.forEach((blockObj: any) => {
            const defaultType = `fn_${blockObj.functionId}`
            blockObj['type'] = this.createUniqueValueForKey(defaultType, 'type', this.sequencerFunctionCollection);
          });

          this.duplicateFunctionBlock.createdByMeFunctions = ObjectUtil.deepClone(res.createdByMeList);
          this.duplicateFunctionBlock.createdByOtherFunctions = ObjectUtil.deepClone(res.createdByOthers); 
          this.dropdownFunctionsList.createdByMeFunctions = ObjectUtil.deepClone(res.createdByMeList);
          this.dropdownFunctionsList.createdByOtherFunctions = ObjectUtil.deepClone(res.createdByOthers);
          this.sequencerFunctionCollection.forEach((blockObj: any) => {
            // Creating the block definition and code generation for each function block based on category type
            if(this.categoryMenu.selectedCategory.id === Category.DEVICE) {
              // Method to create miniature blocks for sequence functions in device category.
              dynamicDeviceBlockBuilder.sequencerFunctionblockInit(blockObj);
            } else {
              // Method to create miniature blocks for sequence functions in cloud and system category.
              dynamicBlockBuilder.sequencerFunctionblockInit(blockObj);
            }
          });
          this.initializeBlocklyWorkspace();
          if(!this.previousMode && this.selectedSite) {
            this.toggleWorkspace('create');
          }
          if(this.previousMode === BuilderMode.LIBRARY_BUILDER) {
            this.toggleWorkspace('create');
            this.previousMode = null;
          }
        } else {
          this.sequencerFunctionCollection = [];
          this.initializeBlocklyWorkspace();
          if(this.previousMode === BuilderMode.LIBRARY_BUILDER) {
            this.toggleWorkspace('create');
            this.previousMode = null;
          }
          
        }
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        this.sequencerFunctionCollection = [];
        this.showToast('error', `${err.message}`);
        this.initializeBlocklyWorkspace();
        if(this.previousMode === BuilderMode.LIBRARY_BUILDER) {
          this.toggleWorkspace('create');
          this.previousMode = null;
        }
      });
    }
  }

   /**
   * Adding the function as a miniature block that is dragged and dropped from custom blocks dropdown to workspace. 
   * @param blockConfig {any} - the function block object that is dragged from the Custom blocks dropdown.
   */
  addSelectedFunctionBlockInWorkspace(event: any, blockConfig: any) {
    const dynamicBlock = this.blocklyWorkSpace.newBlock('fn_' + blockConfig.functionId);
    dynamicBlock.initSvg();
    this.moveDynamicBlock(event, dynamicBlock);
    dynamicBlock.render();
  }

  moveDynamicBlock(event: any, dynamicBlock: any) {
    //Converting the x,y pixel coordinates to workspace coordinates
    const pointX = event.dropPoint.x / this.blocklyWorkSpace.scale;
    const pointY = event.dropPoint.y / this.blocklyWorkSpace.scale;
    const metrics = this.blocklyWorkSpace.getMetricsManager().getAbsoluteMetrics(true);
    let workspaceX = pointX - metrics.left - (this.blocklyWorkSpace.scrollX / this.blocklyWorkSpace.scale);
    let workspaceY = pointY - metrics.top - (this.blocklyWorkSpace.scrollY / this.blocklyWorkSpace.scale);
    const allBlocks = this.blocklyWorkSpace.getAllBlocks();
    while (!this.isPositionEmpty(workspaceX, workspaceY, allBlocks)) {
      workspaceX += 10;
      workspaceY += 20;
    }
    dynamicBlock.moveBy(workspaceX, workspaceY);
  }
  
  isPositionEmpty(x: any, y: any, allBlocks: any[]) {
    for (let i = 0; i < allBlocks?.length; i++) {
        const block = allBlocks[i];
        const blockPosition = block.getRelativeToSurfaceXY();
        if (blockPosition.x === x && blockPosition.y === y) {
            return false; // not empty
        }
    }
    return true; // is empty
  }

  // Removing the Sequence block from the category in the toolbox when navigated from the Sequence builder to the library builder and vice versa.
  toggleBlocksByBuilderMode() {
    const targetCategory = this.categoryMenu.selectedCategory.id === Category.DEVICE ? CustomCategory.PREDEFINED_DEVICE : CustomCategory.PREDEFINED;
    const toolBox = JSON.parse(JSON.stringify(this.toolBox));
    const targetCategoryIndex = toolBox.contents.findIndex((category: any) => category.name === targetCategory);
    const targetBlocksToHide: any[] = [];

    // Pushing the blocks to hide into the targetBlocksToHide array based on the builder mode
    if(this.builderMode === BuilderMode.SEQUENCE_BUILDER) {
      targetBlocksToHide.push(BlockType.SEQUENCER);
      if(this.categoryMenu.selectedCategory.id === Category.CLOUD) {
        targetBlocksToHide.push(BlockType.SEQUENCE_SYSTEM);
      } else if(this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
        this.hideDeprecatedBlocksFromToolbox(toolBox);
        targetBlocksToHide.push(BlockType.SEQUENCE);
      }
    } else if(this.builderMode === BuilderMode.LIBRARY_BUILDER){
      targetBlocksToHide.push(BlockType.SEQUENCE);
      targetBlocksToHide.push(BlockType.SEQUENCE_SYSTEM);
      targetBlocksToHide.push(BlockType.SEQUENCE_DEVICE);
      if(this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
        this.hideDeprecatedBlocksFromToolbox(toolBox);
      }
    }
    
    targetBlocksToHide.forEach((blockType: any) => {
      // Removing the sequence block from category
      if(targetCategoryIndex >= 0) {
        const blockIndex = toolBox.contents[targetCategoryIndex].contents.findIndex((blockObj: any) => blockObj.type === blockType);
        blockIndex >= 0 ? toolBox.contents[targetCategoryIndex].contents.splice(blockIndex, 1) : ''; 
      }
    });
    this.toolBox = toolBox;
  }

  hideDeprecatedBlocksFromToolbox(toolBox: any) {
    toolBox.contents.forEach((item:any) => {
      if (item.name === "Custom Primitives") {
          item.contents = item.contents.filter((block:any) => 
              !this.deprecatedBlocks.includes(block.type)
          );
      }
      if(item.name === "Custom Functions") {
        item.contents = item.contents.filter((block:any) => 
            !this.deprecatedBlocks.includes(block.type)
        );
      }
    });
  }

  toggleCategoriesByBuilderMode() {
    if(this.builderMode === BuilderMode.LIBRARY_BUILDER) {
      const toolBox = JSON.parse(JSON.stringify(this.toolBox));
      const categoriesToHide = ['Created by Me', 'Shared by Others'];
      categoriesToHide.forEach((categoryNameToHide: any) => {
        const targetCategoryIndex = toolBox.contents.findIndex((category: any) => category.name === categoryNameToHide);
        if(targetCategoryIndex >= 0) {
          targetCategoryIndex >= 0 ? toolBox.contents.splice(targetCategoryIndex, 1) : '';
        }
      })
      this.toolBox = toolBox;   
    }
  }

  initCrossTabCopyPaste() {
    const options = {
      contextMenu: true,
      shortcut: true,
    };
    const isPlugInExist = Blockly.ContextMenuRegistry.registry.getItem('blockCopyToStorage')
    if(!isPlugInExist) {
      const copyPlugin = new CrossTabCopyPaste();
      copyPlugin.init(options);
    }
    this.overrideCopyPasteInContextMenu();
  }

  overrideCopyPasteInContextMenu() {
    const blockPasteFromStorageObj = Blockly.ContextMenuRegistry.registry.getItem('blockPasteFromStorage');
    const blockCopyToStorageObj = Blockly.ContextMenuRegistry.registry.getItem('blockCopyToStorage');
    if(blockCopyToStorageObj) {
      Blockly.ContextMenuRegistry.registry.unregister('blockCopyToStorage');
      const _this = this;
      const copyFunction = blockCopyToStorageObj.callback;
      Blockly.ContextMenuRegistry.registry.register({
        displayText: function() { return 'Copy'; },
        preconditionFn: function(scope) { return 'enabled'; },
        callback: function(scope) {
          copyFunction(scope);
          _this.addAdditionalParamsForCopiedBlockConfig();
        },
        scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
        id: 'blockCopyToStorage',
        weight: 20
      });
    }

    if(blockPasteFromStorageObj) {
      Blockly.ContextMenuRegistry.registry.unregister('blockPasteFromStorage');
      const _this = this;
      const pasteFunction = blockPasteFromStorageObj.callback;

      Blockly.ContextMenuRegistry.registry.register({
        displayText: function() { return 'Paste'; },
        preconditionFn: function(scope) { return 'enabled'; },
        callback: function(scope) {
          const restrictPaste =  false;
          
          if(restrictPaste) {
            return; // Stop the paste action
          } else {
            pasteFunction(scope); // Perform actual paste action
            _this.isPasteEventTriggered = true;
          }
        },
        scopeType: Blockly.ContextMenuRegistry.ScopeType.WORKSPACE,
        id: 'blockPasteFromStorage',
        weight: 20
      });
    }
  }

  clearWorkspace() {
    this.blocklyWorkSpace.clear();
    !this.editMode ? this.sequencerBlock = null : '';
  }

  addAdditionalParamsForCopiedBlockConfig() {
    const blocklyStash = JSON.parse(localStorage.getItem('blocklyStash') || '{}');
    if(blocklyStash && blocklyStash.blockState) {
      blocklyStash['category'] = this.categoryMenu.selectedCategory.id;
      blocklyStash['builderMode'] = this.builderMode;
      localStorage.setItem('blocklyStash', JSON.stringify(blocklyStash));
    }
  }


  subscribeToBlocklyEvents() {
    this.blocklyWorkSpace.addChangeListener((event: any) => {
      // Handling the blockly events only when the paste event is triggered
      if(this.isPasteEventTriggered && event.type === Blockly.Events.BLOCK_CREATE && event['blockId']) {
        this.disposeInvalidBlocksOnPasteEvent(event); // Method to handle remove invalid blocks from the workspace
      }
      // Handling the blockly events if the builder mode is sequence builder
      if(this.builderMode === BuilderMode.SEQUENCE_BUILDER) {
        // skippping the sequence blocks event handles when the selected category is device
        if(this.categoryMenu.selectedCategory.id !== Category.DEVICE) {
          this.handleSequenceBlockEvents(event);
        } else {
          this.handleDeviceRunBlockEvents(event);
        }
        this.handleSeqFunctionDoubleClick(event);
      } else {
        // Handling the blockly events if the builder mode is library builder
        this.handleFunctionBlockEvents(event);
      }
      this.handleQueryBlockConnections(event);
      this.validateOnBlockEvents(event);
      this.validateBlocklyLogic();
    });
  }

  /* Removes invalid blocks from the workspace by checking if the blocks are supported in the current Builder mode.
   * Ensures only valid blocks are retained, typically when copying blocks between sequence builder and libbrary builder.
   * Disposes the invalid blocks and preserving valid child blocks.
   *
   * @param {any} event - The Blockly workspace event used to identify blocks.
  */
  disposeInvalidBlocksOnPasteEvent(event: any) {
    const blocksToDispose: any[] = []; // Array to track invalid block types
    // Method to check a block and its children
    const handleBlockDisposal = (block: any) => {
      if (!block) return;
      const isBlockValid = this.isBlockValidInCurrentWorkspace(block); // check if the block is supported in the current category and builder mode.
      const children = block.getChildren(true); // Get all the child blocks of the block to validate the child blocks
      if (!isBlockValid) {
        const blockIdentifier = this.getBlockIdentifiers(block); 
        if (!blocksToDispose.includes(blockIdentifier)) { // Add to array only if not already present
          blocksToDispose.push(blockIdentifier); // Add the invalid block type to the array
        }
        // Detach all child blocks to retain them
        children.forEach((child: any) => {
            child.unplug(); // Disconnect the child from the invalid block
        });
        block.dispose(); // Dispose the invalid block
      }
      // check all child blocks for validity
      children.forEach((child: any) => handleBlockDisposal(child));
    }
    // Get the block from the event
    const rootBlock = this.blocklyWorkSpace.getBlockById(event.json.id);
    rootBlock ? handleBlockDisposal(rootBlock) : '';
    if(blocksToDispose.length) {
      const disposedInvalidBlocks = blocksToDispose.join(', ');
      const blockText = blocksToDispose.length === 1 ? 'Block' : 'Blocks';
      this.showToast('error', `${blockText} "${disposedInvalidBlocks}" ${blockText === 'Block' ? 'is' : 'are'} unsupported and ${blockText === 'Block' ? 'was' : 'were'} removed.`);
    }
    this.isPasteEventTriggered = false;
  }

  disposeDeprecatedBlocks() {
    const allBlocks = this.blocklyWorkSpace.getAllBlocks().filter((block: any) => this.deprecatedBlocks.includes(block.type));
    
    if(allBlocks && allBlocks.length > 0){
      allBlocks.forEach((block: any) => {
        const children = block.getChildren(true);
        if(children && children.length > 0) {
          children.forEach((child: any) => {
            child.unplug(); // Disconnect the child from the invalid block
          });
        }
        block.dispose();
      });
    }
  }

  /* Method to check if the block is valid in the current category and builder mode. 
  * @param {block} - The Blockly block object.
  */
  isBlockValidInCurrentWorkspace(block: any) {
    const toolbox = JSON.parse(JSON.stringify(this.toolBox));
    // if the block is not a custom (sequencer function) block - check if the block type exists in the toolbox
    const isTypeInToolbox = (type: any) =>
      toolbox.contents.some((toolboxItem: any) =>
          toolboxItem.kind === toolBoxContentKind.CATEGORY && toolboxItem.contents && toolboxItem.contents.length &&
          toolboxItem.contents.some((contentItem: any) => contentItem.kind === toolBoxContentKind.BLOCK && contentItem.type === type)
      );

    // if the block is a custom (sequencer function) block - check if the block type exists in the sequencer functions collection array.
    const isTypeInSeqFunctionsCollection = (type: any) => this.sequencerFunctionCollection.some((blockObj: any) => blockObj.type === type);
    
    const blockType = block.type;
    let isBlockValid;

    if (blockType.startsWith(BlockType.CUSTOM_BLOCK)) {
      // Skipping the function blocks from the disposal check in Library builder (as we don't have function blocks (miniature blocks) in library builder).
      isBlockValid = this.builderMode === BuilderMode.SEQUENCE_BUILDER ? isTypeInSeqFunctionsCollection(blockType) : false;
    } else if(blockType.startsWith(BlockType.VARIABLES)) {
      // if the block type is variable, check if the variable exists.
      const allVariables = this.blocklyWorkSpace.getAllVariableNames(); // Get all the variable names that are created dynamically in toolbox
      const variableFieldOfBlock = block.getField(BlockFieldName.VARIABLE);
      const currentVariableNameOfblock = variableFieldOfBlock.variable.name; // Get the selected variable name of the block
      if(allVariables && allVariables.length) {
        isBlockValid = allVariables.includes(currentVariableNameOfblock); // check if the selected variable name is matching with the existing variable names
      } else {
        isBlockValid = false;
      }
    } else {
      // Check if the block type exists in the toolbox
      isBlockValid = isTypeInToolbox(blockType);
    }
    return isBlockValid;
  }

  getBlockIdentifiers(block: any) {
    if (block.type.startsWith(BlockType.CUSTOM_BLOCK)) {
      return block.getFieldValue(BlockFieldName.SEQUENCE_FUNCTION_NAME);
    } else if (block.type === BlockType.SEQUENCE) {
      return BlockIdentifier.SEQUENCE;
    } else {
      return block.type;
    }
  }

  validateBlocklyLogic() {
    if(this.categoryMenu.selectedCategory.id !== Category.DEVICE) {
      const jsCode = javascriptGenerator.workspaceToCode(this.blocklyWorkSpace);
      this.code = this.formatCodeForFunctionBlock(jsCode);
    } else {
      let pyCode = pythonGenerator.workspaceToCode(this.blocklyWorkSpace);
      pyCode = this.pythonToMicropython(pyCode);
      const preambleCode = `######## Preamble Section ########\nimport connmod\nimport uniInputMapping\n`;
      const superloopCode = '######## Superloop Section ########\n' +
                        'connmod.EnterSequence()\n' +
                        runStartupPythonFun + '()\n' +
                        'connmod.ExitSequence()\n' +
                        'while True:\n' +
                        '  connmod.DelayMs(' + sequenceRunRateMs + ')\n' +
                        '  connmod.EnterSequence()\n' +
                        '  ' + runContinuousPythonFun + '()\n' +
                        '  connmod.ExitSequence()';    
      const formattedPyCode = this.formatCodeForFunctionBlock(pyCode);
      this.code = this.builderMode === BuilderMode.SEQUENCE_BUILDER ? `${preambleCode}\n${formattedPyCode}\n${superloopCode}` : `${formattedPyCode}`;
    }

    let currentXml = this.parseXMLForSequence();
    currentXml === XMLEnum.DEFAULT_XML ? currentXml = '' : '';
    let savedWorkspaceXml = this.workspaceXML;
    savedWorkspaceXml === XMLEnum.DEFAULT_XML ? savedWorkspaceXml = '' : '';
    this.noChangesInWorkspace = savedWorkspaceXml === currentXml;
  }

  formatCodeForFunctionBlock(jsCode: any) {
    let code = jsCode;
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    const functionBlocksInWorkspace = allBlocks.filter((blockObj: any) => blockObj.type.includes('fn_'));
    
    if(functionBlocksInWorkspace.length > 0) {
      functionBlocksInWorkspace.forEach((block: any) => {
        const functionBlockCode = block.getFieldValue('snippet');
        code = `${functionBlockCode}\n${code}`;
      }); 
    }
    return code;
  }

  /** Method to format the category names to append the blocks count to the category names */
  formatCategoryNames() {
    const toolboxBlocks = this.blocklyWorkSpace?.getToolbox().getToolboxItems();
    toolboxBlocks.forEach((blockObj: any) => {
      if(blockObj.toolboxItemDef_?.kind == 'category') {
        const target = blockObj['rowDiv_'].querySelector('.blocklyTreeLabel');
        if (target) {
          let catergoryName = blockObj.getName();
          let count = (catergoryName != 'Variables' && catergoryName != 'Functions') ? `(${blockObj.flyoutItems_.length})` : '';
          target.textContent = `${catergoryName} ${count}`;
        }
      }
    });
  }

  /** Method to add a placeholder in the searchbox in toolbox */
  initializeToolboxSearch() {
    const toolboxBlocks = this.blocklyWorkSpace?.getToolbox().getToolboxItems();
    const searchObj = toolboxBlocks[0]['rowDiv_'].querySelector('input');
    searchObj.placeholder = 'Search for Blocks/Sequence';
  }

  /** Method to change the placeholder message on the page */
  togglePlaceholderMessage() {
    const allBlocks = this.blocklyWorkSpace?.getAllBlocks();
    if(this.selectedSiteId) {
      this.placeholderMessage = allBlocks.length > 0 ? '' : (this.categoryMenu.selectedCategory.id !== Category.CLOUD ? 
          `This site does not have any ${this.categoryMenu.selectedCategory.label} sequences applied` : 'This site does not have any sequences applied');
    } else {
      this.placeholderMessage = 'Select a site';
    }
  }

  /** Method to sort the breadcrumbs based on the relative coordinates of the sequence blocks*/
  sortBreadCrumbs() {
    this.breadcrumbs = [];
    this.sequenceTabs = [];
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    const sequenceBlocks = allBlocks.filter((blockObj: any) => blockObj.type == this.sequenceBlockType);
    if(sequenceBlocks.length > 0) {
      // Sorting sequence by x value of it's relative coordinates
      const sortedSequences = sequenceBlocks.sort((obj1: any, obj2: any) => obj1.relativeCoords.x - obj2.relativeCoords.x);
      sortedSequences.forEach((seqObj: any) => {
        const seqName = seqObj.getFieldValue('markername');
        const seqId = seqObj.getFieldValue('sequenceId');
        const breadcrumbObj : any = {'seqName': seqName, 'seqId': seqId};
        this.breadcrumbs.push(breadcrumbObj);
        seqObj.getFieldValue('RUN_STATUS') === SequenceStatus.ENABLE_WITH_LOGS ||
          seqObj.getFieldValue('RUN_STATUS') === SequenceStatus.DISABLE_WITH_LOGS ? this.toggleSequenceTab(seqObj, 'create') : '';
      });
    }
  }

  validateOnBlockEvents(event: any) {
    if(event && event.blockId && !this.isFunctionBlockClicked) {
      const restrictedEvents = ['click', 'drag', 'move', 'selected', 'viewport_change', 'toolbox_item_select', 'viewport_change', 'trashcan_open', 'finished_loading'];
      const allBlocks = this.blocklyWorkSpace.getAllBlocks();
      const targetBlock = event.blockId ? this.blocklyWorkSpace.getBlockById(event.blockId) : null;
      this.togglePlaceholderMessage();
      if(allBlocks.length > 0) {
        // Validating the blocks with input fields when change event is triggered for input field.
        if(event.type == "block_field_intermediate_change" || event.type == "change") {
          if(targetBlock.type === this.sequenceBlockType) {
            this.validateSequenceBlock(targetBlock);
          } else if(targetBlock.type === BlockType.SEQUENCER) {
            this.validateSequencerBlock(targetBlock);
          } else {
            // Validating the block with input fields and associated Sequence block. 
            const rootBlock = targetBlock.getRootBlock();
            if(rootBlock.type === this.sequenceBlockType) {
              this.validateSequenceBlock(rootBlock);
            } else if(targetBlock.type === BlockType.SEQUENCER) {
              this.validateSequencerBlock(targetBlock);
            }
            this.validateInputField(targetBlock);
          }
        }
      }

    }
  }

  /** Method to fetch all sites from API and populates to sites dropdown menu */
  getAllSites() {
    this.isLoading = true;
    this.subscriptions = this.siteService.getSites()
    .subscribe((res: any) => {
      this.isLoading = false;
      this.sites = res.rows.reverse();
      this.checkAndGetSelectedSiteFromFacApp();
    },
    (err) => {
      this.isLoading = false;
      this.sites = [];
      this.showToast('error', `${err.message}`);
    });
  }

  get hostApp() {
    return sessionStorage.getItem('portal');
  }

  getLanguages() {
    this.languages = [
      {"name": "JavaScript", "id": "javascript"}
    ];
    this.getSelectedLanguage();
  }

  /** Method to get the selected options from the languages dropdown menu in the code snippet section */
  getSelectedLanguage() {
    this.selectedLanguageId = this.languages[0].id;
  }

  /** Method to get the selected site from the session storage and to set the selected option for sites dropdown menu in facilisight */
  checkAndGetSelectedSiteFromFacApp() {
    let portal = this.locaStorageService.getPortal();
    if(portal == HostRoute.FACILISIGHT || this.userSelectedSiteInternal?.length) {
      let selectedSiteId = this.userSelectedSiteInternal?.length ? this.userSelectedSiteInternal : sessionStorage.getItem("siteNameFromFacPortal");
      selectedSiteId = selectedSiteId ? this.stripHaystackTypeMapping(selectedSiteId) : '';
      if(selectedSiteId) {
        const selectedSiteArray = this.sites.filter(siteObj => this.stripHaystackTypeMapping(siteObj.id) == selectedSiteId);
        this.getSelectedSite(selectedSiteArray);
      }
    }
  }

  /** Method to strip the haystack type mapping from the response */
  stripHaystackTypeMapping(response: any) {
    return (response) ? JSON.parse(JSON.stringify(response).replace(/"r:|"c:|"n:|"t:|"b:|"m:|"z:|"s:|"d:/g, '"')) : '';
  }

  /** Method to show or hide toolbox in blockly workspace on button click in UI */
  showHideToolBox(value: boolean) {
    this.showToolBox = value;
    !value ? this.blocklyWorkSpace.getFlyout().setVisible(value) : ''; 
    this.blocklyWorkSpace.getToolbox()?.setVisible(value);
  }

  /** Method to show or hide generated code block in bottom section on button click in UI */
  showHideCodeSnippetBlock() {
    this.isCodeBlockVisible = !this.isCodeBlockVisible;
    this.codeBlockTooltipText = this.isCodeBlockVisible ? "Collapse Code Snippet" : "Expand Code Snippet";
  }

  /** Method to perform operations after a site is selected from sites dropdown menu 
  * @param {any} site - Selected site data from sites dropdown menu.
  */
  getSelectedSite(site: any) {
    if(site && site?.length > 0 && this.selectedSiteId != site?.[0]?.id.replace('r:','')) {
      window.parent.postMessage(JSON.stringify(site), '*');
      this.isSequenceTabSelected = false;
      this.activeTabIndex = 0;
      this.selectedSite = site[0];
      this.siteId = this.selectedSite.id;
      this.selectedSiteId = this.selectedSite.id.replace('r:','');
      this.resetCategoryTabs();
      // clearing the blocklyworkspace when switching from one site to another.
      this.blocklyWorkSpace ? this.toggleWorkspace('delete') : '';
      const selectedCategoryTabConfig = {'index': this.categoryMenu.activeTabIndex, tab: {'textLabel': this.categoryMenu.selectedCategory.label}};
      this.toggleCategoryLevelTab(selectedCategoryTabConfig, true);
    } else if(site.length == 0) {
      this.isLoadingAllSequences = false;
      this.selectedSite = null;
      this.showSequenceActionButtons = false;
    }
  }

  resetCategoryTabs() {
    this.categoryMenu.activeTabIndex = 0;
    this.categoryMenu.selectedCategory = this.categoryMenu.tabs[0];
  }

  /** Method to perform operations by creating or deleting (re-initializing) the blockly workspace based on builder mode 
  * @param {string} eventType - The event type to create or delete the blockly workspace.
  * create - To create the blockly workspace and populate the blocks in workspace from XML.
  * delete - To delete the blockly workspace and clear the blocks from the workspace.
  */
  toggleWorkspace(eventType: string) {
    if(eventType == 'create') {
      this.isLoadingAllSequences = true;
      this.modifiedFunctionBlocks = [];
      this.blocklyWorkSpace ? this.blocklyWorkSpace.clear() : '';
      if(this.builderMode === BuilderMode.LIBRARY_BUILDER) { 
        this.defaultTabLabel = DefaultTabName.LIBRARY_BUILDER;
        setTimeout(() => {
          this.buildAndLoadFunctionFromXml();
        }, 500);
      } else {
        this.buildAndLoadSequencesFromXml();
      }
      this.showHideToolBox(!this.isWorkspaceReadOnly);
      this.showToolBoxToggleButton = !this.isWorkspaceReadOnly;
      this.editMode = !this.isWorkspaceReadOnly && !this.isBuildingNewSequenceFunction;
      this.showSequenceActionButtons = true;
    } else {
      this.isLoadingAllSequences = true;
      this.showHideToolBox(false);
      this.showToolBoxToggleButton = false;
      this.showSequenceActionButtons = false;
      this.blocklyWorkSpace.clear();
      this.blocklyWorkSpace.dispose();
      this.blocklyWorkSpace = null;
      this.breadcrumbs = [];
      this.isLoadingAllSequences = false;
    }
  }

  getWorkSpaceBySite() {
    this.isLoading = true;
    return this.siteSequencerService.getWorkspaceBySite(this.selectedSiteId, this.canvasTabs.selectedCanvasTab?.id);
  }

  saveWorkspaceXML(showToastMessage?: boolean, skipStoringXML?: boolean) {
    let xml = Blockly.Xml.workspaceToDom(this.blocklyWorkSpace);
    var xmlString = new XMLSerializer().serializeToString(xml);
    let workspaceRef: any = {
      "siteRef": this.selectedSiteId,
      "workspaceXml": xmlString || '',
      "category": this.categoryMenu.selectedCategory.id,  // "category": "CLOUD" or "CCU" or "DEVICE"
      "name": this.canvasTabs.selectedCanvasTab?.name,
      "id": this.canvasTabs.selectedCanvasTab?.id
    }

    this.siteSequencerService.saveWorkspace(workspaceRef).subscribe((res) => {
      !skipStoringXML ? this.workspaceXML = xmlString : '';
      if (showToastMessage) {
        this.isLoading = false;
        if(this.canvasTabs.selectedCanvasTab) {
          this.canvasTabs.selectedCanvasTab.workspaceXml = xmlString;
          this.updateWorkspaceXMLInCanvasTab(this.canvasTabs.selectedCanvasTab, workspaceRef);
        }
        this.showToast('success', 'Workspace saved successfully');
      }
    }, (err) => {
      this.showToast('error', `${err.message}`);
    });
  }

  saveSequence(sequence: any) {
    const quartzCronRequest = this.getQuartzCronRequestValue(sequence);
    let payload: any = {
      "siteRef": this.selectedSiteId,
      "seqName": sequence.seqName.trim(),
      "blocklyXml": sequence.blocklyXml || '',
      "snippet": sequence.snippet || '',
      "quartzCronRequest": quartzCronRequest,
      'enabled': sequence.isEnabled,
      'workspaceId': this.canvasTabs.selectedCanvasTab?.id,
    }
    sequence.seqId ? payload['seqId'] = sequence.seqId : ''; 
    if(this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
      payload['scope'] = sequence.selectedScopeValue || [null];
    }
    return this.siteSequencerService.saveOrUpdateSequence(payload);
  }

  getQuartzCronRequestValue(sequence: any) {
    if(!sequence['frequency']) sequence['frequency'] = 'EVERY_MINUTE';
    const currentUTCTime = new Date(Date.now());
    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const dayIndex = currentUTCTime.getDay();
    const dayUTC = daysOfWeek[dayIndex];
    const hoursUTC = currentUTCTime.getHours();
    const minutesUTC = currentUTCTime.getMinutes();
    let payload: any = {
      "frequency": sequence['frequency']
    };
    if(sequence['frequency'] == 'DAILY' || sequence['frequency'] == 'EVERY_MONTH') {
      payload['hour'] = hoursUTC;
      payload['minute'] = minutesUTC;
    } else if(sequence['frequency'] == 'HOURLY') {
      payload['minute'] = minutesUTC;
    } else if(sequence['frequency'] == 'EVERY_MINUTE') {
      payload['minute'] = 1;
    } else if(sequence['frequency'] == 'WEEKLY'){
      payload['hour'] = hoursUTC;
      payload['minute'] = minutesUTC;
      payload['dayOfWeek'] = dayUTC;
    }
    return payload;
  }

  updateAllSequences(payload: any) {
    const selectedCanvasTabId :string  = this.canvasTabs.selectedCanvasTab?.id || '';
    return this.siteSequencerService.updateAllSequences(this.selectedSiteId, selectedCanvasTabId,  payload);
  }

  loadSavedState() {
    let currentWorkspaceXml = this.parseXMLForSequence();
    currentWorkspaceXml === XMLEnum.DEFAULT_XML ? currentWorkspaceXml = '' : '';
    this.sequences = [];
    this.breadcrumbs = [];
    this.sequenceTabs = [];
    this.deviceRunBlocksOnWorkspace = []; // Clearing the existing device run blocks on workspace
    this.errorBlocks = [];
    this.blocklyWorkSpace.clear();
    this.isLoadingAllSequences = true;
    const savedWorkspaceXml = this.workspaceXML; // Saved XML string when blocks are loaded into the workspace during initial load.
    this.workspaceXML === XMLEnum.DEFAULT_XML ? this.workspaceXML = '' : '';
    this.noChangesInWorkspace = this.workspaceXML === currentWorkspaceXml; // Comparing the current workspace XML with the saved workspace XML to enable/disable the save button.
    if(this.workspaceXML) {
      const loadedBlocksFromXml: any = this.addBlocksToCanvasFromXML(savedWorkspaceXml);
      loadedBlocksFromXml.then((blocks: any) => {
        if(blocks && blocks.length > 0) {
          this.buildSequenceCollection();
          // To avoid triggering create event handler after populating all blocks to workspace. 
          setTimeout(() => {
            this.isLoadingAllSequences = false;
          }, 3000)
        } else {
          this.isLoadingAllSequences = false;
        }
      }).catch((err: any) => {
        this.isLoadingAllSequences = false;
      })
    }
  }
  
  // Load default blocks in to the workspace and save the sequence
  loadDefaultDeviceBlocks() {
    this.isLoadingAllSequences = true;
    const json = JSON.parse(JSON.stringify(DEFAULT_TEMPLATE));
    Blockly.serialization.workspaces.load(json, this.blocklyWorkSpace); // Loading default blocks to workspace
    const newSequence: any = this.deviceSequenceConfig;
    this.blocklyWorkSpace?.scrollCenter();
    // Saving the sequence without sequence id
    this.saveSequence(newSequence).subscribe((sequenceObj: any) => {
      if(sequenceObj && sequenceObj['seqId']) {
        newSequence['seqId'] = sequenceObj.seqId;  // Setting the created sequence Id to the sequence object
        this.sequences.push(newSequence);  // Adding sequence block to the collection
      }
      this.saveWorkspaceXML();
      this.noChangesInWorkspace = true; // Setting the flag to true after loading the default blocks to avoid the save button to be enabled.
    });
  }

  buildAndLoadSequencesFromXml() {
    this.sequences = [];
    this.breadcrumbs = [];
    this.sequenceTabs = [];
    this.deviceRunBlocksOnWorkspace = []; // Clearing the existing device run blocks on workspace
    this.togglePlaceholderMessage();
    this.getWorkSpaceBySite()
    .subscribe((res) =>{
      if(res && res.workspaceXml) {
        this.workspaceXML = res.workspaceXml;
        this.extractDeletedFunctionBlocksFromXml(this.workspaceXML);
        const loadedBlocksFromXml: any = this.addBlocksToCanvasFromXML(this.workspaceXML);
        loadedBlocksFromXml.then((blocks: any) => {
          
          if(blocks && blocks.length > 0) {
            if(this.categoryMenu.selectedCategory.id === Category.DEVICE) {  
              this.buildDeviceSequenceCollection(); // Building the device sequence collection from the populated blocks in the workspace.
              this.blocklyWorkSpace?.scrollCenter();
            } else {
              if(this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
                this.disposeDeprecatedBlocks();
              }
              this.buildSequenceCollection();
              // Bringing the sequence to the center of the canvas, after switching from the sequence tab to the default tab.
              if(this.sequenceTohighlight) {
                this.getBlockIntoView(this.sequenceTohighlight, true);
                this.sequenceTohighlight = null;
              }
            }
            this.modifiedFunctionBlocks = [];
            this.UpdateFunctionBlocksInCanvas();
            this.disposeDeletedFunctionBlocks();
            this.getUpdatesForFunctionBlocks();
            this.togglePlaceholderMessage();

            // To avoid triggering create event handler after populating all blocks to workspace. 
            setTimeout(() => {
              this.isLoadingAllSequences = false;
            }, 3000)
          } else {
            if(this.categoryMenu.selectedCategory.id === Category.DEVICE) {
              if(this.isCreatingNewDeviceCanvas) {
                this.loadDefaultDeviceBlocks();
                this.isCreatingNewDeviceCanvas = false;
              } else {
                this.buildDeviceSequenceCollection();
              }
            }
            this.isLoadingAllSequences = false;
          }
          this.togglePlaceholderMessage();
        }).catch((err: any) => {
          this.isLoadingAllSequences = false;
        });
      } else {
        if(this.categoryMenu.selectedCategory.id === Category.DEVICE && this.isCreatingNewDeviceCanvas) {  
          this.loadDefaultDeviceBlocks();
          this.isCreatingNewDeviceCanvas = false;
        } else {
          this.workspaceXML = '';
        }
        this.isLoadingAllSequences = false;
      }
      this.isLoading = false;
      // Setting the noChangesInWorkspace flag to true after loading the blocks from XML to avoid the save button to be enabled.
      this.noChangesInWorkspace = true; 

    },(err: any)=>{
      this.showToast('error', `${err.message}`);
      this.isLoading = false;
      this.isLoadingAllSequences = false;
      this.workspaceXML = '';
      this.noChangesInWorkspace = true;
    });
  }

  /**
   * Checking for deleted sequence functions that are used in sequences and registering them as blocks to remove from the workspace. 
  */
  extractDeletedFunctionBlocksFromXml(xml: any) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    // Extracting the blocks from the XML and adding to the workspace.
    const blocks = Array.from(xmlDoc.getElementsByTagName('block'));
    const functionBlocksToDelete: any = [];
    blocks.forEach(block => {
      const type = block.getAttribute('type');
      const isBlockFoundInFlyout = this.sequencerFunctionCollection.find((flyoutBlock: any) => flyoutBlock.type === type);
      if (type && type.includes('fn_') && !isBlockFoundInFlyout) {
        const blockObj = {"type": type, "seqFunctionName": '', "what": '',"why": '', "xml": '', "snippet": '', "functionId": ''};
        // Iterate through child nodes of the block
        const childNodes = Array.from(block.childNodes);
        childNodes.forEach((node: any) => {
          if (node.nodeName === 'field') {
            const fieldName = node.getAttribute('name');
            if (fieldName === 'xml') {
              const blockXml = node.textContent.trim();
              // Parse the inner XML to extract seqFunctionName
              const innerXmlParser = new DOMParser();
              const innerXmlDoc = innerXmlParser.parseFromString(blockXml, 'text/xml');
              const seqFunctionNameField: any = innerXmlDoc.querySelector('field[name="seqFunctionName"]');
              if (seqFunctionNameField) {
                blockObj.seqFunctionName = seqFunctionNameField.textContent.trim();
              }
            }
          }
        });
        functionBlocksToDelete.push(blockObj);
      }
    });
    functionBlocksToDelete.forEach((blockObj: any) => {  
      this.categoryMenu.selectedCategory.id === Category.DEVICE ? dynamicDeviceBlockBuilder.sequencerFunctionblockInit(blockObj) :
        dynamicBlockBuilder.sequencerFunctionblockInit(blockObj)
    });

  }

  // get the sequence block config for device category
  get deviceSequenceConfig() {
    const deviceSequenceName = this.canvasTabs.selectedCanvasTab?.name;
    const deviceSequence = {
      "seqName": deviceSequenceName,
      'blocklyXml': this.parseXMLForSequence(this.blocklyWorkSpace),
      'snippet': this.getJSCodeForSequence('', deviceSequenceName),
      'frequency': 'EVERY_MINUTE',
      'isEnabled': true,
      'alertBlocks': [],
      'functionIds': []
    }
    return deviceSequence;
  }

  // Building the device sequence collection from the populated blocks in the workspace.
  buildDeviceSequenceCollection() {
    // Getting all sequences of the workspace
    const defaultSequence = JSON.parse(JSON.stringify(this.deviceSequenceConfig));
    const selectedCanvasTabId :string  = this.canvasTabs.selectedCanvasTab?.id || '';
    this.siteSequencerService.getAllSequencesPerWorkspace(this.selectedSiteId, selectedCanvasTabId)
    .subscribe((res) => {
      this.sequences = [];
      if(res && res.length > 0) {
        this.sequences = [...res]; // Adding sequence to the sequence collection
      } else {
        // Creating a new sequence If WorkspaceXML has value but no sequences found for workspace.
        this.sequences = [];
        // Saving the sequence withouth sequence id
        this.saveSequence(defaultSequence).subscribe((sequenceObj: any) => {
          if(sequenceObj && sequenceObj['seqId']) {
            defaultSequence['seqId'] = sequenceObj.seqId;  // Setting the created sequence Id to the sequence object
            this.sequences.push(defaultSequence);  // Adding sequence block to the collection
          }
          this.saveWorkspaceXML();
        });
      } 
    }, err=>{
      this.sequences = [defaultSequence];

    });
  }

  buildSequenceCollection() {
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    // Building sequence collection by filtering the sequence blocks from all blocks in workspace.
    const sequenceBlocks = allBlocks.filter((blockObj: any) => blockObj.type === this.sequenceBlockType);
    sequenceBlocks.forEach((sequenceBlock: any) => {
      let sequenceName = sequenceBlock.getFieldValue('markername') || '';
      const sequenceId = sequenceBlock.getFieldValue('sequenceId');
      
      // Building breadcrumb from each sequence and pushing into breadcrumb collection.
      let breadcrumbObj : any= {
        "seqName": sequenceName,
        "seqId": sequenceId
      };
      this.breadcrumbs.push(breadcrumbObj);
      
      // Checking the Run status of each sequence and creating a sequence tab if the run status is either 'Enable with logs' or 'Disable with logs'
      const seqTabEventType = sequenceBlock.getFieldValue('RUN_STATUS') === SequenceStatus.ENABLE_WITH_LOGS ||
        sequenceBlock.getFieldValue('RUN_STATUS') === SequenceStatus.DISABLE_WITH_LOGS ? 'create' : '';
      this.toggleSequenceTab(sequenceBlock, seqTabEventType);

      const isEnabled = sequenceBlock.getFieldValue('RUN_STATUS') === SequenceStatus.ENABLE ? true : false;
      const sequenceObj = {
        'seqId': sequenceBlock.getFieldValue('sequenceId'),
        'seqName': sequenceName,
        'blocklyXml': this.parseXMLForSequence(sequenceBlock),
        'snippet': this.getJSCodeForSequence(sequenceBlock,sequenceName),
        'frequency': sequenceBlock.getFieldValue('Occurance'),
        'isEnabled': isEnabled
      }
      if(sequenceBlock.type === BlockType.SEQUENCE_SYSTEM) {
        this.setScopeLabelForSystemSequences(sequenceBlock);
      }
      this.sequences.push(sequenceObj);
      sequenceBlock.getField('sequenceId').setVisible(false);
      this.blocklyWorkSpace.render();
    });
  }

    /**
   * Disposing or removing the miniature function block that is used within sequences on the workspace, when the owner deletes the function from the library. 
   */
  disposeDeletedFunctionBlocks() {
    const isDeviceCategorySelected = this.categoryMenu.selectedCategory.id === Category.DEVICE;
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    const functionBlocksInCanvas = allBlocks.filter((canvasBlockObj: any) => canvasBlockObj.type.includes('fn_'));
    if(functionBlocksInCanvas.length > 0) {
      const functionBlocksToDispose = functionBlocksInCanvas.filter((blockObj: any) => {
        const targetFunctionId = blockObj.getFieldValue('functionId');
        return !this.sequencerFunctionCollection.some((flyoutBlock: any) => flyoutBlock.functionId === targetFunctionId)
      });
      if(functionBlocksToDispose.length > 0) {
        functionBlocksToDispose.forEach((fnBlock: any) => {
          const sequenceBlockObj = !isDeviceCategorySelected ? fnBlock.getRootBlock() : '';
          const sequenceName = !isDeviceCategorySelected ? sequenceBlockObj.getFieldValue('markername') : this.canvasTabs.selectedCanvasTab?.name;
          this.buildModifiedFunctionBlocksCollection('delete', fnBlock, sequenceName);
          fnBlock.dispose();
        });
        this.saveWorkspaceXML();
        !isDeviceCategorySelected ? this.saveAllSequences(true) : '';
      }
    }
  }

    /**
   * Updating the fields of miniature function blocks that is used in the workspace, when the corresponding function definition gets updated.
   */

  UpdateFunctionBlocksInCanvas() {
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    if(allBlocks.length > 0 && this.sequencerFunctionCollection.length > 0) {
      const functionBlocksInCanvas = allBlocks.filter((canvasBlockObj: any) => canvasBlockObj.type.includes('fn_'));
      if(functionBlocksInCanvas.length > 0) {
        functionBlocksInCanvas.forEach((canvasBlockObj: any) => {
          const targetFunctionBlockInDropdown = this.sequencerFunctionCollection.find((toolboxBlockObj: any) => toolboxBlockObj.type === canvasBlockObj.type);
          if(targetFunctionBlockInDropdown) {
            canvasBlockObj.setFieldValue(targetFunctionBlockInDropdown.seqFunctionName, 'seqFunctionName');
            canvasBlockObj.setFieldValue(targetFunctionBlockInDropdown.functionId, 'functionId');
            canvasBlockObj.setFieldValue(targetFunctionBlockInDropdown.xml, 'xml');
            canvasBlockObj.setFieldValue(targetFunctionBlockInDropdown.snippet, 'snippet');
            canvasBlockObj.setFieldValue(targetFunctionBlockInDropdown.why, 'why');
            canvasBlockObj.setFieldValue(targetFunctionBlockInDropdown.what, 'what');
          }
        }); 
      }

    }
  }

    /**
   * fetching the miniature blocks that are used in the workspace, when the corresponding function definition gets updated.
   */

  getUpdatesForFunctionBlocks() {
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    const sequenceBlocksInCanvas = allBlocks.filter((blockObj: any) => blockObj.type === this.sequenceBlockType);
    const selectedCanvasTabId: string = this.canvasTabs.selectedCanvasTab?.id || '';
    const isDeviceCategorySelected = this.categoryMenu.selectedCategory.id === Category.DEVICE;

    this.siteSequencerService.getAllSequencesPerWorkspace(this.selectedSiteId, selectedCanvasTabId).subscribe((res: any) => {
      if (res && res.length > 0) {
        const allSequencesOfSite = res;

        const processFunctionBlocks = (functionBlocks: any[], targetSequenceObj: any, sequenceName: string) => {
          functionBlocks.forEach((functionBlockObj: any) => {
            const targetFunctionObj = this.sequencerFunctionCollection.find((fnObj: any) => fnObj.functionId === functionBlockObj.getFieldValue('functionId'));
            if (targetFunctionObj) {
              const functionUpdatedDate = new Date(targetFunctionObj.createdBy.dateTime);
              const sequenceUpdatedDate = new Date(targetSequenceObj.modifiedBy.dateTime);
              if (functionUpdatedDate > sequenceUpdatedDate) {
                this.buildModifiedFunctionBlocksCollection('update', functionBlockObj, sequenceName);
              }
            }
          });
        };
        
        if (!isDeviceCategorySelected) {
          sequenceBlocksInCanvas.forEach((sequenceBlockObj: any) => {
            const sequenceName = sequenceBlockObj.getFieldValue('markername');
            const childBlocksOfSequence = sequenceBlockObj.getDescendants('ordered');
            const functionBlocksInSequence = childBlocksOfSequence?.filter((blockObj: any) => blockObj.type.includes('fn_')) || [];
            const targetSequenceObj = allSequencesOfSite.find((sequenceObj: any) => sequenceObj.seqId === sequenceBlockObj.getFieldValue('sequenceId'));
            processFunctionBlocks(functionBlocksInSequence, targetSequenceObj, sequenceName);
          });
        } else {
          const sequenceName = allSequencesOfSite[0].seqName;
          const functionBlocks = allBlocks.filter((blockObj: any) => blockObj.type.includes('fn_'));
          processFunctionBlocks(functionBlocks, allSequencesOfSite[0], sequenceName);
        }
        if (this.modifiedFunctionBlocks.length > 0) {
          this.showSeqFunctionUpdatesInPopup();
        }
      }
    });
  }

  /**
   * creating the modified or deleted miniature sequence function collection to display them in a popup.
   * @param {string} eventType - The event type to identify whether the function block is updated or deleted. 
   * @param {any} updatedFunctionBlock - The updated function block object.
   * @param {any} sequenceName - The name of the sequence to which the function block is associated.
   */

  buildModifiedFunctionBlocksCollection(eventType: string, updatedFunctionBlock: any, sequenceName: any) {
    const keyName = eventType ? eventType === 'delete' ? 'deletedFunctions' : 'updatedFunctions' : '';
    const updatedSeqFunctionName = updatedFunctionBlock.getFieldValue('seqFunctionName');
    const existingSequenceIndex = this.modifiedFunctionBlocks.findIndex((item: any) => item.sequence === sequenceName);
   
    if(existingSequenceIndex >= 0) {
      const isExistingFunction = this.modifiedFunctionBlocks[existingSequenceIndex][keyName].includes(updatedSeqFunctionName);
      if(!isExistingFunction) {
        this.modifiedFunctionBlocks[existingSequenceIndex][keyName].push(updatedSeqFunctionName);
      }
    } else {
      let updatedSequenceObj: any = {
        "sequence" : sequenceName,
      }
      updatedSequenceObj[keyName] = [updatedSeqFunctionName]
      this.modifiedFunctionBlocks.push(updatedSequenceObj);
    }
  }

  /**
   * Displaying the modified or deleted sequence function names in a popup along with corresponding sequence name.
   */

  showSeqFunctionUpdatesInPopup() {
    const dialogRef = this.dialog.open(SequenceValidationPopupComponent, {
      panelClass: 'fs-mat-dialog-container',
      minWidth: '480px',
      disableClose: true
    });
    const title = 'Sequence Function Updates';
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.htmlContent = this.modifiedFunctionBlocks;
    dialogRef.componentInstance.type = 'dialog';
    dialogRef.componentInstance.showConfirmIcon = true;
    dialogRef.updatePosition({ top: '60px' });
    dialogRef.afterClosed().subscribe(result => {});
  }

  formatXml(workspaceXml: any) {
    const xmlStart = `<xml xmlns=\"https://developers.google.com/blockly/xml\">`;
    const xmlEnd = `</xml>`
    const wordToReplace = '<block xmlns=\"https://developers.google.com/blockly/xml\"';
    const replacementWord = '<block'
    const modifiedString = workspaceXml.replace(new RegExp(wordToReplace, 'g'), replacementWord);
    const resultXml = `${xmlStart}${modifiedString}${xmlEnd}`;
    return resultXml;
  }

  buildAndLoadFunctionFromXml() {
    this.sequencerBlock = null;
    if(this.selectedFunctionToView && this.selectedFunctionToView.xml) {
      const workspaceXML = this.formatXml(this.selectedFunctionToView.xml);
      const loadedBlocksFromXml: any = this.addBlocksToCanvasFromXML(workspaceXML);
      loadedBlocksFromXml.then((blocks: any) => {
        if(blocks && blocks.length > 0) {
          const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
          const sequencerBlock = allBlocks.find((blockObj: any) => blockObj.type === BlockType.SEQUENCER);
          sequencerBlock.getField('functionId').setVisible(false);
          this.blocklyWorkSpace.render();
          this.sequencerBlock = {
            "seqFunctionName" : sequencerBlock.getFieldValue('seqFunctionName'),
            "xml": this.parseXMLForSequence(sequencerBlock),
            "snippet": this.getJSCodeForSequence(sequencerBlock, ''),
            "functionId": sequencerBlock.getFieldValue('functionId'),
            "why": sequencerBlock.getFieldValue('why'),
            "what": sequencerBlock.getFieldValue('what')
          }
          this.defaultTabLabel = this.sequencerBlock.seqFunctionName ? this.sequencerBlock.seqFunctionName : DefaultTabName.LIBRARY_BUILDER;
          // Bringing the sequencer to the center of the canvas.
          const blockToHighlight = {"id": sequencerBlock.id};
          this.getBlockIntoView(blockToHighlight, false);
        // To avoid triggering create event handler after populating all blocks to workspace. 
          setTimeout(() => {
            this.isLoadingAllSequences = false;
          }, 3000)
        } else {
          this.isLoadingAllSequences = false;
        }
      }).catch((err: any) => {
        this.isLoadingAllSequences = false;
      })
    } else {
      this.isLoadingAllSequences = false;
    }
    this.isLoading = false;
  }

  async addBlocksToCanvasFromXML(workspaceXML: any) {
    // Populating the blocks in canvas using the workspaceXml
    const parser = new DOMParser();
    const xmlDoc:any = parser.parseFromString(workspaceXML || '', "text/xml");
    this.blocklyWorkSpace.clear();
    const loadedBlocks = await Blockly.Xml.domToWorkspace(xmlDoc.documentElement, this.blocklyWorkSpace);
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    allBlocks.forEach((blockObj: any) => blockObj['isCreated'] = true);  // Adding a property isCreated to the loaded blocks from xml to avoid triggering the create sequence event. 
    return loadedBlocks;
  }

  /** Method to update the sequence block with the updated sequence name 
   * @param {any} blockId - The block id of the sequence block to find in workspace.
   * @param {any} fieldName - Field name in sequencer block.
   * @param {any} fieldValue - Field value to update in targetField on sequence block.
  */
  setAndHideFieldsinBlock(blockId: any, fieldName: any, fieldValue: any) {
    this.isUpdatingExistingMarker = true;
    const block = this.blocklyWorkSpace.getBlockById(blockId);
    let field = block.getField(fieldName);
    field.setValue(fieldValue);
    field.setVisible(false);
    this.blocklyWorkSpace.render();
  }

  handleFunctionBlockEvents(event: any) {
    const targetBlock = this.blocklyWorkSpace.getBlockById(event.blockId);
    const currentBlockType = event.type === 'create' ? event.json?.type : (event.type === 'delete' ? event.oldJson?.type : '');
    if((targetBlock && targetBlock.type === BlockType.SEQUENCER) || currentBlockType === BlockType.SEQUENCER) {
      if(event.type === 'create') {
        this.handleFunctionCreation(event, targetBlock);
      } else if(event.type === 'delete') {
        this.handleFunctionDeletion(event);
      } else if(event.type === 'block_field_intermediate_change') {
        this.handleFunctionInputFieldChange(event, targetBlock);
      }
    }
  }

  handleFunctionCreation(event: any, targetBlock: any) {
    if(!this.isLoadingAllSequences) {
      if(!this.editMode) {
        if(!this.sequencerBlock) {
          const defaultFunctionName = this.getUniqueValueWithCopySuffix(DefaultTabName.LIBRARY_BUILDER, 'seqFunctionName', this.sequencerFunctionCollection);
          const existingName = targetBlock.getFieldValue('seqFunctionName');
          this.sequencerBlock = {
            "seqFunctionName": existingName ? existingName : defaultFunctionName,
            "why": '.',
            "what": '.',
            "xml": this.parseXMLForSequence(targetBlock),
            "snippet": this.getJSCodeForSequence(targetBlock, ''),
          }
          this.activeSequencerBlockRef = targetBlock.id;
          targetBlock.setFieldValue(this.activeSequencerBlockRef, 'functionId');
          targetBlock.getField('functionId').setVisible(false);
        } else {
          this.isLoadingAllSequences = true;
          targetBlock.dispose();
          this.showToast('error', `Sequencer definition should not contain multiple sequencer blocks.`);
          setTimeout(() => {
            this.isLoadingAllSequences = false;
          }, 500);
        }

      } else {
        const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
        const existingSequencerBlock = allBlocks.find((blockObj: any) => blockObj.getFieldValue('functionId') === this.sequencerBlock.functionId);
        if(!existingSequencerBlock) {
          targetBlock.setFieldValue(this.sequencerBlock.functionId, 'functionId');
          targetBlock.getField('functionId').setVisible(false);
        } else {
          this.isLoadingAllSequences = true;
          targetBlock.dispose();
          this.showToast('error', `Sequencer definition should not contain multiple sequencer blocks.`);
          setTimeout(() => {
            this.isLoadingAllSequences = false;
          }, 500);
        }
      }
    }
  }

  handleFunctionDeletion(event: any) {
    if(!this.isLoadingAllSequences && !this.editMode) {
      this.sequencerBlock = null;
      this.defaultTabLabel = DefaultTabName.LIBRARY_BUILDER;
    }
  }

  formatDate(date: any) {
    return dayjs.utc(date).local().format('MMM D, YYYY | h:mm A');
  }

  handleFunctionInputFieldChange(event: any, targetBlock: any) {
    if(event.name === 'seqFunctionName') {
      this.sequencerBlock.seqFunctionName = event.newValue;
      this.defaultTabLabel = event.newValue ? event.newValue : DefaultTabName.LIBRARY_BUILDER;
      this.updateSequencerBlockConfig(targetBlock);
    }
  }
  
  /**
   * Method to handle device run block events on workspace.
   * @param {any} event - event object from blockly.
   */

  handleDeviceRunBlockEvents(event: any) {
    if(event.blockId) {
      const allowedDeviceRunBlockTypes = [DeviceRunBlockType.RUN_BLOCK_STARTUP, DeviceRunBlockType.RUN_BLOCK_CONTINUOUS];
      const targetBlock = this.blocklyWorkSpace.getBlockById(event.blockId);
      const currentBlockType = event.type === 'create' ? event.json?.type : (event.type === 'delete' ? event.oldJson?.type : '');
      const blockType = targetBlock && targetBlock.type ? targetBlock.type : currentBlockType;
      if(allowedDeviceRunBlockTypes.includes(blockType)) {
        if(event.type === 'create') {
          const targetRunBlocksOnCanvas = this.deviceRunBlocksOnWorkspace.find((blockObj: any) => blockObj.type === blockType);
          if(targetRunBlocksOnCanvas) {
            // Showing error when the run block is already exists in the workspace
            this.isLoadingAllSequences = true;
            targetBlock.dispose();
            this.showToast('error', `Workspace should not contain more than one ${blockType} block.`);
            setTimeout(() => {
              this.isLoadingAllSequences = false;
            }, 500);
          } else {
            // Pushing the run block to the deviceRunBlocksOnWorkspace collection if not exists on workspace.
            this.deviceRunBlocksOnWorkspace.push(targetBlock);
          }
        } else if(event.type === 'delete' && !this.isLoadingAllSequences) {
          // Removing the run block from the deviceRunBlocksOnWorkspace collection when the block is deleted from workspace.
          this.deviceRunBlocksOnWorkspace = this.deviceRunBlocksOnWorkspace.filter((blockObj: any) => blockObj.id !== event.blockId);
        }
  
      }
    }
  
  }

  getBlockTypeFromEvent(event: any) {
    const targetBlock = this.blocklyWorkSpace.getBlockById(event.blockId);
    const currentBlockType = event.type === 'create' ? event.json?.type : (event.type === 'delete' ? event.oldJson?.type : '');
    const blockType = targetBlock && targetBlock.type ? targetBlock.type : currentBlockType;
    return blockType;
  }

  handleSequenceBlockEvents(event: any) {
    const targetBlock = this.blocklyWorkSpace.getBlockById(event.blockId);
    const currentBlockType = event.type === 'create' ? event.json?.type : (event.type === 'delete' ? event.oldJson?.type : '');
    if((targetBlock && targetBlock.type === this.sequenceBlockType) || currentBlockType === this.sequenceBlockType) {
      if(event.type === 'create') {
        if(targetBlock.type === BlockType.SEQUENCE || currentBlockType === BlockType.SEQUENCE) {
          this.handleSequenceCreation(event, targetBlock);
        } else if(targetBlock.type === BlockType.SEQUENCE_SYSTEM || currentBlockType === BlockType.SEQUENCE_SYSTEM) {
          this.handleSystemSequenceBlockCreateEvent(event, targetBlock);
        } else {
          this.handleDeviceSequenceBlockCreateEvent(event, targetBlock);
        }
      } else if(event.type === 'delete') {
        this.handleSequenceDeletion(event);
      } else if(event.type === 'block_field_intermediate_change') {
        event.newValue ? this.handleSequenceInputFieldChange(event, targetBlock) : '';
      } else if(event.type === 'change') {
        this.handleSequenceDropdownChange(event, targetBlock);
      } else if(event.type === 'move') {
        // Handling the move events of sequence blocks.
        this.sortBreadCrumbs();
      }
    }
  }

  handleSystemSequenceBlockCreateEvent(event: any, targetBlock: any) { 
    this.handleSequenceCreation(event, targetBlock);
    this.subcribeToScopeDropdownClick(targetBlock);
    this.subscribeTodeliveryLogsButtonClick(targetBlock);
  }

  handleDeviceSequenceBlockCreateEvent(event: any, targetBlock: any) {
    if(!this.isUpdatingExistingMarker && !this.isLoadingAllSequences && !targetBlock['isCreated']) {
      if(this.sequences.length >= 1) {
        //show error
        this.isLoadingAllSequences = true;
        targetBlock.dispose();
        this.showToast('error', `Workspace should not contain more than one device sequences`);
        setTimeout(() => {
          this.isLoadingAllSequences = false;
        }, 500);
      } else {
        this.handleSequenceCreation(event, targetBlock);
      }
    }
  }

  subcribeToScopeDropdownClick(targetBlock: any) {
    const dropdownField = targetBlock.getField('scope');
    dropdownField.fieldGroup_.addEventListener('click', (event: any) => {
      // Define what happens when the dropdown is clicked
      Blockly.hideChaff(); // To hide the dropdown menu
      this.showScopeSelectionDialog(targetBlock);
    });
  }

  subscribeTodeliveryLogsButtonClick(targetBlock: any) {
    const deliveryLogsButton = targetBlock.getField('deliveryLogs');
    deliveryLogsButton.fieldGroup_.addEventListener('click', (event: any) => {
      this.isLoading = true;
      const seqId = targetBlock.getFieldValue('sequenceId');
      const sequenceScope = targetBlock.getFieldValue('scope');
      if(sequenceScope === scope.CUSTOM_SCOPE) {
        const sequenceObj = this.sequences.find((seqObj: any) => seqObj.seqId === seqId);
        if(sequenceObj.scope && sequenceObj.scope.length > 0 && sequenceObj.scope[0] !== null) {
          this.siteSequencerService.getSequenceDeliveryLogs(seqId)
          .subscribe((res: any) => {
            if(res && res.length > 0) {
              this.showDeliveryLogsDialog(res);
              this.isLoading = false;
            } else {
              this.showToast('error', `No logs found for this sequence.`);
              this.isLoading = false;
            }
          }, (err => {
            this.showToast('error', `${err.message}`);
            this.isLoading = false;
          }));
        } else {
          this.showToast('error', `Please click Save & Run button before viewing the delivery logs.`);
          this.isLoading = false;
        }
      } else {
        this.showToast('error', `Please select scope to view the delivery logs.`);
        this.isLoading = false;
      }
    });
  }

  showDeliveryLogsDialog(deliveryLogs: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: ['fs-mat-dialog-container', 'full-height', 'sequence-logs-modal'],
      width: '752px',
      height : '311px',
      disableClose: true
    });
    const headerColumns = ['status', 'ccuName', 'deliveredDateTime', 'ackedDateTime'];
    const headerColumnsToDisplay = headerColumns.filter(key => Object.keys(deliveryLogs[0]).includes(key));
    const htmlContent = {
      sequenceLogstableData: deliveryLogs,
      sequenceLogsHeaderColumns: headerColumnsToDisplay
    }
    dialogRef.componentInstance.htmlContent = htmlContent;
    dialogRef.componentInstance.type = 'delivery_logs';
    dialogRef.componentInstance.showHeader = false;
    dialogRef.componentInstance.isSearchFieldRequired = false;
    dialogRef.updatePosition({ top: '60px' });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  handleSequenceCreation(event: any, targetBlock: any) {
    if(!this.isUpdatingExistingMarker && !this.isLoadingAllSequences && !targetBlock['isCreated']) {
      const defaultSequenceName = this.generateRandomString();
      targetBlock.setFieldValue('', 'markername');
      const newSequence: any = {
        seqName: defaultSequenceName,
        blocklyXml: this.parseXMLForSequence(targetBlock),
        snippet: this.getJSCodeForSequence(targetBlock, defaultSequenceName),
        frequency: targetBlock.getFieldValue('Occurance'),
        isEnabled: targetBlock.getFieldValue('RUN_STATUS') === SequenceStatus.ENABLE ? true : false,
      }
      this.categoryMenu.selectedCategory.id === Category.SYSTEM ? newSequence['scope'] = [null] : '';
      // Saving the new sequence without passing sequence id in payload
      this.saveSequence(newSequence).subscribe((sequenceObj: any) => {
        if(sequenceObj && sequenceObj['seqId']) {
          // Appending the obtained sequenceId to the new sequence.
          newSequence['seqId'] = sequenceObj.seqId;
          const createdBlock = this.blocklyWorkSpace.getBlockById(event.blockId);
          // Adding the obtained sequence id to the sequenceId field of the sequence block.
          if(this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
            this.setAndHideFieldsinBlock(createdBlock.id, 'selectedScopeValue', [null]);
          }
          this.setAndHideFieldsinBlock(createdBlock.id, 'sequenceId', sequenceObj.seqId);
          newSequence['blocklyXml'] = this.parseXMLForSequence(createdBlock);
          newSequence['snippet'] = this.getJSCodeForSequence(createdBlock,newSequence.seqName);
          // Saving (updating) the new sequence after obtaining the sequence ID. 
          this.saveSequence(newSequence).subscribe((seqObj: any) => {
          }, (err: any) => {
            this.handleSequenceCreationError(targetBlock, 'sequence', err);
          });

          // Adding the new sequence to the sequence collection
          this.sequences.unshift(newSequence);
          this.sortBreadCrumbs();
        } else {
          this.handleSequenceCreationError(targetBlock, 'sequence');
        }
        this.isUpdatingExistingMarker = false;
      }, (err: any) => {
        this.handleSequenceCreationError(targetBlock, 'sequence', err);
        this.isUpdatingExistingMarker = false;
      });
    }
  }

  handleSequenceCreationError(targetSequenceBlock: any, BlockType: any, error?: any) {
    this.showToast('error', `${error && error.error ? error.error.error : BlockType + 'creation failed.'}`);
    const targetBlock = this.blocklyWorkSpace.getBlockById(targetSequenceBlock.id);
    targetBlock ? targetBlock.dispose() : '';
  }

  handleSequenceDeletion(event: any) {
    if(!this.isLoadingAllSequences) {
      const sequenceIdToDelete = event.oldJson?.fields?.sequenceId;
      const indexToDelete = this.sequences.findIndex((seqObj) => seqObj.seqId == sequenceIdToDelete);
      const breadcrumbIndexToDelete = this.breadcrumbs.findIndex((breadcrumbObj: any) => breadcrumbObj.seqId === event?.oldJson?.fields?.sequenceId);
      const seqTabToDelete = {'seqId': sequenceIdToDelete};
      if(indexToDelete >= 0) {
        this.sequences.splice(indexToDelete, 1);
        this.breadcrumbs.splice(breadcrumbIndexToDelete,1);
        if(this.categoryMenu.selectedCategory.id === Category.CLOUD) {
          this.toggleSequenceTab(seqTabToDelete, 'delete');
        }
      }
    }
  }

  handleSequenceInputFieldChange(event: any, targetBlock: any) {
    if(event.name == 'markername') {
      const seqIdInBlock = targetBlock.getFieldValue('sequenceId'); 
      const index = this.sequences.findIndex((seqObj) => seqObj.seqId == seqIdInBlock);
      this.sequences[index].seqName = event.newValue;
      // Updating breadcrumb for renamed sequence.
      const breadcrumbIndex = this.breadcrumbs.findIndex((breadcrumb: any) => breadcrumb.seqId === seqIdInBlock);
      if(breadcrumbIndex >= 0){
        this.breadcrumbs[breadcrumbIndex].seqName = event.newValue;
        if(this.categoryMenu.selectedCategory.id === Category.CLOUD) {
          this.toggleSequenceTab(targetBlock, 'rename');
        }
      }
    }
  }

  handleSequenceDropdownChange(event: any, targetBlock: any) {
    if(event.name === 'Occurance') {
      const seqIdInBlock = targetBlock.getFieldValue('sequenceId'); 
      const index = this.sequences.findIndex((seqObj) => seqObj.seqId == seqIdInBlock);
      this.sequences[index].frequency = event.newValue;
    } else if(event.name === 'RUN_STATUS') {
      const previousValue = event.oldValue;
      const seqIdInBlock = targetBlock.getFieldValue('sequenceId');
      const index = this.sequences.findIndex((seqObj) => seqObj.seqId == seqIdInBlock);
      const selectedRunStatus = targetBlock.getFieldValue('RUN_STATUS');
      const isEnabled = selectedRunStatus  == SequenceStatus.ENABLE ? true : false;
      this.sequences[index].isEnabled = isEnabled;
      if(this.categoryMenu.selectedCategory.id === Category.CLOUD) {
        const seqTabEventType = targetBlock.getFieldValue('RUN_STATUS') === SequenceStatus.ENABLE_WITH_LOGS ||
          targetBlock.getFieldValue('RUN_STATUS') === SequenceStatus.DISABLE_WITH_LOGS ? 'create' : '';
        this.toggleSequenceTab(targetBlock, seqTabEventType);
      }
      this.sortBreadCrumbs();
      if(selectedRunStatus === SequenceStatus.DRY_RUN) {
        this.previousRunStatus = previousValue;
        this.activateDryRun(targetBlock);
      } 
    }
  }

  toggleSequenceTab(targetBlockObj: any, eventType: string) { 
    if(eventType === 'create') {
      const sequenceTab = {
        'seqName': targetBlockObj.getFieldValue('markername'),
        'seqId': targetBlockObj.getFieldValue('sequenceId'),
        'status': targetBlockObj.getFieldValue('RUN_STATUS')
      };
      const sequenceIndexInTabs = this.sequenceTabs.findIndex((sequenceObj) => sequenceObj.seqId === sequenceTab.seqId);
      sequenceIndexInTabs < 0 ? this.sequenceTabs.push(sequenceTab) : '';

    } else if(eventType === 'delete') {
      const targetSequenceId = targetBlockObj.seqId ? targetBlockObj.seqId : targetBlockObj.getFieldValue('sequenceId');
      const sequenceIndexInTabs = this.sequenceTabs.findIndex((sequenceObj) => sequenceObj.seqId === targetSequenceId);
      sequenceIndexInTabs >= 0 ? this.sequenceTabs.splice(sequenceIndexInTabs, 1) : '';

    } else if(eventType === 'rename') {
      const targetSequenceId = targetBlockObj.getFieldValue('sequenceId');
      const updatedSequenceName = targetBlockObj.getFieldValue('markername');
      const sequenceIndexInTabs = this.sequenceTabs.findIndex((sequenceObj) => sequenceObj.seqId === targetSequenceId);
      sequenceIndexInTabs >= 0 ? this.sequenceTabs[sequenceIndexInTabs].seqName = updatedSequenceName : '';
    }

    this.tempSequenceTab = this.sequenceTabs;
  }

  /**
   * Switches to the selected tab.
   * 
   * @param selectedTabData - The data of the selected tab.
   */
  switchToSelectedTab(selectedTabData: any) {
    // Skipping the canvas or logs tab switching functionality in library builder mode.
    if (this.builderMode === BuilderMode.LIBRARY_BUILDER) {
      return;
    }

    this.searchText = '';
    this.isSearchActive = false;
    this.canvasTabs.tabs = this.tempCanvasTabs;

    // Resetting the Sequence tab data when switching to canvas tab.
    this.isSequenceTabSelected = false;
    this.resetSequenceLogs();
    this.switchToSelectedCanvasTab(selectedTabData);
    this.canvasTabToSwitch = null;
  }

  searchTabs(event: any) {
    const searchText = event.target.value;
    let searchedTabs = [];
    if(searchText) {
      const allTabs = [...this.canvasTabs.tabs, ...this.sequenceTabs];
      searchedTabs = allTabs.filter((tab: any) => {
        const tabName = tab.name ? tab.name : tab.seqName;
        return tabName.toLowerCase().includes(searchText.toLowerCase());
      });
    }
  }
  
  /**
   * Creating new canvas tab upon clicking add canvas button and when fetching and creating a new workspace for the selected category level.  
   * @param {boolean} initWorkspace - The flag to initialize the workspace if true and to clear and update same workspace when false.
   * 1. Creating a new workspace by calling saveWorkspace API (without workspaceId). 
   * 2. Updating the workspace by calling the saveWorkspace API with the obtained workspaceId.  
  */
  createNewCanvasTab(initWorkspace?: boolean) {
    this.isLoading = true;
    this.searchText = '';
    this.isSearchActive = false;
    this.canvasTabs.tabs = JSON.parse(JSON.stringify(this.tempCanvasTabs));

    this.isCreateCanvasLoading = true
    this.categoryMenu.selectedCategory.id === Category.DEVICE ? this.isCreatingNewDeviceCanvas = true : '';   // Making the flag true when creating a new device canvas tab for populating the default blocks.
  
    // if no workspaces are available for the selected category level.
    // Create a new canvas tab for the selected category level.
    const selectedCategoryLevel = this.categoryMenu.selectedCategory.id;
    if (initWorkspace) {
      this.canvasTabs.tabs = [];
      this.canvasTabs.selectedCanvasTab = null;
    }
    const defaultCanvasTabName = (DefaultCategoryTabName as any)[selectedCategoryLevel];
    const canvasTabConfig: any = {
      "siteRef": this.selectedSiteId,
      "workspaceXml": '',
      "category": selectedCategoryLevel,
      "name": this.createUniqueValueForKey(defaultCanvasTabName, 'name', this.canvasTabs.tabs, ' '),
    }

    // Calling SaveWorkspace API without passing Id to register a new workspace for the selected category level.
    this.siteSequencerService.saveWorkspace(canvasTabConfig)
    .subscribe((res: any) => {
      if (res && res.id) {
        canvasTabConfig['id'] = res.id;
        // Updating the  workspace with the obtained id
        this.siteSequencerService.saveWorkspace(canvasTabConfig)
        .subscribe((res: any) => {
          if (res) {
            canvasTabConfig.workspaceXml = res.workspaceXml;
            canvasTabConfig['isCanvasNameEdit'] = false;
            canvasTabConfig['tempName'] = canvasTabConfig.name;
            canvasTabConfig['showMenu'] = false;
            this.canvasTabs.tabs.length > 0 ? this.canvasTabs.tabs.push(canvasTabConfig) : this.canvasTabs.tabs = [canvasTabConfig];
            this.tempCanvasTabs = JSON.parse(JSON.stringify(this.canvasTabs.tabs));
            this.canvasTabs.activeTabIndex = this.canvasTabs.tabs.length > 0 ? (this.canvasTabs.tabs.length - 1) :  0;
            this.activeTabIndex = this.canvasTabs.activeTabIndex;
            if(initWorkspace) {
              this.canvasTabs.selectedCanvasTab = canvasTabConfig;
            }
            // Calling switchToSelectedCanvasTab() method to switch to the newly created canvas tab.
            this.switchToSelectedCanvasTab(canvasTabConfig, initWorkspace)
          }
          this.isCreateCanvasLoading = false;
        }, (error: any) => {
          this.isLoading = false;
          this.isCreateCanvasLoading = false;
          this.showToast('error', error.error);
        });
      }
    }, (err: any) => {
      this.isLoading = false;
      this.isCreateCanvasLoading = false;
      this.showToast('error', err.error);
    });
      
  }

  /**
   * Switching to selected canvas tab. 
   * @param selectedCanvasTabConfig {any} - The selected canvas tab configuration.
  */
  switchToSelectedCanvasTab(selectedCanvasTabConfig: any, initWorkspace?: boolean, isDeletingWorkspace?: boolean) {
    this.isLoading = true;
    const switchTab = () => {
      this.canvasTabs.selectedCanvasTab = selectedCanvasTabConfig;
      this.canvasTabs.activeTabIndex = this.canvasTabs.tabs.findIndex((tab: any) => tab.name === selectedCanvasTabConfig.name);
      this.builderMode = BuilderMode.SEQUENCE_BUILDER; // Setting the builder mode
      this.previousMode = null;
      this.blocklyWorkSpace.clear();
      this.buildAndLoadSequencesFromXml()
    };

    if(!initWorkspace && this.blocklyWorkSpace) {
      const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);  
      if (allBlocks.length === 0 && isDeletingWorkspace || this.noChangesInWorkspace) {
        // If the Blockly workspace is empty, switch the tab immediately
        switchTab();
      } else {
        // If the Blockly workspace is not empty, check if blocks are valid
        if(!isDeletingWorkspace) {
          const isSequencesValid = this.checkAndReturnErrorStatus();
          if (isSequencesValid) {
            // If blocks are valid, switch the tab after saving workspace state.
            this.saveWorkspaceXML();
            this.saveAllSequences(true);
            switchTab();
          } else {
             // If blocks are not valid, fall back to the previous tab
            const previousCanvasTab = this.canvasTabs.selectedCanvasTab;
            const previousTabIndex = this.canvasTabs.tabs.findIndex((tab: any) => tab.name === previousCanvasTab?.name);
            this.activeTabIndex = previousTabIndex;
            this.isLoading = false;
          }
        }
      }
    } else {
      this.canvasTabs.selectedCanvasTab = selectedCanvasTabConfig;
      this.canvasTabs.activeTabIndex = this.canvasTabs.tabs.findIndex((tab: any) => tab.name === selectedCanvasTabConfig.name);
      this.initializeBlockly();
    }
  }


  /**
   * Retrieves the selected sequence tab and performs necessary actions based on the tab switch.
   * 
   * @param sequence - The selected sequence tab.
   */
  getSelectedSequenceTab(sequence: any) {
    // Skipping the canvas or logs tab switching functionality in library builder mode.
    if (this.builderMode === BuilderMode.LIBRARY_BUILDER) {
      return;
    }
    this.sequenceLogs = [];
    let isSequencesValid = false;
    const isSwitchingFromDefaultTab = !this.isSequenceTabSelected;
    if (isSwitchingFromDefaultTab) {
      isSequencesValid = this.checkAndReturnErrorStatus();
      if (isSequencesValid) {
        if (!this.noChangesInWorkspace) {
          this.saveWorkspaceXML();
          this.saveAllSequences(true);
        }
      } else {
        return;
      }
    }
    sequence ? this.sequenceTohighlight = JSON.parse(JSON.stringify(sequence)) : '';
    this.selectedSequenceTab = sequence;
    this.isSequenceTabSelected = true;
    this.resetSequenceLogs();

    if (!isSwitchingFromDefaultTab) {
      if (this.selectedSequenceTab) {
        // When switching between sequence tabs - get logs for selected sequence
        this.subscribeToSequenceLogs();
      } else {
        // when switching from a sequence tab to canvas tab - Re-render the workspace
        setTimeout(() => {
          this.previousMode = BuilderMode.LIBRARY_BUILDER;
          const categoryLevelTabConfig = { 'index': this.categoryMenu.activeTabIndex, 'tab': { "textLabel": this.categoryMenu.selectedCategory.name } };
          this.toggleCategoryLevelTab(categoryLevelTabConfig, true);
        }, 500);
      }
    } else {
      // when switching to sequence tab from canvas tab - save the sequences and clear the workspace
      if (isSequencesValid) {
        this.subscribeToSequenceLogs();
        this.toggleWorkspace('delete');
      }
    }
  }

  /**
   * Resetting the sequence logs data and unsubscribe the sequence logs response when switched to canvas tab from sequence logs tab. 
   */
  resetSequenceLogs() {
    this.sequenceLogs = [];
    this.sequenceLogstableData = [];
    this.sequenceLogsHeaderColumns = [];
    clearInterval(this.sequenceLogsSubscription);
  }

  subscribeToSequenceLogs() {
    const isDryRunSelected = false;
    this.getSequenceLogs(this.selectedSequenceTab.seqId, isDryRunSelected);
    // Calling the sequence logs API for every minute (Refreshing the sequence logs);
    this.sequenceLogsSubscription = setInterval(() => {
      this.getSequenceLogs(this.selectedSequenceTab.seqId, isDryRunSelected);
    }, this.sequenceLogsRefreshTime);
  }
  
  getSequenceLogs(sequenceId: any, isDryRunSelected: boolean) {
    const sequenceRunMode = isDryRunSelected ? 'DRY_RUN' : 'SCHEDULED_RUN';
    this.siteSequencerService.getSequenceLogs(this.selectedSiteId, sequenceId, sequenceRunMode)
    .subscribe((res: any) => {
      if(res && res.length > 0) {
        this.isLoadingAllSequences = false;
        this.sequenceLogs = res;
        // Replacing key name 'level' with 'type' 
        const logsData = res[0].logs.map((logObj: any) => {
          if('level' in logObj) {
            const {level, ...rest} = logObj;
            return {type: level, ...rest};
          }
          return logObj;
        });
        this.sequenceLogs[0].logs = logsData;
        this.sequenceLogstableData = this.sequenceLogs[0].logs;
        this.sequenceLogsHeaderColumns = [...Object.keys(this.sequenceLogstableData[0])];
        isDryRunSelected ? this.showDryRunLogs(false) : '';
        this.isLoading = false;
      } else {
        isDryRunSelected ? this.showDryRunLogs(true) : '';
        this.isLoadingAllSequences = false;
        this.isLoading = false;
      }
    }, (err: any) => {
      this.isLoadingAllSequences = false;
      this.isLoading = false;
      this.showToast('error', `${err.message}`);
    })
  }

  activateDryRun(sequenceBlock: any) {
    this.isLoading = true;
    const isDryRunSelected = true;
    const isSequencesValid = this.checkAndReturnErrorStatus();
    this.selectedSequenceTab = {
      'seqId': sequenceBlock.getFieldValue('sequenceId'),
      'blockId': sequenceBlock.id
    }
    // Fall back to previous selected Run status option of the sequence.
    const targetBlock = this.blocklyWorkSpace.getBlockById(this.selectedSequenceTab.blockId)
    targetBlock.setFieldValue(this.previousRunStatus, 'RUN_STATUS');

    if(isSequencesValid) {
      if(!this.noChangesInWorkspace) {
        this.saveAllSequences(false, isDryRunSelected);
      } else {
        this.triggerSequenceDryRun();
      }
    } else {
      this.isLoading = false;
    }
  }

  // Dry running the sequence and fetching the logs
  triggerSequenceDryRun() {
    this.isLoading = true;
    this.siteSequencerService.dryRunSequence(this.selectedSequenceTab.seqId)
    .subscribe((res) => {
      this.getSequenceLogs(this.selectedSequenceTab.seqId, true);
    }, (err: any) => {
      this.isLoading = false;
      this.showToast('error', `${err.message}`);
    });
  }

  /**
   * Opens a dialog to show dry run logs.
   * 
   * @param isDataNotFound - Indicates whether the data is not found.
   */
  showDryRunLogs(isDataNotFound: boolean) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: ['fs-mat-dialog-container', 'full-height', 'sequence-logs-modal'],
      minWidth: '1000px',
      width: '100%',
      height : 'calc(100vh - 50px)',
      disableClose: true
    });
    const htmlContent = {
      sequenceLogstableData: this.sequenceLogstableData,
      sequenceLogsHeaderColumns: this.sequenceLogsHeaderColumns
    }
    dialogRef.componentInstance.htmlContent = htmlContent;
    dialogRef.componentInstance.type = 'sequence_logs';
    dialogRef.componentInstance.showHeader = false;
    dialogRef.componentInstance.isFixedHeight = isDataNotFound;
    dialogRef.updatePosition({ top: '60px' });
    dialogRef.afterClosed().subscribe(result => {       
      this.selectedSequenceTab = null;
      this.sequenceLogstableData = [];
      this.sequenceLogsHeaderColumns = [];
    });
  }

  openCCULogsModal() {
    const dialogRef = this.dialog.open(CcuLogsModalComponent, {
      panelClass: ['fs-mat-dialog-container', 'full-height', 'sequence-logs-modal', 'ccu-logs-modal'],
      width: '594px',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.componentInstance.selectedSiteId = this.selectedSiteId;
    dialogRef.componentInstance.sequenceList = this.sequences;
    this.previousCCULogsRef ? dialogRef.componentInstance.previousCCULogsRef = this.previousCCULogsRef : {};
    dialogRef.updatePosition({ top: '120px' });
    dialogRef.afterClosed().subscribe(result => {
      const selectedRefs = result ? result : {};
      this.previousCCULogsRef = selectedRefs;
    });
  }

  setScopeLabelForSystemSequences(sequenceBlockObj: any) {
    this.isLoading = true;
    const selectedScopeValue = sequenceBlockObj.getFieldValue('selectedScopeValue');
    if(selectedScopeValue && selectedScopeValue.length > 0 && selectedScopeValue[0] !== null) {
      this.siteSequencerService.getCCUAndEquipDetailsForSite(this.selectedSiteId)
      .subscribe((res: any) => {
        const data = res.ccuDetailsList ? res.ccuDetailsList : [];
        if(data.length > 0) {
          data.forEach((item: any) => {
            item['formattedProfile'] = this.commonService.getFormattedProfileName(item.profile);
          });
        }
        const selectedScopeItems = data.filter((item: any) => selectedScopeValue.includes(item.ccuId));
        this.updateSelectedScopeInSequenceBlock(selectedScopeItems, sequenceBlockObj);
        this.isLoading = false;
      }, (err: any) => {
        this.isLoading = false;
      });
    } else {
      sequenceBlockObj.setFieldValue(scope.DEFAULT_SCOPE, 'scope');
      sequenceBlockObj.setFieldValue([null], 'selectedScopeValue');
      sequenceBlockObj.getField('selectedScopeValue').setVisible(false);
      this.blocklyWorkSpace.render();
      this.isLoading = false;
    }
  }

  showScopeSelectionDialog(targetBlock: any) {
    this.isLoading = true;
    this.siteSequencerService.getCCUAndEquipDetailsForSite(this.selectedSiteId)
    .subscribe((res: any) => {
      const data = res.ccuDetailsList ? res.ccuDetailsList : [];

      // adding selected property in ccuDetailsList array.
      const selectedScopeValue = targetBlock.getFieldValue('selectedScopeValue');
      if(data.length > 0) {
        data.forEach((item: any) => {
          item.selected = selectedScopeValue.includes(item.ccuId);
          item['formattedProfile'] = this.commonService.getFormattedProfileName(item.profile);
        });
      }
      const dialogRef = this.dialog.open(SequenceScopeSelectionModalComponent, {
        panelClass: ['fs-mat-dialog-container', 'full-height', 'scope-selection-modal'],
        minWidth: '318px',
        width: 'auto',
        disableClose: false,
        autoFocus: false
      });
      this.isLoading = false;
      dialogRef.componentInstance.scopeData = data;
      dialogRef.componentInstance.isFixedHeight = data.length > 0 ? false : true;
      dialogRef.updatePosition({ top: '60px' });
      dialogRef.afterClosed().subscribe(result => {
        this.updateSelectedScopeInSequenceBlock(result, targetBlock);
      });
    }, (err: any) => {
      this.isLoading = false;
    });
  }


  /**
   * Updates the selected scope in the sequence block.
   * @param {any} selectedScopeOptions - The selected scope options.
   * @param {any} targetBlock - The target block (system sequence block) to update the selected scope.
  **/

  updateSelectedScopeInSequenceBlock(selectedScopeOptions: any, targetBlock: any) {
    const options = targetBlock.getField("scope").getOptions();  // Getting scope dropdown options.
    let selectedOption!: any;
    let selectedScopes;

    if(selectedScopeOptions.length > 0) {
      let scopeLabel = selectedScopeOptions.length === 1 ? `${selectedScopeOptions[0].ccuName} - ${this.appendEllipse(selectedScopeOptions[0].formattedProfile)}`: 
        (selectedScopeOptions.length > 1 ? `${selectedScopeOptions[0].ccuName} - ${this.appendEllipse(selectedScopeOptions[0].formattedProfile)} & +${(selectedScopeOptions.length - 1)}` : '');
      options[1] = [scopeLabel, scope.CUSTOM_SCOPE];
      selectedOption = scope.CUSTOM_SCOPE;
      selectedScopes = selectedScopeOptions.map((scopeObj: any) => scopeObj.ccuId);
    } else {
      options.splice(1, 1);
      selectedOption = scope.DEFAULT_SCOPE;
      selectedScopes = [];
    }

    targetBlock.setFieldValue(selectedOption, 'scope');
    const selectedScopeLabel = targetBlock.getFieldValue('scope');
    targetBlock.setFieldValue(selectedScopes, 'selectedScopeValue');
    targetBlock.getField('selectedScopeValue').setVisible(false);
    this.blocklyWorkSpace.render();
  }

  /**
   * Handles the query block connections.
   * 
   * @param event - The event object containing the blockId and reason.
   */
  handleQueryBlockConnections(event: any) {
    if(event.blockId) {
      const isConnectEvent = event && event.reason ? event.reason.find((reason: any) => reason == 'connect') : '';
      if(isConnectEvent) {
        const allBlocks = this.blocklyWorkSpace?.getAllBlocks();
        const queryWithConditionBlocks =  allBlocks.filter((blockObj: any) => blockObj.type === 'haystack_query_with_condition');
        if(queryWithConditionBlocks.length) {
          queryWithConditionBlocks.forEach((blockObj: any) => {
            this.checkQueryConnectionType(blockObj);
          });
        }
      }
    }
  }

  /**
   * Checks the connection type of a query block.
   * @param block - The block to check the connection for.
   */
  checkQueryConnectionType(block: any) {
    const inputName = 'Query_condition';
    const validConnectionTypes = ['haystack_query_condition', 'haystack_logical_operator', 'haystack_not_operator'];
    const isInputExist = block.getInput(inputName);
    if(isInputExist) {
      const connectedBlock = block.getInput(inputName).connection.targetBlock();
      if(connectedBlock && validConnectionTypes.indexOf(connectedBlock.type) < 0) {
        connectedBlock.unplug();
        this.showToast('error', `Invalid connection for query block`);
      }
    }
  }

  /**
   * Validate and save the sequence.
   * 
   * @returns {void}
   */
  validateAndSaveSequence() {
    const isSequencesValid = this.checkAndReturnErrorStatus();
    if(this.builderMode === BuilderMode.SEQUENCE_BUILDER) {
      isSequencesValid ? this.saveAllSequences() : '';
    }
  }

  /**
   * Validates and saves the data.
   * 
   * @param showErrorDialog - Optional parameter to indicate whether to show an error dialog.
   * @returns True if the validation is successful, otherwise false.
   */
  checkAndReturnErrorStatus() {
    if(this.noChangesInWorkspace) {
      // Skipping the validation if there are no changes in the workspace.
      return true;
    } else {
      this.errorBlocks = [];
      const allBlocks = this.blocklyWorkSpace?.getAllBlocks();
      allBlocks?.forEach((blockObj: any) => {
        if(this.builderMode === BuilderMode.SEQUENCE_BUILDER) {
          this.categoryMenu.selectedCategory.id !== Category.DEVICE ? this.validateSequenceBlock(blockObj) : '';  // Skipping sequence blocks validation in device category.
        } else {
          this.validateSequencerBlock(blockObj);
        }
        this.validateInputField(blockObj);
      });
      if(this.errorBlocks.length > 0) {
        this.showToast('error', `Invalid block or sequence found. Please ensure all input fields are filled and the blocks are enclosed within the designated sequence`);
        // Highligting the error block
        this.sequenceTohighlight = {"id": this.errorBlocks[0]};
        this.getBlockIntoView(this.sequenceTohighlight, false);
        return false;
      } else {
        return true;
      }
    }
  }
  
  /**
   * Validates a sequence block.
   * 
   * @param block - The block to validate.
   */
  validateSequenceBlock(block: any) {
    if(block.type === this.sequenceBlockType) {
      let warningText = '';
      const sequenceNameLength = 50;
      const sequenceName = block.getFieldValue('markername').toString();
      const forbidSplCharsAtStart = /^(?:[A-Za-z0-9]|$)+[A-Za-z0-9]*$/;
      const forbidSplCharsInString = /^(?:[A-Za-z0-9\s]+)(?:[A-Za-z0-9_\s#%]*)$/;
      const existingSequenceIndex = this.sequences.findIndex((seqObj: any) => seqObj.seqName === sequenceName && seqObj.seqId !== block.getFieldValue('sequenceId'));
      if(sequenceName) {
        if(existingSequenceIndex < 0) {
          if(forbidSplCharsAtStart.test(sequenceName[0])) {
            if(forbidSplCharsInString.test(sequenceName)) {
              warningText = (sequenceName.length > sequenceNameLength) ? `Sequence name should not exceed ${sequenceNameLength} characters` : '';
            } else {
              warningText = "Special characters are not allowed in Sequence name. Allowed characters[underscore(_), hash(#), percentile(%), and space]`";
            }
          } else {
            warningText = "Sequence name should not start with special characters";
          }
        } else {
          warningText = "Sequence name already exists";
        }
      } else {
        warningText = 'Sequence name should not be empty';
      }
      block.setWarningText(warningText);
      this.toggleBlockErrorState(warningText, block);
    }
  }

  /**
   * Validates a sequencer function block.
   * 
   * @param block - The block to validate.
  */
  validateSequencerBlock(block: any) {
    if(block.type === BlockType.SEQUENCER) {
      let warningText = '';
      const seqFunctionNameLength = 50;
      const descriptionLength = 1000;
      const seqFunctionName = block.getFieldValue('seqFunctionName').toString();
      const descWhat = block.getFieldValue('what');
      const descWhy = block.getFieldValue('why');
      const forbidSplCharsAtStart = /^(?:[A-Za-z0-9]|$)+[A-Za-z0-9]*$/;
      const forbidSplCharsInString = /^(?:[A-Za-z0-9\s]+)(?:[A-Za-z0-9_\s#%]*)$/;
      let existingSequenceIndex: any;
      existingSequenceIndex = this.sequencerFunctionCollection.findIndex((functionObj: any) => {
        if(this.selectedFunctionToView && this.selectedFunctionToView.seqFunctionName) {
          return (functionObj.seqFunctionName !== this.selectedFunctionToView.seqFunctionName) && (functionObj.seqFunctionName.toLowerCase()  === seqFunctionName.toLowerCase() );
        } else {
          return functionObj.seqFunctionName.toLowerCase() === seqFunctionName.toLowerCase();
        }
      });

      if(seqFunctionName) {
        if(existingSequenceIndex < 0) {
          if(forbidSplCharsAtStart.test(seqFunctionName[0])) {
            if(forbidSplCharsInString.test(seqFunctionName)) {
              warningText = (seqFunctionName.length > seqFunctionNameLength) ? `Sequencer function name should not exceed ${seqFunctionNameLength} characters` : '';
            } else {
              warningText = "Special characters are not allowed in Sequence name. Allowed characters[underscore(_), hash(#), percentile(%), and space]`";
            }
          } else {
            warningText = "Sequencer function name should not start with special characters";
          }
        } else {
          warningText = "Sequencer function name already exists";
        }
      } else {
        warningText = 'Sequencer function name should not be empty';
      }

      // Fetching all input fields of a block
      const allInputfieldsInBlocks = block?.inputList.flatMap((inputListObj: any) => Array.isArray(inputListObj.fieldRow) ? inputListObj.fieldRow : [inputListObj.fieldRow]);
      const textfields = allInputfieldsInBlocks.filter((inputObj: any) => inputObj instanceof Blockly.FieldTextInput);
      const blockInputFields = textfields.filter((fieldObj: any) => fieldObj.name && fieldObj.name !== 'seqFunctionName' && !fieldObj.value_);
      // adding warning icon if block's input field is empty.
      if(blockInputFields.length > 0) {
        warningText = 'Input field cannot be empty';
      } else {
        if(!warningText && descWhat && (descWhat.length > descriptionLength)) {
          warningText = `What(description) field should not exceed ${descriptionLength} characters`;
        }
        if(!warningText && descWhy && (descWhy.length > descriptionLength)) {
          warningText = `Why(description) field should not exceed ${descriptionLength} characters`;
        }
      }
      
      block.setWarningText(warningText);
      this.toggleBlockErrorState(warningText, block);
    }
  }

  /**
   * Validates the input field of a block.
   * If the block is not a sequence or sequencer block, it checks for empty input fields and adds a warning icon.
   * If the block is not inside any sequence block, it adds a warning that the block should be inside a sequence or sequencer block.
   * Additionally, it validates date fields if all the input fields of the block are filled.
   * 
   * @param block - The block to validate.
   */
  validateInputField(block: any) {
    const rootBlockType = this.builderMode === BuilderMode.SEQUENCE_BUILDER ? this.sequenceBlockType : BlockType.SEQUENCER;
    const rootBlockLabel = this.builderMode === BuilderMode.SEQUENCE_BUILDER ? 'sequence' : 'sequencer';
    let excludeBlocks: string[] = [];
    excludeBlocks = ['haystack_query_with_condition_pre_defined_current_value','haystack_query_with_condition_pre_defined_device','haystack_query_with_condition_pre_defined_equip','haystack_query_with_condition_pre_defined'];
    
    // Skipping the validation for loose blocks (blocks which are not inside any sequence blocks) in device category Sequence builder.
    const skipLooseBlocksValidation = this.builderMode === BuilderMode.SEQUENCE_BUILDER && this.categoryMenu.selectedCategory.id === Category.DEVICE;

    if(block.type !== rootBlockType) {   // Skipping the validation for sequence and sequencer blocks.
      let warningText = '';
      const rootBlock = block.getRootBlock();
      // Fetching all input fields of a block
      const allInputfieldsInBlocks = block?.inputList.flatMap((inputListObj: any) => Array.isArray(inputListObj.fieldRow) ? inputListObj.fieldRow : [inputListObj.fieldRow]);
      const textfields = allInputfieldsInBlocks.filter((inputObj: any) => inputObj instanceof Blockly.FieldTextInput);
      // Filtering the empty input fields
      let blockInputFields: any[] = [];
      blockInputFields = textfields.filter((fieldObj: any) => fieldObj.name && !fieldObj.value_ && !fieldObj.name.includes('CustomInput_'));
      // Condition (block.id === rootBlock.id) checks if the current block itself is a root/parent block (and the block is not a child of any sequence block).
      
      // if category is the device then skip the checking if the block is inside the sequence or not. 
      if((!skipLooseBlocksValidation) && ((block.id === rootBlock.id) || (rootBlock.type !== rootBlockType))) {
        const surroundBlock = block.getSurroundParent();
        warningText = surroundBlock ? '' : `Block should be inside a ${rootBlockLabel} block`;   // Setting warning text if the block is not inside any sequence block.
      } else {
        // Setting warning text if block's input field is empty.
        if(blockInputFields.length > 0 && excludeBlocks.indexOf(block.type) < 0) {
          warningText = 'Input field cannot be empty';
        }
      }
      // Adding warning icons with warning text for error blocks.  
      block.setWarningText(warningText);
      this.toggleBlockErrorState(warningText, block);
      
      // validating date fields after only if all the input fields of the block are filled. 
      if(!warningText) {
        this.validateDateInputFields(block);
      }

    }
  }

  /**
   * Validates the date input fields of a block.
   * @param block - The block to validate.
   */
  validateDateInputFields(block: any) {
    const blocksWithMultiDates = ['getHistorizedValueFromDateRange' ,'gethistorizedDateTimeVariant', 'getAggregatedValuesFromDateRange', 'getAggregatedValuesFromDateTimeVariant'];
    let warningText = '';
    if(blocksWithMultiDates.indexOf(block.type) >= 0) {
      const fieldsWithDate = ['start_date', 'end_date'];
      
      // Fetching all input fields of a block
      const allInputfieldsInBlocks = block?.inputList.flatMap((inputListObj: any) => Array.isArray(inputListObj.fieldRow) ? inputListObj.fieldRow : [inputListObj.fieldRow]);
      const textfields = allInputfieldsInBlocks.filter((inputObj: any) => inputObj instanceof Blockly.FieldTextInput);
      
      // Checking if each datefield in the block has a valid date. 
      textfields.forEach((fieldObj: any) => {
        if(fieldsWithDate.indexOf(fieldObj.name) >= 0) {
          const regex = /^\d{4}-\d{2}-\d{2}$/;
          if (!(regex.test(fieldObj.value_))) {
            warningText = 'Invalid date. Please enter the date in YYYY-MM-DD format';
          }
        } 
      });

      // Checking if block contains multiple date fields and comparing them.
      const multiDateFields = textfields.filter((fieldObj: any) => fieldObj.name && fieldsWithDate.indexOf(fieldObj.name) >=0);
      if(!warningText && multiDateFields.length > 1) {
        const fromDate = new Date(block.getFieldValue('start_date'));
        const toDate = new Date(block.getFieldValue('end_date'));
        
        if(fromDate <= toDate) {
          warningText = '';
        } else if(fromDate > toDate) {
          warningText =  'From Date should be less than To Date';
        } 
        else {
          warningText = 'To Date should be greated than From Date';
        }
      }

      
       // Additional validation for time format
       if (['getAggregatedValuesFromDateTimeVariant','gethistorizedDateTimeVariant'].includes(block.type)) {
        const fieldsWithTime = ['start_time', 'end_time'];
        const timeRegex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

          // Fetching all input fields of a block
        const allInputfieldsInBlocks = block?.inputList.flatMap((inputListObj: any) => Array.isArray(inputListObj.fieldRow) ? inputListObj.fieldRow : [inputListObj.fieldRow]);
        const textfields = allInputfieldsInBlocks.filter((inputObj: any) => inputObj instanceof Blockly.FieldTextInput);
        
         // Checking if each datefield in the block has a valid date. 
         textfields.forEach((fieldObj: any) => {
           if (fieldsWithTime.indexOf(fieldObj.name) >= 0) {
             if (!(timeRegex.test(fieldObj.value_))) {
               warningText = 'Invalid time format. Please enter time in HH:MM format (24-hour clock)';
             }
           }
         });
      
    }
       
    }
    block.setWarningText(warningText);
    this.toggleBlockErrorState(warningText, block);
  }

  /**
   * Toggles the error state of a block and updates the errorBlocks array accordingly.
   * @param {any} warningText - The warning text associated with the error state.
   * @param {any} block - The block object to toggle the error state for.
   */
  toggleBlockErrorState(warningText: any, block: any) {
    const targetIndex = this.errorBlocks.indexOf(block.id);
    if(warningText) {
      this.errorBlocks.push(block.id); 
      block.setHighlighted(true);
      block.select();
    } else {
      if(block.isInErrorState) {
        this.errorBlocks.push(block.id);
      } else {
        targetIndex >= 0 ? this.errorBlocks.splice(targetIndex, 1) : '';
        block.setHighlighted(false);
        block.unselect();
      }
    }
  }

  navigateBackToSiteSequences() {
    const targetBuilderMode = BuilderMode.SEQUENCE_BUILDER;
    const skipValidationCheck = true;
    this.toggleBuilderMode(targetBuilderMode, skipValidationCheck);
  }

  navigateToLibraryBuilder() {
    this.resetLibraryBuilder();
    this.isBuildingNewSequenceFunction = true;
    this.toggleBuilderMode(BuilderMode.LIBRARY_BUILDER);
  }

  showConfirmationDialog(eventType: any, selectedSeqFunction?: any) {
    this.siteSequencerService.getSequenceFunctionsUsage(selectedSeqFunction.functionId, this.SequenceFunctionsComponent)
    .subscribe((res: any) => {
      if(res) {
        const sequenceFunctionUsage = res;
        sequenceFunctionUsage['eventType'] = eventType;
        const selectedSequenceFunction = selectedSeqFunction.seqFunctionName;
        const dialogRef = this.dialog.open(EditAndDeleteConfirmationModalComponent, {
          panelClass: 'fs-mat-dialog-container',
          minWidth: '480px',
          disableClose: true
        });
        const title = `Are you sure you want to ${eventType} ${selectedSequenceFunction}?`;
        const subTitle = 'this action cannot be undone';
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.subTitle = subTitle;
        dialogRef.componentInstance.sequenceFunction = sequenceFunctionUsage;
        dialogRef.componentInstance.type = 'confirm';
        dialogRef.componentInstance.confirmBtnText = 'continue';
        dialogRef.componentInstance.showConfirmIcon = true;
        dialogRef.componentInstance.isCanvasAction = false;
        dialogRef.updatePosition({ top: '60px' });
        dialogRef.afterClosed().subscribe(result => {
          // On edit or delete confirmation
          if(result && result == 'confirm') {
            if(eventType === 'edit') {
              this.canvasTabToSwitch = this.canvasTabs.selectedCanvasTab;
              this.handleViewSequence(selectedSeqFunction);
            } 
            else if (eventType === 'delete') {
              this.deleteSequenceFunction();
            }
          }
        });
      }
    }, (err: any) => {
      this.showToast('error', `${err.message}`);
    });

  }
  
  saveSequenceFunction() {
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    const functionBlocksInCanvas = allBlocks.filter((blockObj: any) => blockObj.type === 'sequencer');
    if(functionBlocksInCanvas.length > 0 
        && functionBlocksInCanvas[0].id === this.activeSequencerBlockRef && this.sequencerBlock) {
      this.isLoading = true;
      const targetBlock = this.blocklyWorkSpace.getBlockById(this.activeSequencerBlockRef);
      const isSequencesValid = this.checkAndReturnErrorStatus();
      if(isSequencesValid) {
        this.sequencerBlock = {
          "seqFunctionName": targetBlock.getFieldValue('seqFunctionName'),
          "why": targetBlock.getFieldValue('why'),
          "what": targetBlock.getFieldValue('what'),
          "snippet": this.getJSCodeForSequence(targetBlock, ''),
          "xml": this.sequencerBlock.xml = this.parseXMLForSequence(targetBlock),
          "component": this.SequenceFunctionsComponent
        }
        
        this.siteSequencerService.saveSequenceFunction(this.sequencerBlock)
        .subscribe((res) => {
          if(res && res.functionId) {
            // Adding the obtained functionId to the functionId field of sequencer block
            this.sequencerBlock['functionId'] = res['functionId'];
            targetBlock.setFieldValue(this.sequencerBlock['functionId'], 'functionId');
            targetBlock.getField('functionId').setVisible(false);
            this.updateSequencerBlockConfig(targetBlock);
            
            this.saveAndUpdateSequenceFunction();
          } else {
            this.isLoading = false;
          }
        }, (err: any) => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
      this.showToast('error', 'Add a sequencer block to canvas to create a sequencer function');
    }
  }

  seqFunctionActionFromFlyout(eventType: any, blockObj?: any) {
    const seqFunctionBlock = blockObj;
    if(eventType === 'edit') {
      this.isFunctionBlockClicked = true;
      this.showConfirmationDialog('edit', seqFunctionBlock);
    } else if(eventType === 'delete') {
      this.sequencerBlock = {};
      this.showConfirmationDialog('delete', seqFunctionBlock);
    } else if(eventType === 'view') {
      this.isFunctionBlockClicked = true;
      this.handleViewSequence(seqFunctionBlock); 
    }
  }

  deleteSequenceFunction() {
    this.isLoading = true;
    const selectedSeqFunction = this.selectedFunctionToView;
    this.isDeletingSequenceFunction = true;
    this.siteSequencerService.deleteSequenceFunction(selectedSeqFunction.functionId, this.SequenceFunctionsComponent)
    .subscribe((res) => {
      this.showToast('success', `sequence function ${selectedSeqFunction.seqFunctionName} deleted succesfully`);
      this.toggleBuilderMode(BuilderMode.SEQUENCE_BUILDER);
    }, err => {
      this.isLoading = false;
      this.isDeletingSequenceFunction = false;
      this.showToast('error', `Something went wrong in deleting sequence function ${selectedSeqFunction.seqFunctionName} , please click delete button again.`);
    })
    
  }

  duplicateSequenceFunction() {
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    if(allBlocks.length <= 0) {
      this.showToast('error', 'Add a sequencer block to canvas to create a sequencer function');
      return;
    }
    this.isLoading = true;
    this.isDuplicatingSequenceFunction = true;
    const isSequencesValid = this.checkAndReturnErrorStatus();
    if(isSequencesValid) {
      const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
      const sequencerBlock = allBlocks.find((blockObj: any) => blockObj.type === BlockType.SEQUENCER && 
        blockObj.getFieldValue('functionId') === this.sequencerBlock.functionId);
      const targetBlockInWorkspace = this.blocklyWorkSpace.getBlockById(sequencerBlock.id);
      
      if(targetBlockInWorkspace) {
        const functionName = this.getUniqueValueWithCopySuffix(sequencerBlock.getFieldValue('seqFunctionName'), 'seqFunctionName', this.sequencerFunctionCollection);
        targetBlockInWorkspace.setFieldValue(functionName, 'seqFunctionName');
        this.defaultTabLabel = functionName;
        targetBlockInWorkspace.setFieldValue('', 'functionId');
        this.updateSequencerBlockConfig(targetBlockInWorkspace);
        // Passing Component in the payload to save the sequence function.
        const payload = JSON.parse(JSON.stringify(this.sequencerBlock));
        payload['component'] = this.SequenceFunctionsComponent;
        this.siteSequencerService.saveSequenceFunction(payload)
        .subscribe((res) => {
          if(res && res.functionId) {
            targetBlockInWorkspace.setFieldValue(res.functionId, 'functionId');
            this.updateAlertBlockInSeqFunction(targetBlockInWorkspace, res.functionId); // Adding data property Updating the alert block IDs of the duplicated sequence with the obtained functionId followed by underscore (_) and unique number.
            this.updateSequencerBlockConfig(targetBlockInWorkspace);
            this.saveAndUpdateSequenceFunction(true);
            
            if (this.isWorkspaceReadOnly) {
              this.showHideToolBox(false); //Making toolbox hidden in readOnly Mode.
              this.showToolBoxToggleButton = false; //Hiding show toolbox button hidden in readOnly Mode.
              this.editMode = false;
            } else {
              this.showHideToolBox(true); //Making toolbox hidden in readOnly Mode.
              this.showToolBoxToggleButton = true; //Hiding show toolbox button hidden in readOnly Mode.
              this.editMode = true;
            }

            this.showToast('success', `Sequence function ${this.sequencerBlock.seqFunctionName} created successfully.`);
            this.isLoading = false;

          } else {
            this.isLoading = false;
            this.isDuplicatingSequenceFunction = false;
            this.showToast('error', `Something went wrong in creating ${this.sequencerBlock.seqFunctionName} as a new sequence, please click the 'Create as new sequence button' again.`);
          }
        }, err => {
          this.isLoading = false;
          this.isDuplicatingSequenceFunction = false;
          this.showToast('error', `Something went wrong in creating ${this.sequencerBlock.seqFunctionName} as a new sequence, please click the 'Create as new sequence button' again.`);
        });
        
      } else {
        this.isLoading = false;
      }
    }
  }

  /**
   * Update the alert block Ids with functionId when duplicating a sequence function.
   * 
   * @param targetBlock - sequencer block (sequence function block) object to retrieve the alert blocks.
   * @param seqFunctionId - sequence function id to create unique id for the alert block and save it in data property.
   */
  updateAlertBlockInSeqFunction(targetBlock: any, seqFunctionId: any) {
    const allBlocksOfSequence = targetBlock.getDescendants('ordered');
    // Retrieving the alert blocks (blocks with type alertWithRef) from the child blocks of the sequence block.
    const alertBlocks = allBlocksOfSequence ? 
      allBlocksOfSequence.filter((blockObj: any) => blockObj.type.includes('alertWithRef')) : [];
    if(alertBlocks.length) {
      const uniqueAlertIds: any[] = [];
      alertBlocks.forEach((alertBlock: any) => {
        // Generate a unique ID for each alert block by appending an underscore (_) and incrementing the number by 1 to the functionId (seqFunctionId).
        let updatedAlertId = `${seqFunctionId}_${uniqueAlertIds.length + 1}`;
        while (uniqueAlertIds.includes(updatedAlertId)) {
          updatedAlertId = `${seqFunctionId}_${uniqueAlertIds.length + 1}`;
        }
        uniqueAlertIds.push(updatedAlertId);
        // Saving the updated alertId in the data property of the block.
        // (Block.data property - Optional text data that round-trips between blocks and XML. Has no effect. May be used by 3rd parties for meta information.)       
        this.blocklyWorkSpace.getBlockById(alertBlock.id).data = updatedAlertId;
      });
    }
  }

  createUniqueValueForKey(valueToChange: any, targetKey: any, collection: any, seperator?: string) {
    // Check if the passed value string already exists in the provided collection.
    let existingValue = collection.find((item: any) => item[targetKey] === valueToChange);
    // If the value is unique, return the value as is.
    if(!existingValue) {
      return valueToChange;
    }
    
    let number = 1;
    let formattedValue = seperator ? `${valueToChange}${seperator}${number}` : `${valueToChange}_${number}`

    // Check if the formatted string already exists in the provided collection.
    let existingItem = collection.find((item: any) => item[targetKey] === formattedValue);

    // If the formatted string already exists, append a number and increment until it's unique.
    while (existingItem) {
        number++;
        formattedValue = seperator ? `${valueToChange}${seperator}${number}` : `${valueToChange}_${number}`
        existingItem = collection.find((item: any) => item[targetKey] === formattedValue);
    }
    return formattedValue;
  }

  getUniqueValueWithCopySuffix(valueToChange: any, targetKey: any, collection: any) {
    let number = 1;
    let formattedValue: any;
    const regex = /_copy(\d+)$/;
    const match = valueToChange.match(regex);
    if (match) {
      number = parseInt(match[1]) + 1;
      formattedValue = `${valueToChange.replace(regex, '')}_copy${number}`;
    } else {
      formattedValue = `${valueToChange}_copy1`;
    }
    let existingItem = collection.find((item: any) => item[targetKey] === formattedValue);
    while (existingItem) {
        number++;
        formattedValue = `${valueToChange.replace(regex, '')}_copy${number}`;
        existingItem = collection.find((item: any) => item[targetKey] === formattedValue);
    }
    return formattedValue;
  }

  saveAndUpdateSequenceFunction(skipToggleBuilderMode?: boolean) {
    this.isLoading = true;
    const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
    if(allBlocks.length > 0) {
      const isSequencesValid = this.checkAndReturnErrorStatus();
      if(isSequencesValid) {
        const sequencerBlock = allBlocks.find((blockObj: any) => blockObj.type === BlockType.SEQUENCER && 
          blockObj.getFieldValue('functionId') === this.sequencerBlock.functionId);
        if(sequencerBlock) {
          this.updateSequencerBlockConfig(sequencerBlock);
          // Passing Component in the payload to save the sequence function.
          const payload = JSON.parse(JSON.stringify(this.sequencerBlock));
          payload['component'] = this.SequenceFunctionsComponent;
          this.siteSequencerService.updateSequenceFunction(this.sequencerBlock.functionId, payload)
          .subscribe((res: any) => {
            if(res) {
              if(!skipToggleBuilderMode) {
                this.showToast('success', `sequence function ${this.sequencerBlock.seqFunctionName} saved succesfully`);
                this.toggleBuilderMode(BuilderMode.SEQUENCE_BUILDER);
              }
            } else {
                this.isLoading = false;
                this.showToast('error', `Something went wrong in saving the sequence function, please try again.`);
            }
          }, (err) => {
              this.isLoading = false;
            this.showToast('error', `err`);
          });
        } else {
          this.isLoading = false;
          this.showToast('error', 'Add a sequencer block to canvas to create a sequencer function');
        }
      }
    } else {
      this.isLoading = false;
      this.showToast('error', 'Add a sequencer block to canvas to create a sequencer function');
    }
  }

  updateSequencerBlockConfig(block: any) {
    this.sequencerBlock.seqFunctionName = block.getFieldValue('seqFunctionName');
    this.sequencerBlock.functionId = block.getFieldValue('functionId');
    this.sequencerBlock.why = block.getFieldValue('why');
    this.sequencerBlock.what = block.getFieldValue('what');
    this.sequencerBlock.xml = this.parseXMLForSequence(block);
    this.sequencerBlock.snippet = this.getJSCodeForSequence(block, '');
    this.activeSequencerBlockRef = block.id;

    this.selectedFunctionToView = JSON.parse(JSON.stringify(this.sequencerBlock));
    this.selectedFunctionToView.category = this.categories.CREATED_BY_ME;
  }

  /**
   * Saves all sequences in the site sequencer component.
   * 
   * @param isDeletingWorkspace - Optional parameter to indicate if the workspace is being deleted.
   * @param isDryRunSelected - Optional parameter to indicate if dry run is selected.
   */
  saveAllSequences(isDeletingWorkspace?: boolean, isDryRunSelected?: boolean) {
    this.isLoading = true;
    const allBlocks = this.blocklyWorkSpace?.getAllBlocks();
    const sequenceBlocks = allBlocks.filter((blockObj: any) => blockObj.type === this.sequenceBlockType);
    const payload: any[] = [];
    let quartzCronRequest: any;
    if(this.sequences && this.sequences?.length > 0 && sequenceBlocks.length > 0) {
      this.sequences.forEach((seqObj: any) => {
        let targetBlock: any = null;
        quartzCronRequest = this.getQuartzCronRequestValue(seqObj);
        if(this.categoryMenu.selectedCategory.id !== Category.DEVICE) {
          const targetBlockArray = sequenceBlocks.filter((blockObj: any) => blockObj.getFieldValue('sequenceId') == seqObj.seqId);
          targetBlock = targetBlockArray[0];
          const isEnabled = (targetBlock.getFieldValue('RUN_STATUS') == SequenceStatus.ENABLE || targetBlock.getFieldValue('RUN_STATUS') == SequenceStatus.ENABLE_WITH_LOGS) ? true : false;
          seqObj['isEnabled'] = isEnabled;
          seqObj['frequency'] = targetBlock.getFieldValue('Occurance');
          seqObj['blocklyXml'] = this.parseXMLForSequence(targetBlock);
          seqObj['snippet'] = this.getJSCodeForSequence(targetBlock,seqObj['seqName']);
          seqObj['alertBlocks'] = this.getAlertBlocksIdsOfSequence(targetBlock);
          seqObj['functionIds'] = this.getFunctionIdsOfSequence(targetBlock);
        }
        const formattedObj: any = {
          'seqId': seqObj['seqId'],
          "workspaceId": this.canvasTabs.selectedCanvasTab?.id,
          'siteRef': this.selectedSiteId,
          'seqName': this.getSequenceName(seqObj['seqName']),
          'blocklyXml': this.parseXMLForSequence(targetBlock),
          'snippet': this.getJSCodeForSequence(targetBlock,seqObj['seqName']) || '',
          "quartzCronRequest": quartzCronRequest,
          'enabled': seqObj['isEnabled'],
          'functionIds': this.getFunctionIdsOfSequence(targetBlock)
        };
        (seqObj['alertBlocks']?.length) ? formattedObj['seqAlerts'] = seqObj['alertBlocks'] : '';
        if(this.categoryMenu.selectedCategory.id === Category.SYSTEM) {
          const sequenceScope = targetBlock.getFieldValue('selectedScopeValue');
          if(sequenceScope && sequenceScope.length > 0) {
            const result = sequenceScope.split(',');
            formattedObj['scope'] = result;
            seqObj['scope'] = result;
          } else {
            formattedObj['scope'] = [null];
            seqObj['scope'] = [null];
          }
        }
        payload.push(formattedObj);      
      });
      this.updateAllSequences(payload).subscribe((res) => {
        this.noChangesInWorkspace = true;
        if(!isDeletingWorkspace) {
          // Saving Workspace XML
          const isSavingAllSequences = true;
          this.saveWorkspaceXML(isSavingAllSequences);
          if(isDryRunSelected) {
            this.triggerSequenceDryRun();
          }
        } else {
          this.showToast('success', `Workspace saved successfully`);
          this.isLoading = false;
        }
      }, (err: any) => {
        this.isLoading = false;
        this.showToast('error', `${err.error && err.error.error ? err.error.error : err.message }`);
        console.log(err.msg || err.message || 'something went wrong');
        this.noChangesInWorkspace = false;
      });
    } else {
      if(!this.errorBlocks.length) {
        this.updateAllSequences(payload).subscribe((res) => {
          this.noChangesInWorkspace = true;
          if(!isDeletingWorkspace) {
            // Saving Workspace XML
            this.saveWorkspaceXML();
            if(isDryRunSelected) {
              this.triggerSequenceDryRun();
            }
          }
        });
      }
      this.isLoading = false;
    }
  }

  getSequenceName(sequenceName:string) {
    if(this.categoryMenu.selectedCategory.id === Category.DEVICE) {
      return this.canvasTabs.selectedCanvasTab?.name
    }
    return sequenceName;
  }

  /**
   * Retrieves the alert block IDs of a sequence.
   * 
   * @param targetBlock - The target block to retrieve the alert block IDs from.
   * @returns An array of alert objects containing the alert block ID, type, title, message, notification message, severity, and enabled status.
   */
  getAlertBlocksIdsOfSequence(targetBlock: any) {
    const childBlocksOfSequence = targetBlock.getDescendants('ordered');
    const seqAlerts: any = [];
    const alertBlocksInSequence = childBlocksOfSequence ? 
      childBlocksOfSequence.filter((blockObj: any) => blockObj.type === 'alert' || blockObj.type === 'alertWithRef' ) : '';
    if(alertBlocksInSequence.length) {
      alertBlocksInSequence.forEach((alertBlockObj: any) => {
        const alertObj = this.getAlertDef(alertBlockObj);
        seqAlerts.push(alertObj);
      })
    }

    // Extracting the function blocks (blocks with type fn_) from the child blocks of the sequence block
    const functionBlocks = childBlocksOfSequence ?
      childBlocksOfSequence.filter((blockObj: any) => blockObj.type.includes('fn_')) : '';
    
    const formattedFunctionBlocksArray: any[] = [];
    if(functionBlocks.length) {
      functionBlocks.forEach((functionBlockObj: any) => {
          const functionBlockId = functionBlockObj.getFieldValue('functionId'); // Extracting the functionIds from the function block
          const functionAlreadyExist = formattedFunctionBlocksArray.find((functionObj: any) => functionObj.getFieldValue('functionId') === functionBlockId);
          if(!functionAlreadyExist) {
            formattedFunctionBlocksArray.push(functionBlockObj);
          }
      });

      if(formattedFunctionBlocksArray.length) {
        // Looping the function blocks to get the functionIds and alertIds
        formattedFunctionBlocksArray.forEach((functionBlockObj: any) => {
          let functionXml = functionBlockObj.getFieldValue('xml');
          const parser = new DOMParser();
          const xmlDom = parser.parseFromString(functionXml, 'text/xml');
          const blockElements = xmlDom.getElementsByTagName('block'); // Extracting the blocks from the xml
          // Looping through the blockElements of type HTMLCollectionOf<Element> to get the alert block ids
          Array.from(blockElements).forEach((block) => {
            const blockType = block.getAttribute('type');
            if (blockType === 'alertWithRef' || blockType === 'alertWithOffset' || blockType === 'alertHystersis') {
              let blockId = block.getAttribute('id');
              // Get the fields within the block (if any)
              const fields = Array.from(block.children).filter((child: any) => child.tagName === 'field');
              const dataField = Array.from(block.children).find((child: any) => child.tagName === 'data');
              blockId = dataField ? dataField.textContent : block.getAttribute('id'); // Extracting the alert block ID from the data field or the block ID itself of the block.
              const alertAlreadyExist = seqAlerts.find((alertObj: any) => alertObj.alertBlockId === blockId);
              if(!alertAlreadyExist) {
                let alertObj:any = {
                  "alertBlockId": dataField ? dataField.textContent : blockId,
                  "alertType": "",
                  "title": "",
                  "message": "message",
                  "notificationMessage":"notificationMessage",
                  "severity": "",
                  "enabled": true
                };
                for (let j = 0; j < fields.length; j++) {
                  const fieldName = fields[j].getAttribute('name');
                  if(fieldName === 'ALERT_TYPE') {
                    alertObj['alertType'] = fields[j].textContent;
                  } else if(fieldName === 'ALERT_TITLE') {
                    alertObj['title'] = fields[j].textContent;
                  } else if(fieldName === 'ALERT_MESSAGE') {
                    alertObj['message'] = "message";
                  } else if(fieldName === 'ALERT_NOTIFICATION_MESSAGE') {
                    alertObj['notificationMessage'] = "notificationMessage";
                  } else if(fieldName === 'ALERT_SEVERITY') {
                    alertObj['severity'] = fields[j].textContent;
                  }
                  
                }
                seqAlerts.push(alertObj);
              }
            }
          });
  
        });
      }

    }



    return seqAlerts;
  }


  getAlertDef(alertBlockObj: any) {
    if (alertBlockObj.type === 'alert') {
      return {
        "alertBlockId": alertBlockObj.id,
        "alertType": alertBlockObj.getFieldValue('ALERT_TYPE'),
        "title": alertBlockObj.getFieldValue('ALERT_TITLE'),
        "message": alertBlockObj.getFieldValue('ALERT_MESSAGE'),
        "notificationMessage": alertBlockObj.getFieldValue('ALERT_NOTIFICATION_MESSAGE'),
        "severity": alertBlockObj.getFieldValue('ALERT_SEVERITY'),
        "enabled": true
      };
    } else {

      return {
        "alertBlockId": alertBlockObj.id,
        "alertType": alertBlockObj.getFieldValue('ALERT_TYPE'),
        "title": alertBlockObj.getFieldValue('ALERT_TITLE'),
        "message": javascriptGenerator.valueToCode(alertBlockObj,'ALERT_MESSAGE', javascriptGenerator.ORDER_MEMBER),
        "notificationMessage":javascriptGenerator.valueToCode(alertBlockObj,'ALERT_NOTIFICATION_MESSAGE', javascriptGenerator.ORDER_MEMBER),
        "severity": alertBlockObj.getFieldValue('ALERT_SEVERITY'),
        "enabled": true
      };
    }
  }

  /**
   * Retrieves the functionId from the function blocks of a sequence.
   * @param {any} rootBlock optional - Optional parameter indicates the root block which contains the function blocks as child blocks.
   * @returns An array of function IDs.
   */
  getFunctionIdsOfSequence(rootBlock?: any) {
    const isFunctionsInsideRootBlocks = rootBlock ? true : false;
    const functionIds: any = [];
    let functionBlocks = [];
    const allBlocksOfSequence = isFunctionsInsideRootBlocks ? rootBlock.getDescendants('ordered') : this.blocklyWorkSpace.getAllBlocks(true);
    // Extracting the function blocks (blocks with type fn_) from the child blocks of the sequence block.
    functionBlocks = allBlocksOfSequence ? 
      allBlocksOfSequence.filter((blockObj: any) => blockObj.type.includes('fn_')) : [];
    if(functionBlocks.length) {
      // Looping the function blocks to get the functionIds and alertIds
      functionBlocks.forEach((functionBlockObj: any) => {
        const functionBlockId = functionBlockObj.getFieldValue('functionId'); // Extracting the functionIds from the function block
        const functionAlreadyExist = functionIds.find((functionId: any) => functionId === functionBlockId);
        if(!functionAlreadyExist) {
          functionIds.push(functionBlockId);
        }
      })
    }
    return functionIds;
  }

  /**
   * Parses the given block into an XML string representation.
   * 
   * @param block - The block to parse.
   * @returns The XML string representation of the block.
  */
  parseXMLForSequence(block?: any, isWorkspaceXmlRequired?: boolean) {
    const isDeviceCategorySelected = this.categoryMenu.selectedCategory.id === Category.DEVICE && 
      this.builderMode === BuilderMode.SEQUENCE_BUILDER;

    // Use workspaceToDom for the entire workspace XML when the selected category is "device" or no block is provided.
    // Otherwise, use blockToDom to get the XML for a specific block.
    const xmlDom = isDeviceCategorySelected || !block ? 
      Blockly.Xml.workspaceToDom(this.blocklyWorkSpace) : 
      Blockly.Xml.blockToDom(block);

    // Generate formatted XML using domToPrettyText for "device" category or a specific block.
    // Use serializeToString for raw XML in other cases.
    const xmlText = block ? 
      Blockly.Xml.domToPrettyText(xmlDom) : 
      new XMLSerializer().serializeToString(xmlDom);

    return xmlText;
  }

  /**
   * Generates JavaScript code for a sequence.
   * @param block The block to generate code from.
   * @param name The name of the sequence.
   * @returns The generated JavaScript code.
   */
   getJSCodeForSequence(block: any, name: any){
    let code = (this.categoryMenu.selectedCategory.id !== Category.DEVICE) ? javascriptGenerator.workspaceToCode(this.blocklyWorkSpace) : 
      pythonGenerator.workspaceToCode(this.blocklyWorkSpace);
    // Formatting the code if any function blocks are added to the canvas.
    if(this.builderMode === BuilderMode.SEQUENCE_BUILDER) {
      code = this.formatCodeForFunctionBlock(code);
    }
    if(name) {
      name = name.replaceAll(' ','');
      code+= `sequence_${name}()`
    }
    return code;
  }

  /**
   * Retrieves the block into view based on the provided breadcrumb and sequence block flag.
   * @param breadcrumb - The breadcrumb object containing the sequence ID and block ID.
   * @param isSequenceBlock - A boolean flag indicating whether the block is a sequence block.
   */
  getBlockIntoView(breadcrumb: any, isSequenceBlock: boolean) {
    if (breadcrumb) {
      const allBlocks = this.blocklyWorkSpace.getAllBlocks(true);
      let targetSequenceBlocks = [];
      targetSequenceBlocks = allBlocks.filter((blockObj: any) => {
        return isSequenceBlock ? (blockObj.type == this.sequenceBlockType && blockObj.getFieldValue('sequenceId') === breadcrumb.seqId) :
         blockObj.id === breadcrumb.id;
      });
      if (targetSequenceBlocks.length > 0) {
        const block = targetSequenceBlocks[0];
        block.select();
        if(isSequenceBlock) {
          const metrics = this.blocklyWorkSpace.getMetrics(); // Getting the metrics of the workspace
          const blockXYCords = block.getRelativeToSurfaceXY();  // Scroll the workspace so that the block's top left corner
          this.blocklyWorkSpace.scrollbar.set(blockXYCords.x * this.blocklyWorkSpace.scale - metrics.contentLeft + (metrics.viewWidth * 0.25), blockXYCords.y * this.blocklyWorkSpace.scale - metrics.contentTop + (metrics.viewHeight * 0.2));
        } else {
          this.blocklyWorkSpace.centerOnBlock(block.id);
        }
      }
    }
  }

  /**
   * Toggles the builder mode of the site sequencer component.
   * @param selectedbuilderMode The selected builder mode.
   */
  toggleBuilderMode(selectedbuilderMode: any, skipValidationCheck?: boolean) {
    this.previousMode = this.builderMode;
    const isSequencesValid = skipValidationCheck ? true : this.checkAndReturnErrorStatus();
    // Switching the builder only if all the blocks are valid.
    if(isSequencesValid) {
      this.isLoading = true;
      // Restricting the cross tab copy functionality.
      const blocklyStash = localStorage.getItem('blocklyStash');
      // Saving the copy of blocklyStash in copy_blocklyStash when switching from sequence builder to library builder.
      // (to avoid the loss of blocklyStash in localstorage, when switching back to sequence builder as blocklyStash will be cleared when re-initializing the workspace)
      if (blocklyStash !== null) {
        localStorage.setItem('copy_blocklyStash', blocklyStash);
      }
      if(this.previousMode === BuilderMode.SEQUENCE_BUILDER && !this.noChangesInWorkspace) {  // If switching from sequence builder
        this.saveWorkspaceXML();
        this.saveAllSequences(true);
      } else if(this.previousMode === BuilderMode.LIBRARY_BUILDER) { // If switching from Library builder
        this.resetLibraryBuilder();
      }
      this.toggleWorkspace('delete'); // Deleting the existing workspace
      setTimeout(() => {
        this.builderMode = selectedbuilderMode; // Setting the builder mode
        if(this.previousMode === BuilderMode.SEQUENCE_BUILDER) {
          this.initializeBlockly();
          this.toggleWorkspace('create'); // Re-initializing the workspace
        } else if(this.previousMode === BuilderMode.LIBRARY_BUILDER) {
          this.previousMode = BuilderMode.SEQUENCE_BUILDER;
          const selectedCategoryTabConfig = {'index': this.categoryMenu.activeTabIndex, tab: {'textLabel': this.categoryMenu.selectedCategory.label}};
          this.toggleCategoryLevelTab(selectedCategoryTabConfig, true);
        }
        // When switcing from library builder to sequence builder, saving the copy_blocklyStash to blocklyStash.
        const copy_blocklyStash = localStorage.getItem('copy_blocklyStash');
        if(copy_blocklyStash) {
          localStorage.setItem('blocklyStash', copy_blocklyStash);
          localStorage.removeItem('copy_blocklyStash');
        }
      }, 500);
    } else {
      this.sequencerBlock = null;
      this.selectedFunctionToView = null;
    }

  }

  handleSeqFunctionDoubleClick(event: Blockly.Events.Click) {
    if (event.type == 'click' && event.blockId) {
      let targetBlock = this.blocklyWorkSpace.getBlockById(event.blockId);
      this.isFunctionBlockClicked = targetBlock && targetBlock.type.includes('fn_') ? true : false;
      const block = this.blocklyWorkSpace.getBlockById(event.blockId);
      if (block.isInFlyout || !block.isMovable())
        return;
        const now = Date.now();
      if (now - (block["_lastClickTime"] ?? 0) < 300) {
        const isFunctionBlock = block.type.includes('fn_');
        if(isFunctionBlock) {
          const targetCategory = targetBlock['category'] = this.sequencerFunctionCollection.find((x) => x.type == targetBlock.type).category;
          targetBlock = {
            "seqFunctionName": targetBlock.getFieldValue('seqFunctionName'),
            "functionId": targetBlock.getFieldValue('functionId'),
            "why": targetBlock.getFieldValue('why'),
            "what": targetBlock.getFieldValue('what'),
            "xml": targetBlock.getFieldValue('xml'),
            "snippet": targetBlock.getFieldValue('snippet'),
            "category": targetCategory
          }
          this.handleViewSequence(targetBlock, true);
        }
      }
      block["_lastClickTime"] = now;
    }
  }

  handleViewSequence(targetBlock: any, isDoubleClickEventTriggered?: boolean) {
    if (this.isFunctionBlockClicked) {
      this.selectedFunctionToView = targetBlock;      
      if(isDoubleClickEventTriggered && this.selectedFunctionToView.category === 'Created by Me') {
        this.seqFunctionActionFromFlyout('edit', targetBlock);
      } else {
        this.toggleBuilderMode(BuilderMode.LIBRARY_BUILDER);
        setTimeout(() => {
          this.isFunctionBlockClicked = false;
        }, 500);
      }
    }
  }

  enableEditSequence(){
    this.blocklyWorkSpace.getToolbox()?.setVisible(true); //Making toolbox viewable in edit Mode.
    this.showToolBoxToggleButton = true; //Hiding show toolbox button viewable in edit Mode.
    this.editMode = true;
  }

  //Getter for checking with category and making the workspace readOnly or vice-versa
  get isWorkspaceReadOnly() {
    return this.selectedFunctionToView?.category == this.categories?.SHARED_BY_OTHERS;
  }

  resetLibraryBuilder() {
    this.sequencerBlock = null;
    this.selectedFunctionToView = null;
    this.isBuildingNewSequenceFunction = false;
    this.isDuplicatingSequenceFunction = false;
    this.isDeletingSequenceFunction = false;
    this.activeSequencerBlockRef = null;
    this.sequencerBlock = null;
    this.editMode = false;
    this.isFunctionBlockClicked = false;
  }

  clearFilterSearch() {
    this.dropdownFunctionsList.createdByMeFunctions = ObjectUtil.deepClone(this.duplicateFunctionBlock.createdByMeFunctions);
    this.dropdownFunctionsList.createdByOtherFunctions = ObjectUtil.deepClone(this.duplicateFunctionBlock.createdByOtherFunctions);
  }

  onOptionHover(item: any) {
    this.enableTableEdit = true;
    this.hoveredFunctionBlock = item;
  }

  onMouseOut() {
    this.enableTableEdit = false;
  }

  dropdownListAccordion(type : any) {
    this.dropdownAccordion[type] = !this.dropdownAccordion[type];
  }

  searchCustomBlocksOnDropDownList(searchText: string) {
    if (searchText && searchText?.length) {
      this.dropdownAccordion.createdByMeFunctions = true;
      this.dropdownAccordion.createdByOtherFunctions = true;
      const text = searchText.toLowerCase();
      this.searchBlocksOnDropDownListOnInternal(text);
    } else {
      this.clearFilterSearch();
    }
  }

  searchBlocksOnDropDownListOnInternal(text: string) {
    if (this.duplicateFunctionBlock?.createdByMeFunctions?.length) {
      const personalBlocks = ObjectUtil.deepClone(this.duplicateFunctionBlock.createdByMeFunctions);
      this.dropdownFunctionsList.createdByMeFunctions = this.functionBlockFilter(personalBlocks, text);
    }
    if (this.duplicateFunctionBlock?.createdByOtherFunctions?.length) {
      const sharedByOthersBlocks = ObjectUtil.deepClone(this.duplicateFunctionBlock.createdByOtherFunctions);
      this.dropdownFunctionsList.createdByOtherFunctions = this.functionBlockFilter(sharedByOthersBlocks, text);
    }
  }

  functionBlockFilter(array: any, text: any) {
    return array.filter((x: any) => x.seqFunctionName.toLowerCase().includes(text));
  }

  updateBlockLikeBookmark(type: string, item: any) {
    if (type == 'LIKE') {
      item.likedByLoggedInUser = !item.likedByLoggedInUser;
      if (item.likedByLoggedInUser) {
        item.noOfLikes += 1;
      } else {
        item.noOfLikes -= 1;
      }
    } else {
      item.bookmarkedByLoggedInUser = !item.bookmarkedByLoggedInUser;
    }

    let payload = {
      component: this.SequenceFunctionsComponent,
      category: type,
      value: type == 'LIKE' ? item.likedByLoggedInUser : item.bookmarkedByLoggedInUser
    }

    this.siteSequencerService.updateLikeOrBookmark(item?.functionId, payload).subscribe({
      next: (res: string) => {
        if (res)
        this.isLikeUnlikeRefresh = true;
        this.setUpdatedFunctionsBlockData();
      },
      error: (error) => {
        this.showToast('error', `${error.message}`);
        console.log(error.msg || error.message || 'something went wrong');
      }
    });
  }

  get SequenceFunctionsComponent() { 
      switch(this.categoryMenu.selectedCategory.id) {
        case Category.CLOUD:
          return componentType.CLOUD;
        case Category.SYSTEM:
          return componentType.SYSTEM;
        case Category.DEVICE:
          return componentType.DEVICE;
        default:
          return componentType.CLOUD;
      }
  }

  setUpdatedFunctionsBlockData(){
    this.siteSequencerService.getSequenceFunctionsList(this.SequenceFunctionsComponent).subscribe((res: any) => {
      if(res) {
        res.createdByMeList.forEach((functionObj: any) => functionObj['category'] = 'Created by Me');
        res.createdByOthers.forEach((functionObj: any) => functionObj['category'] = 'Shared by Others');
        this.sequencerFunctionCollection = [...res.createdByMeList, ...res.createdByOthers];
        
        // Setting the type for each block 
        this.sequencerFunctionCollection.forEach((blockObj: any) => {
          const defaultType = `fn_${blockObj.functionId}`
          // blockObj['type'] = this.createUniqueValueForKey(defaultType, 'type', this.sequencerFunctionCollection);
          blockObj['type'] = defaultType;
        });
        this.duplicateFunctionBlock.createdByMeFunctions = ObjectUtil.deepClone(res.createdByMeList);
        this.duplicateFunctionBlock.createdByOtherFunctions = ObjectUtil.deepClone(res.createdByOthers); 
        this.dropdownFunctionsList.createdByMeFunctions = ObjectUtil.deepClone(res.createdByMeList);
        this.dropdownFunctionsList.createdByOtherFunctions = ObjectUtil.deepClone(res.createdByOthers);
        this.sequencerFunctionCollection.forEach((blockObj: any) => {  
          this.categoryMenu.selectedCategory.id === Category.DEVICE ? dynamicDeviceBlockBuilder.sequencerFunctionblockInit(blockObj) :
            dynamicBlockBuilder.sequencerFunctionblockInit(blockObj);
        });
      } else {
        this.sequencerFunctionCollection = [];
        this.isLoading = false;
      }
    }, (err) => {
      this.isLoading = false;
      this.showToast('error', `${err.message}`)
    });
  }

  likeCountFormat(count: number) {
    if (count < 1000) {
      return count.toString();
    } else {
      const roundedNumber: string = (Math.floor(count / 100) / 10).toFixed(1);
      return `${roundedNumber}k`;
    }
  }

  drop(event: any, draggedFunctionConfig: any) {
    this.handleCursorType('dropped', event);
    const element = document.elementFromPoint(event.dropPoint.x, event.dropPoint.y);
    if (element) {
        const containerClass = element.classList.value;
        if(containerClass === 'blocklyMainBackground') {
          this.addSelectedFunctionBlockInWorkspace(event, draggedFunctionConfig);
        }
    } else {
        console.log("No element found at drop point.");
    }
  }

  handleCursorType(eventType: any, event: any) {
    if(eventType === 'dragStarted') {
      document.body.style.cursor = 'grabbing';
      this.isDraggingFunctionBlock = true;
    } else if(eventType === 'dragEnded') {
      document.body.style.cursor = 'default';
      this.isDraggingFunctionBlock = false;
    } else if(eventType === 'dropped') {
      document.body.style.cursor = 'default';
      this.isDraggingFunctionBlock = false;
    } else if(eventType === 'moved') {
      document.body.style.cursor = 'grabbing';
      this.isDraggingFunctionBlock = true;
      const element = document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y);
      if (element) {
        const containerClass = element.classList.value;
        if(containerClass === 'blocklyMainBackground') {
          document.body.style.cursor = 'grabbing';
        } else {
          document.body.style.cursor = 'no-drop';
        }
      }
    }
  }

  /**  
  *  get the sequence block type based on the selected category
  */
  get sequenceBlockType() {
    switch(this.categoryMenu.selectedCategory.id) {
      case Category.CLOUD:
        return BlockType.SEQUENCE;
      case Category.SYSTEM:
        return BlockType.SEQUENCE_SYSTEM;
      case Category.DEVICE:
        return BlockType.SEQUENCE_DEVICE;
      default:
        return BlockType.SEQUENCE;
    }
  }
  
  searchbtnClicked() {
    this.isSearchActive = true;
  }

  /**
   * Searches for a sequence based on the provided search text.
   * @param searchText - The text to search for.
   */
  searchSequence(searchText: string) {
    this.searchText = searchText;
    if (this.searchText && this.searchText.length) {
      this.searchSequences(this.searchText);
    } else {
      this.canvasTabs.tabs = this.tempCanvasTabs;
    }
  }

  /**
   * Searches for sequences based on the provided search text.
   * Updates the canvasTabs and sequenceTabs arrays with the filtered results.
   * @param searchText - The text to search for in the sequence names.
   */
  searchSequences(searchText: string) { 
    this.canvasTabs.tabs = this.tempCanvasTabs.filter((sequence: any) => sequence.name.toLowerCase().includes(searchText.toLowerCase()));
    this.sequenceTabs = this.sequenceTabs.filter((sequence: any) => sequence.seqName.toLowerCase().includes(searchText.toLowerCase()));
  }

  /**
   * Opens the menu for the given canvas data.
   * @param event - The mouse event that triggered the menu opening.
   * @param canvasdata - The data associated with the canvas.
   */
  openMenu(event: MouseEvent, canvasdata: any): void {
    this.clearOpenMenu();
    event.preventDefault(); // Prevent the default context menu from appearing
    const target = event.target as HTMLElement;
    const rect = target?.getBoundingClientRect();

    this.contextMenuPostion.x = rect?.left;
    this.contextMenuPostion.y = rect?.top;
    canvasdata.showMenu = true;
  }

  /**
   * Clears the canvas search by resetting the search text, deactivating the search,
   * and restoring the original tabs.
   */
  clearCanvasSearch() {
    this.searchText = '';
    this.isSearchActive = false;
    this.canvasTabs.tabs = this.tempCanvasTabs;
  }

  /**
   * Closes the search and sets the isSearchActive flag to false if the searchText is empty.
   */
  closeSearch() {
    if (this.searchText == '') {
      this.isSearchActive = false;
    }
  }

  canvasClicked(canvasTab: any) {
    clearTimeout(this.clickTimer);
    this.clickTimer = setTimeout(() => {
      this.switchToSelectedTab(canvasTab);
    }, 300);
  }

  canvasDblClicked(canvasTab: any) {
    clearTimeout(this.clickTimer);
    this.clearRenameCanvas();
    canvasTab.isCanvasNameEdit = true;
    // Setting the focus on the input field after the input is rendered.
    setTimeout(() => {
      this.renameInput.nativeElement.focus();
    }, 0);
  }

  /**
   * Clears the open menu for all canvas tabs.
   */
  clearOpenMenu(){
    this.canvasTabs.tabs.map((canvasObj: any) => canvasObj.showMenu = false);
  }

  clearRenameCanvas() {
    this.canvasTabs.tabs.map((canvasObj: any) => canvasObj.isCanvasNameEdit = false);
  }

  /**
   * Toggling input field on canvas tab to rename the canvas.
   * @param {any} canvasTab - The canvas tab to be renamed.
   * @param {MouseEvent} event - The mouse event that triggered the rename. 
   */
  showRenameCanvasInput(canvasTab: any, event: MouseEvent) {
    event.stopPropagation();
    canvasTab.showMenu = false;
    this.clearRenameCanvas();
    canvasTab.isCanvasNameEdit = true;
    // Setting the focus on the input field after the input is rendered.
    setTimeout(() => {
        this.renameInput.nativeElement.focus();
    }, 0);
  }

  /**
   * checking the provided value in the input with the existing canvas name and appending a unique value if the name already exists.
   * @param {any} canvasTab - The canvas tab to be renamed.
   */
  renameCanvasText(canvasTab: any) {
    const oldCanvasName = canvasTab.name;
    if (canvasTab.tempName == '') {
      canvasTab.tempName = canvasTab.name;
    } else if (canvasTab.name != canvasTab.tempName) {
      if(canvasTab.tempName.length > 40) {
        this.showToast('error', 'Canvas name should not exceed 40 characters');
      } else {
        const newName = this.createUniqueValueForKey(canvasTab.tempName, 'name', this.tempCanvasTabs, '-');
        if(newName.length > 40) {
          this.showToast('error', 'Canvas name should not exceed 40 characters');
        } else {
          canvasTab.tempName = this.createUniqueValueForKey(canvasTab.tempName, 'name', this.tempCanvasTabs, '-');
          canvasTab.name = canvasTab.tempName;
          this.renameWorkSpace(canvasTab, oldCanvasName);
        }
      }
    }
    canvasTab.isCanvasNameEdit = false;
  }

  /**
   * Renames the selected canvas tab and shows a success message.
   * @param {any} selectedCanvasTab - The canvas tab to be renamed.
   */
  renameWorkSpace(selectedCanvasTab: any, oldCanvasName: any) {
    let xml = selectedCanvasTab.workspaceXml;
    let workspaceRef: any = {
      "siteRef": this.selectedSiteId,
      "workspaceXml": xml || '',
      "category": this.categoryMenu.selectedCategory.id,  // "category": "CLOUD" or "CCU" or "DEVICE"
      "name": selectedCanvasTab.name,
      "id": selectedCanvasTab.id
    }

    this.siteSequencerService.saveWorkspace(workspaceRef).subscribe((res) => {
      this.updateWorkspaceXMLInCanvasTab(selectedCanvasTab,workspaceRef)
      this.searchSequence(this.searchText);
      
      this.showToast('success', 'Workspace Renamed successfully');
      if (this.categoryMenu.selectedCategory.id == Category.DEVICE && this.canvasTabs.selectedCanvasTab?.id == selectedCanvasTab.id) {
        this.saveAllSequences()
      }
    }, (err) => {
      this.showToast('error', `${err.message}`);
    });
  }

  updateWorkspaceXMLInCanvasTab(selectedCanvasTab:any,workspaceRef:any) {
    let tempCanvasIndex = this.tempCanvasTabs.findIndex((tab: any) => tab.id == selectedCanvasTab.id);
    let tabIndex = this.canvasTabs.tabs.findIndex((tab: any) => tab.id == selectedCanvasTab.id);
    if(tempCanvasIndex > -1) {
      this.tempCanvasTabs[tempCanvasIndex] = selectedCanvasTab;
      this.tempCanvasTabs[tempCanvasIndex].workspaceXml = workspaceRef.workspaceXml;
    }
    if(tabIndex > -1) {
      this.canvasTabs.tabs[tabIndex] = selectedCanvasTab;
      this.canvasTabs.tabs[tabIndex].workspaceXml = workspaceRef.workspaceXml;
    }
  }

  /**
   * Deletes the canvas tab and hides the menu.
   * @param canvasTab - The canvas tab to be deleted.
   */
  deleteCanvas(canvasTab: any, event: MouseEvent) {
    event.stopPropagation();
    this.isLoading = true;
    this.siteSequencerService.getAllSequencesPerWorkspace(this.selectedSiteId, canvasTab.id)
    .subscribe((res) => {
      if(res) {
        this.isLoading = false;
        canvasTab.showMenu = false;
        const sequenceFunctionUsage: any = {
          "sequenceCount": res.length,
        };
        sequenceFunctionUsage['eventType'] = 'delete';
        const dialogRef = this.dialog.open(EditAndDeleteConfirmationModalComponent, {
          panelClass: 'fs-mat-dialog-container',
          minWidth: '400px',
          disableClose: true
        });
        const title = `Are you sure you want to delete ${canvasTab.name}?`;
        const subTitle = 'this action cannot be undone';
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.subTitle = subTitle;
        dialogRef.componentInstance.sequenceFunction = sequenceFunctionUsage;
        dialogRef.componentInstance.type = 'confirm';
        dialogRef.componentInstance.confirmBtnText = 'delete';
        dialogRef.componentInstance.showConfirmIcon = true;
        dialogRef.updatePosition({ top: '60px' });
        dialogRef.componentInstance.isCanvasAction = true;
        dialogRef.afterClosed().subscribe(result => {
          // On delete confirmation
          if(result && result == 'confirm') {
            this.isLoading = true;
            this.siteSequencerService.deleteWorkspace(this.selectedSiteId, canvasTab.id)
            .subscribe((result) => {
              if(result && result.id === canvasTab.id) {
                this.sequenceTabs = [];
                this.deviceRunBlocksOnWorkspace = []; // Clearing the existing device run blocks on workspace
                this.canvasTabs.tabs = this.canvasTabs.tabs.filter((tab: any) => tab.id !== canvasTab.id);
                this.tempCanvasTabs = this.tempCanvasTabs.filter((tab: any) => tab.id !== canvasTab.id);
                this.isLoading = false;
                this.blocklyWorkSpace.clear();
                this.showToast('success', `Workspace ${canvasTab.name} deleted succesfully`);
                if(this.canvasTabs.tabs.length > 0 && this.tempCanvasTabs.length > 0) {
                  const isInitWorkspace = false;
                  const isDeletingWorkspace = true;
                  this.switchToSelectedCanvasTab(this.canvasTabs.tabs[0], isInitWorkspace, isDeletingWorkspace);
                } else {
                  const initWorkspace = true;
                  const categoryLevelTabConfig = { 'index': this.categoryMenu.activeTabIndex, 'tab': { "textLabel": this.categoryMenu.selectedCategory.label } };
                  this.toggleCategoryLevelTab(categoryLevelTabConfig, initWorkspace);
                }
              }
            }, (err: any) => {
              this.isLoading = false;
              this.showToast('error', `Something went wrong in deleting workspace ${canvasTab.name} , please click delete option again.`);
            })
          }
        });
      }

    }, (err: any) => {
      this.isLoading = false;
      this.showToast('error', `Something went wrong in deleting workspace ${canvasTab.name} , please click delete option again.`);
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    this.clearOpenMenu();
  }

  /**
  * Appends ellipses to a text.
  * @param textToFormat - The text to append ellipses to.
  */
  appendEllipse(textToFormat: string) {
    if (textToFormat && textToFormat.length >= 5) {
      return textToFormat.slice(0, 5) + '...';
    } else {
      return textToFormat;
    }
  }


  downloadFile(data: any, filename: string) {
    const element = document.createElement('a');
    let url = '';
    if (filename.includes('.py')) {
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      element.setAttribute('download', filename);
      element.style.display = 'none';
    } else {
      url = window.URL.createObjectURL(data);
      element.href = url;
      element.download = filename;
    }
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    window.URL.revokeObjectURL(url);
  }

  searchSequenceLogs(searchedValue: any) {
    this.searchedSequenceLogsValue = searchedValue;
  }

  pythonToMicropython(code:string) {
    // String which is inserted by Blockly to import the numbers module
    // This is not supported by Micropython and needs to be removed
    const PYTHON_IMPORT_NUMBERS_STRING = 'from numbers import Number';
  
    // Remove the import numbers statement
    code = code.replace(PYTHON_IMPORT_NUMBERS_STRING, '');
  
    // Number is not supported by Micropython, replace with int or float
    const isInstanceCode = "isinstance(myVariable, Number)";
    code = code.replace(/isinstance\((\w+), Number\)/g, 'isinstance($1, (int,float))');
  
    return code;
  }

  /**
   * Downloads the sequence code as a MicroPython file.
   * If the selected canvas tab has a name, it will be used as the filename.
   * Otherwise, an empty string will be used as the filename.
   * @returns {Promise<void>} A promise that resolves when the download is complete.
   */
  async downLoadSequence() {
    try {
      let filename = this.canvasTabs.selectedCanvasTab?.name || '';
      if(!this.code)  {
        this.validateBlocklyLogic();
      }
      this.siteSequencerService.getMicroPythonConversion(this.code, filename).subscribe(async (res: any) => {
       let firmwareFile =  await DFU.readBlobContent(res)
        if (firmwareFile.byteLength > 65536) {
          // Code to handle the case when firmwareFile byte length is greater than 64KB
          this.showToast('error', `The sequence size must be less than 64KB to upload to the device. Please reduce the size and try again.`);
          return;
        }
        this.webusbConnect(res);
      }, (err: any) => {
        console.log(err);
        this.showToast('error', `Something went wrong in downloading the sequence code, please try again.`);
      })

    } catch (error) {
      this.showToast('error', `Failed to connect to Device.`);
    }
  }

  async webusbConnect(blob: any) {
    try {
      let filters = [];

      filters.push({ 'vendorId': '0x0483' });
      navigator.usb.requestDevice({ 'filters': filters }).then(
        async (selectedDevice: any) => {
          let interfaces = DFU.findDeviceDfuInterfaces(selectedDevice);
          if (interfaces.length == 0) {
            this.showToast('error', `The selected device does not have any USB DFU interfaces`);

          } else {
            await DFU.fixInterfaceNames(selectedDevice, interfaces);
            let flashInterface:any = interfaces.find((x: any) => x.name?.includes('Internal Flash'));
            if (flashInterface) {
              selectedDevice = await DFU.connect(new Device(selectedDevice, flashInterface));
            } else {
              this.showToast('error', `The selected device does not have any USB DFU interfaces.`);
              return;
            }
            try {
              let metadata = await DFU.generateMetadata(blob,this.canvasTabs.selectedCanvasTab?.name || '')
              await DFU.upload(selectedDevice, blob, 1024, DFU.SEQUENCE_ADDERESS, false);
              await DFU.upload(selectedDevice,metadata , 1024, DFU.SEQUENCE_META_ADDERESS, true);
              this.showToast('success', `Sequence uploaded to the device successfully.`);
            } catch(error) {
              this.showToast('error', `Failed to upload the file to the device.`);
            }
           

          }
        }
      ).catch((error: any) => {
        this.showToast('error', `Failed to connect to Device.`);
        let filename = this.canvasTabs.selectedCanvasTab?.name || '';
        this.downloadFile(blob, filename + ".mpy")
      });
    } catch (error) {
      this.showToast('error', `Failed to connect to Device.`);
      console.error('Error connecting to micro:bit:', error);
    }
  }

  get ownerDetails() {
    if(this.hoveredFunctionBlock) {
      let firstName = this.hoveredFunctionBlock?.createdBy?.firstName != null ? this.hoveredFunctionBlock?.createdBy?.firstName : ""
      let lastName = this.hoveredFunctionBlock?.createdBy?.lastName != null ? this.hoveredFunctionBlock?.createdBy?.lastName : ""
      let ownerDetails = (firstName || lastName) ? (firstName + " " + lastName) : this.hoveredFunctionBlock?.createdBy?.userEmailId;
      ownerDetails = this.hoveredFunctionBlock?.createdBy?.userId && this.hoveredFunctionBlock?.createdBy?.userId === 'external' ? `${ownerDetails}(External)` : `${ownerDetails}`;
      return ownerDetails;
    }
  }

  // Function to generate a random string of length 10
  generateRandomString() {
    const length = 10;
    const createCharacterArray = () => {
        const charArray: any[] = [];
        const baseArray = [...Array(62)];
        baseArray.forEach((char, i) => {
            const charCode = i + (i < 10 ? 48 : (i < 36 ? 55 : 61));
            charArray.push(String.fromCharCode(charCode));
        });
        return charArray.join('');
    };

    const characters = createCharacterArray();
    let result = '';
    const baseArray = [...Array(length)];
    baseArray.forEach(() => {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    });
    return result;
  }


  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}




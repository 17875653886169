import { javascriptGenerator, Order } from 'blockly/javascript';
import * as Blockly from 'blockly';
export class CodeGenerate {
  static addCodeGenerators() {
    try {

      Blockly.Extensions.registerMutator('hystersis_mutator', {
        /**
         * Serialize the state of SHOW_UPPER_LIMIT and SHOW_LOWER_LIMIT.
         */
        mutationToDom: function() {
          const container = document.createElement('mutation');
          console.log('SHOW_UPPER_LIMIT:', this.getFieldValue('SHOW_UPPER_LIMIT')); // Debug
          console.log('SHOW_LOWER_LIMIT:', this.getFieldValue('SHOW_LOWER_LIMIT')); // Debug
          container.setAttribute('show_upper_limit', this.getFieldValue('SHOW_UPPER_LIMIT'));
          container.setAttribute('show_lower_limit', this.getFieldValue('SHOW_LOWER_LIMIT'));
          return container;
        },
      
        /**
         * Deserialize the state and update the block.
         */
        domToMutation: function(xmlElement:any) {
          const showUpperLimit = xmlElement.getAttribute('show_upper_limit') === 'YES';
          const showLowerLimit = xmlElement.getAttribute('show_lower_limit') === 'YES';
          this.updateShape_(showUpperLimit, showLowerLimit);
        },
      
        /**
         * Handle changes to dropdown fields.
         */
        onchange: function(event:any) {
          if (event.type === Blockly.Events.BLOCK_CHANGE && event.blockId === this.id) {
            const showUpperLimit = this.getFieldValue('SHOW_UPPER_LIMIT') === 'YES';
            const showLowerLimit = this.getFieldValue('SHOW_LOWER_LIMIT') === 'YES';
            this.updateShape_(showUpperLimit, showLowerLimit);
          }
        },
      
        /**
         * Update the block shape by hiding or showing inputs.
         */
        updateShape_: function(showUpperLimit:boolean, showLowerLimit:boolean) {
          // Handle Upper Limit
          const upperLimitInputs = [
            'ALERT_TRIGGER_UPPER_LIMIT',
            'ALERT_FIX_UPPER_LIMIT',
            'ALERT_TRIGGER_UPPER_LIMIT_OFFSET',
            'ALERT_FIX_UPPER_LIMIT_OFFSET'
          ];
      
          upperLimitInputs.forEach(input => {
            const inputField = this.getInput(input);
            if (inputField) {
              inputField.setVisible(showUpperLimit); // Hide/Show input
            }
          });
      
          // Handle Lower Limit
          const lowerLimitInputs = [
            'ALERT_TRIGGER_LOWER_LIMIT',
            'ALERT_FIX_LOWER_LIMIT',
            'ALERT_TRIGGER_LOWER_LIMIT_OFFSET',
            'ALERT_FIX_LOWER_LIMIT_OFFSET'
          ];
      
          lowerLimitInputs.forEach(input => {
            const inputField = this.getInput(input);
            if (inputField) {
              inputField.setVisible(showLowerLimit); // Hide/Show input
            }
          });
      
          // Refresh the block rendering to reflect changes
          this.initSvg();
          this.render();
        }
      });

      function functionNameValidator(name: string) {
        return name.replace(/^[^a-zA-Z_]+|[^a-zA-Z0-9_]/g, '_');
      }

      javascriptGenerator.forBlock['marker'] = function (block: any, generator: any) {
        // Search the text for a substring.
        var marker = block.getFieldValue('markername');
        var status = block.getFieldValue('RUN_STATUS');
        var level = block.getFieldValue('Level');
        var occurance = block.getFieldValue('Occurance');
        var runCode = '\nvar level = ' + `"${level}"` + ';\nvar occurance = ' + `"${occurance}"` + ';';
        var sequenceCode = generator.statementToCode(block, 'CODE');

        sequenceCode = generator.addLoopTrap(sequenceCode, block);
        return `/**Sequence ${marker} - Status: ${status}**/
        \nfunction sequence_${marker.replaceAll(' ', '')}() {
          \n${runCode}
          \n${sequenceCode}  
          \n}\n
        /**End of Sequence ${marker}**/ \n`;
      };

      javascriptGenerator.forBlock['sequencer'] = function (block: any, generator: any) {
        // Search the text for a substring.
        var sequencerName = block.getFieldValue('seqFunctionName');
        var sequencerCode = generator.statementToCode(block, 'CODE');
        sequencerCode = generator.addLoopTrap(sequencerCode, block);
        return `
        \nfunction ${functionNameValidator(sequencerName)}() {
          \n${sequencerCode}  
          \n}\n`
      };

      javascriptGenerator.forBlock['sequence_system'] = function (block: any, generator: any) {
        // Search the text for a substring.
        var marker = block.getFieldValue('markername');
        var status = block.getFieldValue('RUN_STATUS');
        var scope = block.getFieldValue('scope');
        var occurance = block.getFieldValue('Occurance');
        var runCode = '\nvar scope = ' + `"${scope}"` + ';\nvar occurance = ' + `"${occurance}"` + ';';
        var sequenceCode = generator.statementToCode(block, 'CODE');

        sequenceCode = generator.addLoopTrap(sequenceCode, block);
        return `/**Sequence ${marker} - Status: ${status}**/
        \nfunction sequence_${marker.replaceAll(' ', '')}() {
          \n${runCode}
          \n${sequenceCode}  
          \n}\n
        /**End of Sequence ${marker}**/ \n`;
      };

      javascriptGenerator['haystack_logical_operator'] = function (block: any, generator: any) {
        var code = '';
        var lhs = generator.valueToCode(block, 'Query_LHS', generator.ORDER_ATOMIC);
        var operator = block.getFieldValue('OPERATOR');
        var rhs = generator.valueToCode(block, 'Query_RHS', generator.ORDER_ATOMIC);

        // Handling RHS based on LHS and operator
        if (lhs && operator && rhs && rhs !== null && rhs.length > 0) {
          code = `'('+(${lhs} +' ${operator} '+ ${rhs})+')'`;
        }
        return [code, Order.ATOMIC];
      };
      javascriptGenerator['haystack_query_condition'] = function (block: any, generator: any) {
        var code = '';
        var lhs = generator.valueToCode(block, 'Query_LHS', generator.ORDER_ATOMIC);
        var operator = block.getFieldValue('OPERATOR');
        var rhs = generator.valueToCode(block, 'Query_RHS', generator.ORDER_ATOMIC);

        // Handling RHS based on LHS and operator
        if (lhs && operator && rhs && rhs !== null && rhs.length > 0) {
          var appendText = '';
          if (lhs.includes('Ref') || lhs.includes('id')) {
            appendText = '@';
          } else {
            appendText = '';
          }

          code = appendText ? `(${lhs} + '${operator}' + '${appendText}' + ${rhs})` : `(${lhs} + '${operator}' + '\\\\\\\\"' + ${rhs} + '\\\\\\\\"')`;
        }
        return [code, Order.ATOMIC];
      };

      javascriptGenerator['haystack_not_operator'] = function (block: any, generator: any) {
        var code = '';
        const query = generator.valueToCode(block, 'condition', generator.ORDER_ATOMIC);
        code = `'not '+ ${query}`;
        return [code, Order.ATOMIC];
      }

      javascriptGenerator.forBlock['haystack_query'] = function (block: any, generator: any) {
        var code = '';
        var query = block.getFieldValue('QUERY');
        var condition = generator.valueToCode(block, 'Query_condition', generator.ORDER_ATOMIC);
        const queryWithCondition = condition ? `${query} and ` + condition : `${query}`;
        code = `"(${queryWithCondition})"`;
        return [`${'JSON.parse(haystack.findByFilter(' + code + ', ctx))\n'}`, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['haystack_query_with_type'] = function (block: any, generator: any) {
        var queryText = '';
        var code = '';
        var query = block.getFieldValue('QUERY');
        var type = block.getFieldValue('QUERY_TYPE');
        var condition = generator.valueToCode(block, 'Query_condition', generator.ORDER_ATOMIC);
        const queryWithCondition = condition ? `${query} and ` + condition : `${query}`;
        queryText = `"(${queryWithCondition})"`;
        switch (type) {
          case 'point':
            code = `JSON.parse(haystack.findEntityByFilter(${queryText}, ctx))\n`;
            break;
          case 'pointValue':
            code = `JSON.parse(haystack.findValueByFilter(${queryText}, ctx))\n`;
            break;
          case 'list':
            code = `JSON.parse(haystack.findByFilter(${queryText}, ctx))\n`;
            break;
          default:
            code = `JSON.parse(haystack.findByFilter(${queryText}, ctx))\n`;
            break;
        }
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['haystack_query_with_condition_pre_defined'] = function (block: any, generator: any) {
        const query = block.getFieldValue('QUERY');
        const REF_TYPE = block.getFieldValue('REF_TYPE');
        const operator = block.getFieldValue('OPERATOR');
        var rhs = generator.valueToCode(block, 'Query_condition', generator.ORDER_ATOMIC);
        var condition = `('${REF_TYPE}' + '${operator}' + '@' + ${rhs})`;
        return [`JSON.parse(haystack.findByFilter(function(query,condition) {
          var code = "("+ query + (condition ? " and " + eval(condition) : '')+")";
          return code;
        } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['haystack_query_with_condition_pre_defined_equip'] = function (block: any, generator: any) {
        const query = block.getFieldValue('QUERY');
        const REF_TYPE = block.getFieldValue('REF_TYPE');
        const operator = block.getFieldValue('OPERATOR');
        var rhs = generator.valueToCode(block, 'Query_condition', generator.ORDER_ATOMIC);
        var condition = `('${REF_TYPE}' + '${operator}' + '@' + ${rhs})`;
        return [`JSON.parse(haystack.findByFilter(function(query,condition) {
          var code = "(equip and ("+ query + (condition ? " and " + eval(condition) : '')+"))";
          return code;
        } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];

      };

      javascriptGenerator.forBlock['haystack_query_with_condition_pre_defined_device'] = function (block: any, generator: any) {
        const query = block.getFieldValue('QUERY');
        const REF_TYPE = block.getFieldValue('REF_TYPE');
        const operator = block.getFieldValue('OPERATOR');
        var rhs = generator.valueToCode(block, 'Query_condition', generator.ORDER_ATOMIC);
        var condition = `('${REF_TYPE}' + '${operator}' + '@' + ${rhs})`;
        return [`JSON.parse(haystack.findByFilter(function(query,condition) {
          var code = "(device and ("+ query + (condition ? " and " + eval(condition) : '')+"))";
          return code;
        } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['haystack_query_with_condition_pre_defined_current_value'] = function (block: any, generator: any) {
        const query = block.getFieldValue('QUERY');
        const REF_TYPE = block.getFieldValue('REF_TYPE');
        const operator = block.getFieldValue('OPERATOR');
        var rhs = generator.valueToCode(block, 'Query_condition', generator.ORDER_ATOMIC);
        var condition = `('${REF_TYPE}' + '${operator}' + '@' + ${rhs})`;
        return [`JSON.parse(haystack.findValueByFilter(function(query,condition) {
          var code = "("+ query + (condition ? " and " + eval(condition) : '')+")";
          return code;
        } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['haystack_query_with_condition'] = function (block: any, generator: any) {
        const query = block.getFieldValue('QUERY');
        var condition = generator.valueToCode(block, 'Query_condition', generator.ORDER_ATOMIC);
        var type = block.getFieldValue('QUERY_TYPE');


        switch (type) {
          case 'point':
            return [`JSON.parse(haystack.findEntityByFilter(function(query,condition) {
              var code = "("+ query + (condition ? " and " + eval(condition) : '')+")";
              return code;
            } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];

          case 'pointValue':
            return [`JSON.parse(haystack.findValueByFilter(function(query,condition) {
              var code = "("+ query + (condition ? " and " + eval(condition) : '')+")";
              return code;
            } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];

          case 'list':
            return [`JSON.parse(haystack.findByFilter(function(query,condition) {
              var code = "("+ query + (condition ? " and " + eval(condition) : '')+")";
              return code;
            } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];

          default:
            return [`JSON.parse(haystack.findByFilter(function(query,condition) {
              var code = "("+ query + (condition ? " and " + eval(condition) : '')+")";
              return code;
            } ("${query}","${condition.replace(/"/g, "\\\"")}"), ctx))\n`, Order.ATOMIC];

        }
      };

      javascriptGenerator.forBlock['pointwrite'] = function (block: any, generator: any) {
        var level = block.getFieldValue('level');
        var value = generator.valueToCode(block, 'value', generator.ORDER_ATOMIC);
        var id = generator.valueToCode(block, 'id', generator.ORDER_ATOMIC);

        var code = `\nhaystack.pointWrite(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),${level},${value},${false},ctx);\n`
        return code;
      };

      javascriptGenerator.forBlock['point_write_null'] = function (block: any, generator: any) {
        var level = block.getFieldValue('level');
        var id = generator.valueToCode(block, 'id', generator.ORDER_ATOMIC);

        var code = `\nhaystack.clearPointValueMany(
          (function(arg) {
            const items = Array.isArray(arg) ? arg : [arg];
            return items.map(item => 
              typeof item === "object" && item !== null ? item.id : item
            );
          })(${id}),${level},ctx);\n`
          return code;
      };

      javascriptGenerator.forBlock['hisWrite'] = function (block: any, generator: any) {
        var value = generator.valueToCode(block, 'value', generator.ORDER_ATOMIC);
        var id = generator.valueToCode(block, 'id', generator.ORDER_ATOMIC);
        var code = `\nhaystack.hisWrite(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),${value},ctx);\n`
        return code;
      };

      javascriptGenerator.forBlock['getHistorizedValue'] = function (block: any, generator: any) {
        var code = ''
        var interval = generator.valueToCode(block, 'INTERVAL', generator.ORDER_ATOMIC);
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        code = `JSON.parse(haystack.hisReadManyInterpolateWithInterval(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),${interval},ctx))\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['getHistorizedValueExcludeMins'] = function (block: any, generator: any) {
        var code = ''
        var interval = generator.valueToCode(block, 'INTERVAL', generator.ORDER_ATOMIC);
        var exclude = generator.valueToCode(block, 'EXCLUDE_INTERVAL', generator.ORDER_ATOMIC);
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        code = `JSON.parse(haystack.hisReadManyInterpolateWithInterval(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),${interval},${exclude},ctx))\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['getAggregatedValuesFromDateRange'] = function (block: any, generator: any) {
        var code = '';
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        var fromDate = block.getFieldValue('start_date');
        var endDate = block.getFieldValue('end_date');
        var groupBy = block.getFieldValue('groupBy');
        var groupByValue = block.getFieldValue('groupByValue');
        var aggregateBy = block.getFieldValue('aggregationFunction');
        var interpolationMethod = block.getFieldValue('interpolationMethod');
        code = `JSON.parse(haystack.getAggregateTimeSeries(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),'${fromDate}','${endDate}','${groupByValue}','${groupBy}','${aggregateBy}','${interpolationMethod}', ctx))\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['getAggregatedValuesFromDateTimeVariant'] = function (block: any, generator: any) {
        var code = ''
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        var fromDate = block.getFieldValue('start_date');
        var endDate = block.getFieldValue('end_date');
        var fromTime = block.getFieldValue('start_time') + ':00';
        var endTime = block.getFieldValue('end_time') + ':00';
        var groupBy = block.getFieldValue('groupBy');
        var groupByValue = block.getFieldValue('groupByValue');
        var aggregateBy = block.getFieldValue('aggregationFunction');
        var interpolationMethod = block.getFieldValue('interpolationMethod');
        var startDateTime = fromDate + ' ' + fromTime;
        var endDateTime = endDate + ' ' + endTime;
        code = `JSON.parse(haystack.getAggregateTimeSeries(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),'${startDateTime}','${endDateTime}', '${groupByValue}','${groupBy}','${aggregateBy}','${interpolationMethod}', ctx))\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['gethistorizedNVariant'] = function (block: any, generator: any) {
        var code = ''
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        var rangeValue = block.getFieldValue('rangeValue');
        var rangeUnit = block.getFieldValue('rangeUnit');
        code = `JSON.parse(haystack.hisReadManyInterpolateWithInterval(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),'${rangeValue}', '${rangeUnit}', ctx))\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['gethistorizedDateTimeVariant'] = function (block: any, generator: any) {
        var code = ''
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        var fromDate = block.getFieldValue('start_date');
        var endDate = block.getFieldValue('end_date');
        var fromTime = block.getFieldValue('start_time') + ':00';
        var endTime = block.getFieldValue('end_time') + ':00';
        var startDateTime = fromDate + ' ' + fromTime;
        var endDateTime = endDate + ' ' + endTime;
        code = `JSON.parse(haystack.hisReadManyInterpolateWithDateRange(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),'${startDateTime}','${endDateTime}', ctx))\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['getAggregatedValues'] = function (block: any, generator: any) {
        var code = ''
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        var rangeValue = block.getFieldValue('rangeValue');
        var rangeUnit = block.getFieldValue('rangeUnit');
        var groupBy = block.getFieldValue('groupBy');
        var groupByValue = block.getFieldValue('groupByValue');
        var aggregateBy = block.getFieldValue('aggregationFunction');
        var interpolationMethod = block.getFieldValue('interpolationMethod');

        code = `haystack.getAggregateTimeSeriesWithRange(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),'${rangeValue}','${rangeUnit}','${groupByValue}','${groupBy}','${aggregateBy}','${interpolationMethod}', ctx)\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['getWrtiablePointValue'] = function (block: any, generator: any) {
        var code = '';
        var level = block.getFieldValue('level');
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        code = `haystack.fetchValueByPointWriteMany(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}), ${level},ctx)\n`;
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['getCurrentHisValue'] = function (block: any, generator: any) {
        var arg = generator.valueToCode(block, 'PointID',
          Order.ATOMIC) || '';

        return [`${`haystack.fetchValueById(function(arg) {
          if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${arg}), ctx)`}\n`, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['getKeyValue'] = function (block: any, generator: any) {
        var item = generator.valueToCode(block, 'item', generator.ORDER_MEMBER) || '[]';
        var value_logic = block.getFieldValue('key');
        var code = '';
        code += '(function(t, key) { ' +
                'if (typeof t === "string") { ' +
                't = JSON.parse(t); ' +
                '} ' +
                'return t[key]; ' +
                '}(' + item + ', "' + value_logic + '"))';
        return [code, generator.ORDER_FUNCTION_CALL];
    };

      javascriptGenerator.forBlock['alert'] = function (block: any, generator: any) {
        var code = `\nalerts.triggerAlert('${block.id}', ctx);\n`
        return code;
      };

      javascriptGenerator.forBlock['alertWithRef'] = function (block: any, generator: any) {
        const ref = generator.valueToCode(block, 'ALERT_REFERENCE', generator.ORDER_MEMBER) || '';
        const message = generator.valueToCode(block, 'ALERT_MESSAGE', generator.ORDER_MEMBER) || '';
        const notificationMessage = generator.valueToCode(block, 'ALERT_NOTIFICATION_MESSAGE', generator.ORDER_MEMBER) || '';
        const blockId = block.data ? block.data : block.id;
        const code = `\nalerts.triggerAlert('${blockId}',${notificationMessage},${message},${ref}, ctx); \n`
        return code;
      };

      javascriptGenerator.forBlock['alertHystersis'] = function (block: any, generator: any) {
        const ref = generator.valueToCode(block, 'ALERT_REFERENCE', generator.ORDER_MEMBER) || '';
        const message = generator.valueToCode(block, 'ALERT_MESSAGE', generator.ORDER_MEMBER) || '';
        const notificationMessage = generator.valueToCode(block, 'ALERT_NOTIFICATION_MESSAGE', generator.ORDER_MEMBER) || '';
        const blockId = block.data ? block.data : block.id;

        const code = `\nalerts.triggerAlert('${blockId}',${notificationMessage},${message},${ref}, ctx); \n`
        return code;
      };

      javascriptGenerator.forBlock['alertWithOffset'] = function (block: any, generator: any) {
        const ref = generator.valueToCode(block, 'ALERT_REFERENCE', generator.ORDER_MEMBER) || '';
        const message = generator.valueToCode(block, 'ALERT_MESSAGE', generator.ORDER_MEMBER) || '';
        const notificationMessage = generator.valueToCode(block, 'ALERT_NOTIFICATION_MESSAGE', generator.ORDER_MEMBER) || '';
        const blockId = block.data ? block.data : block.id;
        var trigger_offset = generator.valueToCode('ALERT_TRIGGER_OFFSET',generator.ORDER_MEMBER) || 0;
        var fix_offset = generator.valueToCode('ALERT_FIX_OFFSET', generator.ORDER_MEMBER) || 0;
        const code = `\nalerts.triggerAlert('${blockId}',${notificationMessage},${message},${ref}, ctx); \n`
        return code;
      };


      

      javascriptGenerator.forBlock['logger'] = function (block: any, generator: any) {
        var name = block.getFieldValue('log_name');
        var log = generator.valueToCode(block, 'log_value', generator.ORDER_MEMBER) || '';
        return `\n ctx.debugLog('${name}', JSON.stringify({value:${log}}))\n`;
      };

      javascriptGenerator.forBlock['comment'] = function (block: any, generator: any) {
        var commentText = block.getFieldValue('comment');
        return `\n /* ${commentText} */ \n`;
      };

      javascriptGenerator.forBlock['getHistorizedValueFromDateRange'] = function (block: any, generator: any) {
        var code = ''
        var id = generator.valueToCode(block, 'PointID', generator.ORDER_ATOMIC);
        var fromDate = block.getFieldValue('start_date');
        var endDate = block.getFieldValue('end_date');
        code = `JSON.parse(haystack.hisReadManyInterpolateWithDateRange(function(arg) {
          if(!arg) return arg;
          else if (typeof arg === "string") return arg;
          else if(typeof arg === "object") return arg.id;
          else return arg;
        }(${id}),'${fromDate}','${endDate}', ctx))\n`
        return [code, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['maparray'] = function (block: any, generator: any) {
        var list = generator.valueToCode(block, 'list', generator.ORDER_MEMBER) || '[]';
        var value_logic = "item." + block.getFieldValue('key');
        var code = '';
        code += '(function(t){ if (typeof t === "string") return JSON.parse(t);  return t;}(' + list + ')).map(function (item){ return Number(' + value_logic + ');})';
        code += '';

        return code;
      };

      javascriptGenerator.forBlock['create_persistent_variable'] = function (block: any, generator: any) {
        const key = generator.valueToCode(block, 'VARIABLE_NAME', generator.ORDER_MEMBER) || '';
        const value = generator.valueToCode(block, 'VARIABLE_VALUE', generator.ORDER_MEMBER) || null;
        const code = `\npersistBlock.create(${key}, ${value}, ctx);\n`
        return code;
      };

      javascriptGenerator.forBlock['set_persistent_variable'] = function (block: any, generator: any) {
        const key = generator.valueToCode(block, 'VARIABLE_NAME', generator.ORDER_MEMBER) || '';
        const value = generator.valueToCode(block, 'VARIABLE_VALUE', generator.ORDER_MEMBER) || null;
        const code = `\n persistBlock.set(${key}, ${value}, ctx);\n`
        return code;
      };

      javascriptGenerator.forBlock['get_persistent_variable'] = function (block: any, generator: any) {
        var key = generator.valueToCode(block, 'VARIABLE_NAME', generator.ORDER_MEMBER) || '';
        key = key ? key.replace(/^'|'$/g, "\"") : null;
        const code = `persistBlock.get(${key}, ctx)\n`
        return [code, Order.ATOMIC];
      };

    }
    catch (e) {
      console.log("Error in adding code generators", e);
    }
  }

  static sequenceTemplates() {
    try {

      javascriptGenerator.forBlock['template_num_to_num'] = function (block: any, generator: any) {
        var code = '';

        const numbericInput = generator.valueToCode(block, 'NUMERIC_INPUT', generator.ORDER_ATOMIC);
        const minInput = generator.valueToCode(block, 'MIN_INPUT', generator.ORDER_ATOMIC);
        const maxInput = generator.valueToCode(block, 'MAX_INPUT', generator.ORDER_ATOMIC);
        const minOutput = generator.valueToCode(block, 'MIN_OUTPUT', generator.ORDER_ATOMIC);
        const maxOutput = generator.valueToCode(block, 'MAX_OUTPUT', generator.ORDER_ATOMIC);

        code = `((((${numbericInput} - ${minInput}) * (${maxOutput} - ${minOutput})) / (${maxInput} - ${minInput})) + ${minOutput})`;

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['template_num_to_bool'] = function (block: any, generator: any) {
        var code = '';

        const numbericInput = generator.valueToCode(block, 'NUMERIC_INPUT', generator.ORDER_ATOMIC);
        const thresholdValue = generator.valueToCode(block, 'THRESHOLD_VALUE', generator.ORDER_ATOMIC);
        const boolValue1 = generator.valueToCode(block, 'BOOL_VALUE1', generator.ORDER_ATOMIC);
        const boolValue2 = generator.valueToCode(block, 'BOOL_VALUE2', generator.ORDER_ATOMIC);
        code = `${numbericInput} <= ${thresholdValue} ? ${boolValue1} : ${boolValue2}`;

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['template_75f_10kThermistor_type2_to_temp'] = function (block: any, generator: any) {
        var code = '';
        const numbericInput = generator.valueToCode(block, 'NUMERIC_INPUT', generator.ORDER_ATOMIC);
        code = `function getTemperature(numbericInput) { 
          const resistanceLookupTable = [878900, 617590, 439340, 316180, 230060, 169150, 125550, 94143, 71172, 54308, 41505, 32014, 30458, 28989, 27600, 26287, 25011, 23819, 22692, 21626, 20617, 19691, 18790, 17936,17127, 16359, 15618, 14918, 14254, 13624, 13026, 12474, 11927, 11407, 10914, 10444, 10000, 9580, 9181, 8801, 8439, 8080, 7749, 7433, 7132, 6845, 6569, 6306, 6055, 5816, 5587,5372, 5162, 4961, 4770, 4587, 4424, 4255, 4094, 3939, 3792, 3661, 3525, 3395, 3270, 3151, 3039, 2929, 2824, 2723, 2626, 2536, 2128, 1794, 1518, 1290, 1100, 942, 809, 697, 604, 525, 457, 400, 351, 308, 272, 240, 213, 189, 168];

        const temperatureLookupTable = [-67,-58,-49,-40,-31,-22,-13,-4,5,14,23,32,33.8,35.6,37.4,39.2,41,42.8,44.6,46.4,48.2,50,51.8,53.6,55.4,57.2,59,60.8,62.6,64.4,66.2,68,69.8,71.6,73.4,75.2,77,78.8,80.6,82.4,84.2,86,87.8,89.6,91.4,93.2,95,96.8,98.6,100.4,102.2,104,105.8,107.6,109.4,111.2,113,114.8,116.6,118.4,120.2,122,123.8,125.6,127.4,129.2,131,132.8,134.6,136.4,138.2,140,149,158,167,176,185,194,203,212,221,230,239,248,257,266,275,284,293,302,311];

            // Find the exact number
            const index = resistanceLookupTable.indexOf(numbericInput);
            if (index !== -1) {
             return temperatureLookupTable[index];
            } else {
              // Find the closest number
              var closestNumber = resistanceLookupTable[0];
              var minDifference = Math.abs(numbericInput - closestNumber);
            
              for (let i = 1; i < resistanceLookupTable.length; i++) {
                const difference = Math.abs(numbericInput - resistanceLookupTable[i]);
                if (difference < minDifference || (difference === minDifference && resistanceLookupTable[i] > closestNumber)) {
                  closestNumber = resistanceLookupTable[i];
                  minDifference = difference;
                }
              }
            }

            return temperatureLookupTable[resistanceLookupTable.indexOf(closestNumber)];
        }(${numbericInput})`;
        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['template_10kThermistor_type2_to_temp'] = function (block: any, generator: any) {
        var code = '';
        const numbericInput = generator.valueToCode(block, 'NUMERIC_INPUT', generator.ORDER_ATOMIC);
        code = `function getTemperature(numbericInput) { 
          const resistanceLookupTable = [323839,300974,279880,260410,242427,225809,210443,196227,183068,170775,159488,149024,139316,130306,121939,114165,106939,100218,93909,88090,82670,77620,72911,68518,64419,60592,57017,53647,50526,47606,44874,42317,39921,37676,35573,33599,31732,29996,28365,26834,25395,24042,22770,21573,20446,19376,18378,17437,16550,15714,14925,14180,13478,12814,12182,11590,11030,10501,10000,9526,9078,8653,8251,7866,7505,7163,6838,6530,6238,5960,5697,5447,5207,4981,4766,4561,4367,4182,4006,3838,3679,3525,3380,3242,3111,2985,2865,2751,2642,2538,2438,2343,2252,2165,2082,2003,1927,1855,1785,1718,1655,1594,1536,1480,1427,1375,1326,1279,1234,1190,1149,1109,1070,1034];

          const temperatureLookupTable = [-39,-37,-35,-33,-31,-29,-27,-25,-23,-21,-19,-17,-15,-13,-11,-9,-7,-5,-3,-1,1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35,37,39,41,43,45,47,49,51,53,55,57,59,61,63,65,67,69,71,73,75,77,79,81,83,85,87,89,91,93,95,97,99,101,103,105,107,109,111,113,115,117,119,121,123,125,127,129,131,133,135,137,139,141,143,145,147,149,151,153,155,157,159,161,163,165,167,169,171,173,175,177,179,181,183,185,187];

            // Find the exact number
            const index = resistanceLookupTable.indexOf(numbericInput);
            if (index !== -1) {
             return temperatureLookupTable[index];
            } else {
              // Find the closest number
              var closestNumber = resistanceLookupTable[0];
              var minDifference = Math.abs(numbericInput - closestNumber);
            
              for (let i = 1; i < resistanceLookupTable.length; i++) {
                const difference = Math.abs(numbericInput - resistanceLookupTable[i]);
                if (difference < minDifference || (difference === minDifference && resistanceLookupTable[i] > closestNumber)) {
                  closestNumber = resistanceLookupTable[i];
                  minDifference = difference;
                }
              }
            }

            return temperatureLookupTable[resistanceLookupTable.indexOf(closestNumber)];
        }(${numbericInput})`;
        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['template_10kThermistor_type3_to_temp'] = function (block: any, generator: any) {
        var code = '';
        const numbericInput = generator.valueToCode(block, 'NUMERIC_INPUT', generator.ORDER_ATOMIC);
        code = `function getTemperature(numbericInput) { 
          const resistanceLookupTable = [232032,217394,203774,191093,179281,168275,158013,148442,139511,131100,123317,116045,109247,102889,96941,91374,86160,81276,76659,72371,68348,64574,61031,57703,54578,51641,48879,46259,43817,41519,39354,37316,35395,33585,31878,30267,28735,27302,4656,4473,4298,4131,3971,3817,3671,3532,3398,3271,3149,3032,2920,2812,2709,2610,2516,2425,2339,2256,2176,2099,2026,1955,1887,1822,1760,1700,1642,1587,1534,1483,1433,1386,1341,1297,1255,1214,25948,24670,23462,22320,21241,20220,19254,18332,17467,16648,15872,15136,14439,13778,13151,12556,11987,11451,10942,10459,10000,9564,9149,8754,8379,8019,7679,7355,7047,6754,6474,6208,5954,5712,5479,5258,5048,4847];

          const temperatureLookupTable = [-39,-37,0.35,-33,-31,-29,-27,-25,-23,-21,-19,-17,-15,-13,-11,-9,-7,-5,-3,-1,1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35,113,115,117,119,121,123,125,127,129,131,133,135,137,139,141,143,145,147,149,151,153,155,157,159,161,163,165,167,169,171,173,175,177,179,181,183,185,187,37,39,41,43,45,47,49,51,53,55,57,59,61,63,65,67,69,71,73,75,77,79,81,83,85,87,89,91,93,95,97,99,101,103,105,107,109,111];

            // Find the exact number
            const index = resistanceLookupTable.indexOf(numbericInput);
            if (index !== -1) {
             return temperatureLookupTable[index];
            } else {
              // Find the closest number
              var closestNumber = resistanceLookupTable[0];
              var minDifference = Math.abs(numbericInput - closestNumber);
            
              for (let i = 1; i < resistanceLookupTable.length; i++) {
                const difference = Math.abs(numbericInput - resistanceLookupTable[i]);
                if (difference < minDifference || (difference === minDifference && resistanceLookupTable[i] > closestNumber)) {
                  closestNumber = resistanceLookupTable[i];
                  minDifference = difference;
                }
              }
            }

            return temperatureLookupTable[resistanceLookupTable.indexOf(closestNumber)];
        }(${numbericInput})`;
        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['template_10kThermistor_type2_to_temp'] = function (block: any, generator: any) {
        var code = '';
        const numbericInput = generator.valueToCode(block, 'NUMERIC_INPUT', generator.ORDER_ATOMIC);
        code = `function getTemperature(numbericInput) { 
          const resistanceLookupTable = [323839,300974,279880,260410,242427,225809,210443,196227,183068,170775,159488,149024,139316,130306,121939,114165,106939,100218,93909,88090,82670,77620,72911,68518,64419,60592,57017,53647,50526,47606,44874,42317,39921,37676,35573,33599,31732,29996,28365,26834,25395,24042,22770,21573,20446,19376,18378,17437,16550,15714,14925,14180,13478,12814,12182,11590,11030,10501,10000,9526,9078,8653,8251,7866,7505,7163,6838,6530,6238,5960,5697,5447,5207,4981,4766,4561,4367,4182,4006,3838,3679,3525,3380,3242,3111,2985,2865,2751,2642,2538,2438,2343,2252,2165,2082,2003,1927,1855,1785,1718,1655,1594,1536,1480,1427,1375,1326,1279,1234,1190,1149,1109,1070,1034];

          const temperatureLookupTable = [-39,-37,-35,-33,-31,-29,-27,-25,-23,-21,-19,-17,-15,-13,-11,-9,-7,-5,-3,-1,1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35,37,39,41,43,45,47,49,51,53,55,57,59,61,63,65,67,69,71,73,75,77,79,81,83,85,87,89,91,93,95,97,99,101,103,105,107,109,111,113,115,117,119,121,123,125,127,129,131,133,135,137,139,141,143,145,147,149,151,153,155,157,159,161,163,165,167,169,171,173,175,177,179,181,183,185,187];

            // Find the exact number
            const index = resistanceLookupTable.indexOf(numbericInput);
            if (index !== -1) {
             return temperatureLookupTable[index];
            } else {
              // Find the closest number
              var closestNumber = resistanceLookupTable[0];
              var minDifference = Math.abs(numbericInput - closestNumber);
            
              for (let i = 1; i < resistanceLookupTable.length; i++) {
                const difference = Math.abs(numbericInput - resistanceLookupTable[i]);
                if (difference < minDifference || (difference === minDifference && resistanceLookupTable[i] > closestNumber)) {
                  closestNumber = resistanceLookupTable[i];
                  minDifference = difference;
                }
              }
            }

            return temperatureLookupTable[resistanceLookupTable.indexOf(closestNumber)];
        }(${numbericInput})`;
        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['template_1kThermistor_to_temp'] = function (block: any, generator: any) {
        var code = '';
        const numbericInput = generator.valueToCode(block, 'NUMERIC_INPUT', generator.ORDER_ATOMIC);
        code = `function getTemperature(numbericInput) { 
          const resistanceLookupTable = [803.07,805.28,807.48,809.69,811.89,814.1,816.3,818.5,820.71,822.91,825.11,827.31,829.51,831.71,833.92,836.12,838.32,840.51,842.71,844.91,847.11,849.31,851.5,853.7,855.9,858.09,860.29,862.48,864.68,866.87,869.07,
            871.26,873.45,875.65,877.84,880.03,882.22,884.41,886.6,888.8,890.99,893.17,895.36,897.55,899.74,901.93,904.12,906.3,908.49,910.68,912.86,915.05,917.23,919.42,921.6,923.79,925.97,928.16,930.34,932.52,934.7,936.89,939.07,941.25,943.43,945.61,
            947.79,949.97,952.15,954.33,956.51,958.68,960.86,963.04,965.22,967.39,969.57,971.75,973.92,976.1,978.27,980.45,982.62,984.79,986.97,989.14,991.31,993.49,995.66,997.83,1000,1002.17,1004.34,1006.51,1008.68,1010.85,1013.02,1015.19,1017.36,
            1019.53,1021.69,1023.86,1026.03,1028.19,1030.36,1032.53,1034.69,1036.86,1039.02,1041.19,1043.35,1045.51,1047.68,1049.84,1052,1054.17,1056.33,1058.49,1060.65,1062.81,1064.97,1067.13,1069.29,1071.45,1073.61,1075.77,1077.93,1080.09,1082.24,
            1084.4,1086.56,1088.71,1090.87,1093.03,1095.18,1097.34,1099.49,1101.65,1103.8,1105.95,1108.11,1110.26,1112.41,1114.57,1116.72,1118.87,1121.02,1123.17,1125.32,1127.47,1129.62,1131.77,1133.92,1136.07,1138.22,1140.37,1142.51,1144.66,1148.95,
            1151.1,1153.25,1155.39,1157.54,1159.68,1161.83,1163.97,1166.11,1168.26,1170.4,1172.54,1174.69,1176.83,1178.97,1181.11,1183.25,1185.39,1187.53,1189.67,1191.81,1193.95,1196.09,1198.23,1200.36,1202.5,1204.64,1206.78,1208.91,1211.05,1213.18,
            1215.32,1217.46,1219.59,1221.72,1223.86,1225.99,1228.13,1230.26,1232.39,1234.52,1236.66,1238.79,1240.92,1243.05,1245.18,1247.31,1249.44,1253.7,1255.83,1257.95,1260.08,1262.21,1264.34,1266.46,1268.59,1270.72,1272.84,1274.97,1277.09,1279.22,
            1281.34,1283.47,1285.59,1287.71,1289.84,1291.96,1294.08,1296.2,1298.32,1300.45,1302.57,1304.69,1306.81,1308.93,1311.05,1313.17,1315.28,1317.4,1319.52,1321.64,1323.75,1325.87,1327.99,1330.1];

          const temperatureLookupTable = [-58,-57,-56,-55,-54,-53,-52,-51,-50,-49,-48,-47,-46,-45,-44,-43,-42,-41,-40,-39,-38,-37,-36,-35,-34,-33,-32,-31,-30,-29,-28,-27,-26,-25,-24,-23,-22,-21,-20,-19,-18,-17,-16,-15,-14,-13,-12,-11,-10,-9,-8,-7,-6,
            -5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,
            78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,
            144,145,146,147,148,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186];

            // Find the exact number
            const index = resistanceLookupTable.indexOf(numbericInput);
            if (index !== -1) {
             return temperatureLookupTable[index];
            } else {
              // Find the closest number
              var closestNumber = resistanceLookupTable[0];
              var minDifference = Math.abs(numbericInput - closestNumber);
            
              for (let i = 1; i < resistanceLookupTable.length; i++) {
                const difference = Math.abs(numbericInput - resistanceLookupTable[i]);
                if (difference < minDifference || (difference === minDifference && resistanceLookupTable[i] > closestNumber)) {
                  closestNumber = resistanceLookupTable[i];
                  minDifference = difference;
                }
              }
            }

            return temperatureLookupTable[resistanceLookupTable.indexOf(closestNumber)];
        }(${numbericInput})`;
        return [code, generator.ORDER_FUNCTION_CALL];
      };


    }
    catch (e) {
      console.log("Error in adding code generators", e);
    }
  }

  static customFunctions() {
    try {

      javascriptGenerator.forBlock['getCurrentValueMany'] = function (block: any, generator: any) {
        var level = block.getFieldValue('level');
        var arg = generator.valueToCode(block, 'PointID',
          Order.ATOMIC) || '';

        return [`${`haystack.fetchValueById(
          (function(arg) {
            if (typeof arg === "string") return [arg];
            else if (Array.isArray(arg)) {
              return arg.map(function(pt) {
                if (typeof pt === "object") return pt.id;
                else return pt;
              });
            }
            else if (typeof arg === "object") return [arg.id];
            else return arg;
          }) (${arg}),'${level}', ctx)`}\n`, Order.ATOMIC];
      };

      javascriptGenerator.forBlock['pointWriteValueMany'] = function (block: any, generator: any) {
        var level = block.getFieldValue('level');
        var value = generator.valueToCode(block, 'value', generator.ORDER_ATOMIC);
        var arg = generator.valueToCode(block, 'PointID',
          Order.ATOMIC) || '';

        return `\nhaystack.pointWriteMany(
            (function(arg) {
              if (typeof arg === "string") return [arg];
              else if (Array.isArray(arg)) {
                return arg.map(function(pt) {
                  if (typeof pt === "object") return pt.id;
                  else return pt;
                });
              }
              else if (typeof arg === "object") return [arg.id];
              else return arg;
            })(${arg}), ${level}, ${value}, ${false}, ctx)\n`;
      };

      javascriptGenerator.forBlock['hisWriteValueMany'] = function (block: any, generator: any) {
        var value = generator.valueToCode(block, 'value', generator.ORDER_ATOMIC);
        var arg = generator.valueToCode(block, 'PointID',
          Order.ATOMIC) || '';

        return `\nhaystack.hisWriteMany(
            (function(arg) {
              if (typeof arg === "string") return [arg];
              else if (Array.isArray(arg)) {
                return arg.map(function(pt) {
                  if (typeof pt === "object") return pt.id;
                  else return pt;
                });
              }
              else if (typeof arg === "object") return [arg.id];
              else return arg;
            })(${arg}), ${value}, ctx)\n`;
      };

      javascriptGenerator.forBlock['writeValueandHistorizeMany'] = function (block: any, generator: any) {
        var level = block.getFieldValue('level');
        var value = generator.valueToCode(block, 'value', generator.ORDER_ATOMIC);
        var arg = generator.valueToCode(block, 'PointID',
          Order.ATOMIC) || '';

        return `\n${`haystack.pointWriteMany(
          (function(arg) {
            if (typeof arg === "string") return [arg];
            else if (Array.isArray(arg)) {
              return arg.map(function(pt) {
                if (typeof pt === "object") return pt.id;
                else return pt;
              }); 
            }
            else if (typeof arg === "object") return [arg.id];
            else return arg;
          })(${arg}), ${level}, ${value}, ${false}, ctx)\n;
          
          haystack.hisWriteMany(
            (function(arg) {
              if (typeof arg === "string") return [arg];
              else if (Array.isArray(arg)) {
                return arg.map(function(pt) {
                  if (typeof pt === "object") return pt.id;
                  else return pt;
                });
              }
              else if (typeof arg === "object") return [arg.id];
              else return arg;
            })(${arg}), ${value}, ctx)\n;
            
            `}`;
      };

    }
    catch (e) {
      console.log("Error in adding code generators", e);
    }
  }

  static unitConversionTemplates() {
    try {

      javascriptGenerator.forBlock['temp_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputTempValue = generator.valueToCode(block, 'TEMP_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'CELSIUS' && unitTo === 'FAHRENHEIT') {
          code = `(${inputTempValue} * 9/5) + 32`;
        } else if (unitFrom === 'CELSIUS' && unitTo === 'KELVIN') {
          code = `(${inputTempValue} + 273.15)`;
        } else if (unitFrom === 'FAHRENHEIT' && unitTo === 'CELSIUS') {
          code = `(${inputTempValue} - 32) * 5/9`;
        } else if (unitFrom === 'FAHRENHEIT' && unitTo === 'KELVIN') {
          code = `((${inputTempValue} - 32) * 5/9) + 273.15`;
        } else if (unitFrom === 'KELVIN' && unitTo === 'CELSIUS') {
          code = `(${inputTempValue} - 273.15)`;
        } else if (unitFrom === 'KELVIN' && unitTo === 'FAHRENHEIT') {
          code = `((${inputTempValue} - 273.15) * 9/5) + 32`;
        } else {
          code = `${inputTempValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };


      javascriptGenerator.forBlock['angular_velocity_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'ANG_VELOCITY_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'RAD_PER_SEC' && unitTo === 'RPM') {
          code = `(${inputValue} * 0.1047)`;
        } else if (unitFrom === 'RPM' && unitTo === 'RAD_PER_SEC') {
          code = `(${inputValue} / 0.1047)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['velocity_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'VELOCITY_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'METER_PER_SEC' && unitTo === 'METER_PER_HR') {
          code = `(${inputValue} * 3600)`;
        } else if (unitFrom === 'METER_PER_HR' && unitTo === 'METER_PER_SEC') {
          code = `(${inputValue} / 3600)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['area_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'AREA_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'METER_SQUARE' && unitTo === 'FEET_SQUARE') {
          code = `(${inputValue} * 10.763910417)`;
        } else if (unitFrom === 'FEET_SQUARE' && unitTo === 'METER_SQUARE') {
          code = `(${inputValue} * 0.09290304)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['volume_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'VOLUME_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'CUBIC_METER' && unitTo === 'LITER') {
          code = `(${inputValue} * 1000)`;
        } else if (unitFrom === 'CUBIC_METER' && unitTo === 'GALLONS') {
          code = `(${inputValue} * 219.969152)`;
        } else if (unitFrom === 'GALLONS' && unitTo === 'LITER') {
          code = `(${inputValue} * 4.546092)`;
        } else if (unitFrom === 'GALLONS' && unitTo === 'CUBIC_METER') {
          code = `(${inputValue} / 219.969152)`;
        } else if (unitFrom === 'LITER' && unitTo === 'GALLONS') {
          code = `(${inputValue} / 4.546092)`;
        } else if (unitFrom === 'LITER' && unitTo === 'CUBIC_METER') {
          code = `(${inputValue} * 0.001)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['enthalpy_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'ENTHALPY_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'kJOULE_PER_KG' && unitTo === 'BTU_PER_LB') {
          code = `(${inputValue} * 0.429922614)`;
        } else if (unitFrom === 'BTU_PER_LB' && unitTo === 'kJOULE_PER_KG') {
          code = `(${inputValue} * 2.326)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['power_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'POWER_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'WATT' && unitTo === 'kJOULE_PER_HR') {
          code = `(${inputValue} * 3.6)`;
        } else if (unitFrom === 'kJOULE_PER_HR' && unitTo === 'WATT') {
          code = `(${inputValue} * 0.2777777778)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['force_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'FORCE_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'NEWTON' && unitTo === 'POUNDS') {
          code = `(${inputValue} * 0.224808943)`;
        } else if (unitFrom === 'POUNDS' && unitTo === 'NEWTON') {
          code = `(${inputValue} * 4.44822)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['energy_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'ENERGY_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'BTU' && unitTo === 'KWH') {
          code = `(${inputValue} / 3412.14)`;
        } else if (unitFrom === 'BTU' && unitTo === 'JOULE') {
          code = `(${inputValue} * 1055.06)`;
        } else if (unitFrom === 'BTU' && unitTo === 'WATT_HOUR') {
          code = `(${inputValue} / 3.412)`;
        } else if (unitFrom === 'KWH' && unitTo === 'BTU') {
          code = `(${inputValue} * 3412.14)`;
        } else if (unitFrom === 'KWH' && unitTo === 'JOULE') {
          code = `(${inputValue} * 3600000)`;
        } else if (unitFrom === 'KWH' && unitTo === 'WATT_HOUR') {
          code = `(${inputValue} / 1000)`;
        } else if (unitFrom === 'JOULE' && unitTo === 'BTU') {
          code = `(${inputValue} / 1055.06)`;
        } else if (unitFrom === 'JOULE' && unitTo === 'KWH') {
          code = `(${inputValue} / 3600000)`;
        } else if (unitFrom === 'JOULE' && unitTo === 'WATT_HOUR') {
          code = `(${inputValue} / 3600)`;
        } else if (unitFrom === 'WATT_HOUR' && unitTo === 'BTU') {
          code = `(${inputValue} * 3.412)`;
        } else if (unitFrom === 'WATT_HOUR' && unitTo === 'KWH') {
          code = `(${inputValue} * 1000)`;
        } else if (unitFrom === 'WATT_HOUR' && unitTo === 'JOULE') {
          code = `(${inputValue} * 3600)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };


      javascriptGenerator.forBlock['flow_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'FLOW_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'CUBIC_METER_PER_HR' && unitTo === 'FEET_CUBIC_PER_HR') {
          code = `(${inputValue} * 35.3147)`;
        } else if (unitFrom === 'CUBIC_METER_PER_HR' && unitTo === 'LITER_PER_SEC') {
          code = `(${inputValue} / 3.6)`;
        } else if (unitFrom === 'CUBIC_METER_PER_HR' && unitTo === 'CUBIC_METER_PER_SEC') {
          code = `(${inputValue} / 3600)`;
        } else if (unitFrom === 'CUBIC_METER_PER_HR' && unitTo === 'GALLON_PER_MINUTE') {
          code = `(${inputValue} * 4.402867)`;
        } else if (unitFrom === 'CUBIC_METER_PER_HR' && unitTo === 'CUBIC_FEET_PER_MINUTE') {
          code = `(${inputValue} / 1.699)`;
        } else if (unitFrom === 'FEET_CUBIC_PER_HR' && unitTo === 'CUBIC_METER_PER_HR') {
          code = `(${inputValue} / 35.3147)`;
        } else if (unitFrom === 'FEET_CUBIC_PER_HR' && unitTo === 'LITER_PER_SEC') {
          code = `(${inputValue} / 127.133)`;
        } else if (unitFrom === 'FEET_CUBIC_PER_HR' && unitTo === 'CUBIC_METER_PER_SEC') {
          code = `(${inputValue} / 127133)`;
        } else if (unitFrom === 'FEET_CUBIC_PER_HR' && unitTo === 'GALLON_PER_MINUTE') {
          code = `(${inputValue} / 8.02083)`;
        } else if (unitFrom === 'FEET_CUBIC_PER_HR' && unitTo === 'CUBIC_FEET_PER_MINUTE') {
          code = `(${inputValue} / 60)`;
        } else if (unitFrom === 'LITER_PER_SEC' && unitTo === 'CUBIC_METER_PER_HR') {
          code = `(${inputValue} * 3.6)`;
        } else if (unitFrom === 'LITER_PER_SEC' && unitTo === 'FEET_CUBIC_PER_HR') {
          code = `(${inputValue} * 127.133)`;
        } else if (unitFrom === 'LITER_PER_SEC' && unitTo === 'CUBIC_METER_PER_SEC') {
          code = `(${inputValue} / 1000)`;
        } else if (unitFrom === 'LITER_PER_SEC' && unitTo === 'GALLON_PER_MINUTE') {
          code = `(${inputValue} * 15.85032)`;
        } else if (unitFrom === 'LITER_PER_SEC' && unitTo === 'CUBIC_FEET_PER_MINUTE') {
          code = `(${inputValue} * 2.11888)`;
        } else if (unitFrom === 'CUBIC_METER_PER_SEC' && unitTo === 'CUBIC_METER_PER_HR') {
          code = `(${inputValue} * 3600)`;
        } else if (unitFrom === 'CUBIC_METER_PER_SEC' && unitTo === 'FEET_CUBIC_PER_HR') {
          code = `(${inputValue} * 127133)`;
        } else if (unitFrom === 'CUBIC_METER_PER_SEC' && unitTo === 'LITER_PER_SEC') {
          code = `(${inputValue} * 1000)`;
        } else if (unitFrom === 'CUBIC_METER_PER_SEC' && unitTo === 'GALLON_PER_MINUTE') {
          code = `(${inputValue} * 15850.323141)`;
        } else if (unitFrom === 'CUBIC_METER_PER_SEC' && unitTo === 'CUBIC_FEET_PER_MINUTE') {
          code = `(${inputValue} * 2118.88)`;
        } else if (unitFrom === 'GALLON_PER_MINUTE' && unitTo === 'CUBIC_METER_PER_HR') {
          code = `(${inputValue} / 4.402867)`;
        } else if (unitFrom === 'GALLON_PER_MINUTE' && unitTo === 'FEET_CUBIC_PER_HR') {
          code = `(${inputValue} * 8.02083)`;
        } else if (unitFrom === 'GALLON_PER_MINUTE' && unitTo === 'LITER_PER_SEC') {
          code = `(${inputValue} / 15.85032)`;
        } else if (unitFrom === 'GALLON_PER_MINUTE' && unitTo === 'CUBIC_METER_PER_SEC') {
          code = `(${inputValue} / 15850.323141)`;
        } else if (unitFrom === 'GALLON_PER_MINUTE' && unitTo === 'CUBIC_FEET_PER_MINUTE') {
          code = `(${inputValue} / 7.480519)`;
        } else if (unitFrom === 'CUBIC_FEET_PER_MINUTE' && unitTo === 'CUBIC_METER_PER_HR') {
          code = `(${inputValue} * 1.6999)`;
        } else if (unitFrom === 'CUBIC_FEET_PER_MINUTE' && unitTo === 'FEET_CUBIC_PER_HR') {
          code = `(${inputValue} * 60)`;
        } else if (unitFrom === 'CUBIC_FEET_PER_MINUTE' && unitTo === 'LITER_PER_SEC') {
          code = `(${inputValue} / 2.11888)`;
        } else if (unitFrom === 'CUBIC_FEET_PER_MINUTE' && unitTo === 'CUBIC_METER_PER_SEC') {
          code = `(${inputValue} / 2118.88)`;
        } else if (unitFrom === 'CUBIC_FEET_PER_MINUTE' && unitTo === 'GALLON_PER_MINUTE') {
          code = `(${inputValue} * 7.480519)`;
        } else {
          code = `${inputValue}`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

      javascriptGenerator.forBlock['pressure_conversion'] = function (block: any, generator: any) {
        const unitFrom = block.getFieldValue('FROM_UNIT');
        const unitTo = block.getFieldValue('TO_UNIT');
        const inputValue = generator.valueToCode(block, 'PRESSURE_INPUT', generator.ORDER_ATOMIC);

        var code = '';

        if (unitFrom === 'POUND_PER_SQUARE_INCH' && unitTo === 'BAR') {
          code = `(${inputValue} / 14.5038)`;
        } else if (unitFrom === 'POUND_PER_SQUARE_INCH' && unitTo === 'PASCAL') {
          code = `(${inputValue} * 6894.76)`;
        } else if (unitFrom === 'POUND_PER_SQUARE_INCH' && unitTo === 'inH2O') {
          code = `(${inputValue} * 27.6799)`;
        } else if (unitFrom === 'POUND_PER_SQUARE_INCH' && unitTo === 'inHg') {
          code = `(${inputValue} * 2.03602)`;
        } else if (unitFrom === 'BAR' && unitTo === 'POUND_PER_SQUARE_INCH') {
          code = `(${inputValue} * 14.5038)`;
        } else if (unitFrom === 'BAR' && unitTo === 'PASCAL') {
          code = `(${inputValue} * 100000)`;
        } else if (unitFrom === 'BAR' && unitTo === 'inH2O') {
          code = `(${inputValue} * 401.865)`;
        } else if (unitFrom === 'BAR' && unitTo === 'inHg') {
          code = `(${inputValue} * 29.53)`;
        } else if (unitFrom === 'PASCAL' && unitTo === 'POUND_PER_SQUARE_INCH') {
          code = `(${inputValue} / 6894.76)`;
        } else if (unitFrom === 'PASCAL' && unitTo === 'BAR') {
          code = `(${inputValue} / 100000)`;
        } else if (unitFrom === 'PASCAL' && unitTo === 'inH2O') {
          code = `(${inputValue} / 248.84)`;
        } else if (unitFrom === 'PASCAL' && unitTo === 'inHg') {
          code = `(${inputValue} / 3386.39)`;
        } else if (unitFrom === 'inH2O' && unitTo === 'POUND_PER_SQUARE_INCH') {
          code = `(${inputValue} / 27.6799)`;
        } else if (unitFrom === 'inH2O' && unitTo === 'BAR') {
          code = `(${inputValue} / 401.865)`;
        } else if (unitFrom === 'inH2O' && unitTo === 'PASCAL') {
          code = `(${inputValue} * 248.84)`;
        } else if (unitFrom === 'inH2O' && unitTo === 'inHg') {
          code = `(${inputValue} / 13.6087)`;
        } else if (unitFrom === 'inHg' && unitTo === 'POUND_PER_SQUARE_INCH') {
          code = `(${inputValue} / 2.03602)`;
        } else if (unitFrom === 'inHg' && unitTo === 'BAR') {
          code = `(${inputValue} / 29.53)`;
        } else if (unitFrom === 'inHg' && unitTo === 'PASCAL') {
          code = `(${inputValue} * 3386.39)`;
        } else if (unitFrom === 'inHg' && unitTo === 'inH2O') {
          code = `(${inputValue} * 13.6087)`;
        }

        return [code, generator.ORDER_FUNCTION_CALL];
      };

    }
    catch (e) {
      console.log("Error in adding code generators", e);
    }
  }

  static externalAPIItegrattionTemplates() {
    try {

      javascriptGenerator.forBlock['http_request_header'] = function (block: any, generator: any): [string | null, number] {
        let parameters: any = {};
        let authValue: any;
        let authType: any = block.getFieldValue('auth_type');
    
        if (authType !== 'NONE') {
            if (authType === 'BASIC-AUTH') {
                const userName: any = block.getFieldValue('auth_user_value');
                const password: any = block.getFieldValue('auth_pass_value');
                authValue = { 'username': userName, 'password': password };
                parameters = { ...parameters, ...authValue };
            } else if (authType === 'BEARER') {
                authValue = generator.valueToCode(block, 'auth_input', generator.ORDER_ATOMIC) || null;
                authValue = authValue ? authValue.replace(/^'|'$/g, "\"") : null;
                parameters['Authorization'] = `(function(arg) { return "Bearer " + arg; })(${authValue})`;
            } else if (authType === 'API-KEY') {
                authValue = generator.valueToCode(block, 'auth_input', generator.ORDER_ATOMIC);
                authValue = authValue ? authValue.replace(/^'|'$/g, "\"") : null;
                parameters['api-key'] = `(function(arg) { return arg; })(${authValue})`;
            }
        }
    
        if (block.itemCount_ > 0) {
            let selectedParamKeys: any[] = block.inputList.filter((obj: any) => obj?.name?.includes("element_"))
                .flatMap((obj: any) => obj?.fieldRow.filter((row: any) => row?.name?.includes("headerParamKey_")))
                .map((row: any) => row.value_);
    
            let selectedKeyValues: (string | null)[] = selectedParamKeys.map((_key: any, i: number) => {
                const elementName: string = `element_${i}`;
                const valueBlock: string | null = generator.valueToCode(block, elementName, generator.ORDER_ATOMIC);
                return valueBlock !== null && valueBlock !== undefined ? valueBlock : null;
            });
    
            for (let i = 0; i < selectedParamKeys.length; i++) {
                let key: any = block.getFieldValue(`headerParamKey_${i}`);
                let key1: any = block.getFieldValue(`CustomInput_${i}`);
                let actualKey: any = (key === 'Other-Key') ? key1 : key;
                let valueBlock: string | null = selectedKeyValues[i];
                if (valueBlock) {
                    if (!parameters[actualKey]) {
                        parameters[actualKey] = valueBlock?.replace(/^'|'$/g, "");
                    } else {
                        if (!Array.isArray(parameters[actualKey])) {
                            parameters[actualKey] = [parameters[actualKey]];
                        }
                        parameters[actualKey].push(valueBlock?.replace(/^'|'$/g, ""));
                    }
                }
            }
        }
    
        let headerParameters: string | null = parameters ? JSON.stringify(parameters).replace(/"/g, "'") : null;
        let code: string = `${headerParameters}`;
        return [code, generator.ORDER_ATOMIC];
    };
    

    javascriptGenerator.forBlock['http_request_block'] = function (block: any, generator: any) {
      var code = '';
      const requestUrl = block.getFieldValue('request_url') || '';
      const requestMethod = block.getFieldValue('request_method') || '';
      let postDataInput = null;
      let bodyData = null;
  
      var headerBlock = generator.valueToCode(block, 'request-header-input', generator.ORDER_ATOMIC) || null;
      if (requestMethod === 'PATCH' || requestMethod === 'PUT' || requestMethod === 'POST') {
          postDataInput = generator.valueToCode(block, 'postData', generator.ORDER_ATOMIC) || null;
          bodyData = postDataInput?.replace(/^'|'$/g, "");
      }
  
      headerBlock = `function(header) { 
          if (!header) { return header; }
          
          Object.keys(header).forEach((key) => { 
              if (typeof header[key] === 'string' && header[key].startsWith('(function')) {
                header[key] = eval(header[key]);
              }
          });
          
          return header;
      }(${headerBlock})`;
  
      code = `externalApi.sendRequest('${requestUrl}', '${requestMethod}', ${headerBlock}, ${bodyData}, ctx)`;
      return [code, generator.ORDER_ATOMIC];
  };
  


      javascriptGenerator.forBlock['http_request_body'] = function (block: any, generator: any) { 
        var code = '';
        const postData = block.getFieldValue('body_payload') || null;
        code = `${postData}`;
        return [code, generator.ORDER_ATOMIC];
      }

      javascriptGenerator.forBlock['haystack_query_id'] = function (block: any, generator: any) {
        let code = '';
        const query = block.getFieldValue('Query_with_condition');
        const conditionsList = [];
      
        for (let i = 0; i < block.itemCount_; i++) {
          let queryType = block.getFieldValue('Query_type' + i);
          let lhs = generator.valueToCode(block, 'Query_LHS'+i, generator.ORDER_ATOMIC) || '';
          let operator = block.getFieldValue('OPERATOR' + i);
          let rhs = generator.valueToCode(block, 'Query_RHS'+i, generator.ORDER_ATOMIC) || '';;
      
          if (queryType && operator && rhs) {
            let condition = {
              "queryType": queryType,
              "lhs": lhs,
              "operator": operator,
              "rhs": rhs
            };
            conditionsList.push(condition);
          }
        }
        let queryCondition = '';
        conditionsList.forEach((condition: any) => {
          // Adding '@' to RHS if LHS ends with Ref or id
          if (condition.lhs.endsWith('Ref') || condition.lhs.endsWith('id')) {
            condition.rhs = `@${condition.rhs}`;
          } else if(condition.operator == 'not') {
            condition.rhs = condition.rhs;
          } else {
            condition.rhs = `\\"${condition.rhs}\\"`;
          }
          queryCondition += condition.queryType + " " + condition.lhs + " " + condition.operator + " " + condition.rhs + " "; 
        });
        code = `"(${query} ${queryCondition}) and siteRef==@SITEREF"`;
        return [`${'JSON.parse(haystack.findByFilter(' + code + ', ctx))'}`, Order.ATOMIC];
      };

    } catch (e) {
      console.log("Error in adding code generators", e);
    }
  }

}
